import { MDBBtn } from "mdb-react-ui-kit";
import styles from "../styles.module.css";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { passwordResolver } from "../../../validators/passwordResolver";
import FormValidationError from "../../../shared/UI/FormValidationError";
import { useNavigate } from "react-router-dom";
import MDBPassword from "../../../shared/UI/MDBPassword";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
export default function ChangePassword(params) {
  const defaultValues = {
    curpassword: "",
    newpassword: "",
    comfirmpassword: "",
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues, resolver: passwordResolver });
  const onSubmit = (values) => {
    
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="col-11  py-3 mx-auto d-flex ">
        <div className={styles["changepasswordbox"] + " col-11 mx-auto  "}>
          <MDBBreadcrumb className="ms-5 mt-5 mb-0">
            <MDBBreadcrumbItem onClick={() => navigate("/account")}>
              Account Settings
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Change Passsword</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <div md={6}>
            <h6 className="fw-bold  py-3 mx-5  ">Change Password</h6>
          </div>
          <div className="ms-5 mt-1">
            <div className={`col-lg-5 col-10 col-md-5 py-2 col-sm-11 mb-2   `}>
              <Controller
                control={control}
                name="curpassword"
                render={({ field: { onChange, value } }) => (
                  <MDBPassword
                    className={`${errors.curpassword && "is-invalid"}`}
                    label="Current Password"
                    size="lg"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormValidationError errorMessage={errors.curpassword?.message} />
            </div>
            <div className={`col-lg-5 col-10 col-md-5 py-2 col-sm-11 mb-2   `}>
              <Controller
                control={control}
                name="newpassword"
                render={({ field: { onChange, value } }) => (
                  <MDBPassword
                    className={`${errors.newpassword && "is-invalid"}`}
                    label="New Password"
                    size="lg"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormValidationError errorMessage={errors.newpassword?.message} />
            </div>
            <div className={`col-lg-5 col-10 col-md-5 py-2 col-sm-11 mb-2   `}>
              <Controller
                control={control}
                name="confirmpassword"
                render={({ field: { onChange, value } }) => (
                  <MDBPassword
                    className={`${errors.confirmpassword && "is-invalid"}`}
                    label="Confirm Password"
                    size="lg"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormValidationError
                errorMessage={errors.confirmpassword?.message}
              />
            </div>
          </div>
          <div>
            <MDBBtn
              className={`px-4 mt-3  ${styles.btnchangePassword}`}
              onClick={handleSubmit(onSubmit)}
            >
              Change Passsword
            </MDBBtn>
          </div>
        </div>
      </div>
    </>
  );
}
