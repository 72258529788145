import { MDBBtn, MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import styles from "../Checkout/styles.module.css";
import AddAddress from "../Account/Addresses/AddAddress";
import Cart from "../../components/Cart/Cart";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setLoginState,
  setOrderType,
} from "../../redux/features/auth/authSlice";
import { useSelector } from "react-redux";
export default function PickupDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.token
  );

  const cartDataRedux = useAppSelector((state) => state?.cart?.cartData);
  const [instruction, setInstruction] = useState("");
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const location = useSelector((state) => state?.common?.location);

  const navigateTOCheckOut = () => {
    if (!token === true) {
      dispatch(setLoginState(true));
    } else {
      navigate("/checkout", {
        state: { instructions: instruction },
      });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="row col-11 mx-auto">
        <div
          className="col-lg-6 col-xl-7 my-4 col-12 mx-auto bg-white py-lg-5 p-4 order-lg-1 order-2"
          style={{ border: "1px solid #EAEAEA", borderRadius: "12px" }}
        >
          <div className="d-flex flex-column justify-content-between h-100 col-lg-11 col-12 mx-auto">
            <div>
              <h5 className="fw-bold black">Pickup Details</h5>
              <h6 className="fw-bold black my-3"> Our pickup address is</h6>

              <div className="my-4">
                <MDBInput
                  style={{
                    background: "#FFFFFF",
                  }}
                  label="Pickup Address"
                  disabled
                  size="lg"
                  value={location?.address ?? "Loading..."}
                  color="#606060"
                />
              </div>
              <div className="my-2">
                <MDBTextArea
                  value={instruction}
                  onChange={(e) => setInstruction(e.target.value)}
                  label={"Special Instructions"}
                  rows={4}
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-md-row flex-column align-items-center mx-0">
                <MDBBtn
                  className={` ${styles["btnUpload"]}  ${
                    cartDataRedux?.length > 0 ? "col-xl-3 " : "col-xl-12 "
                  } shadow-none border-0 fw-bold col-xl-3 col-md-5 col-12 my-1`}
                  size="lg"
                  onClick={() => {
                    goBack();
                  }}
                >
                  {" "}
                  Go Back
                </MDBBtn>

                {!(cartDataRedux?.length === 0) && (
                  <MDBBtn
                    className={
                      "shadow-none border-0  fw-bold bgRed my-1 ms-md-2 col-xl-9 col-lg-8 col-md-7 col-12"
                    }
                    size="lg"
                    onClick={() => {
                      navigateTOCheckOut();
                    }}
                  >
                    Continue to Checkout
                  </MDBBtn>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5 my-4 rounded-3 col-12 mx-auto radius12 order-lg-2 order-1 px-0">
          {<Cart checkout={false} />}
        </div>
      </div>
      {!!openAddressModal && (
        <AddAddress open={openAddressModal} setOpen={setOpenAddressModal} />
      )}
    </>
  );
}
