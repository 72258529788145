import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cartData: [],
};
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setAddToCart: (state, action) => {
      const cartData = action.payload;
      state.cartData.unshift(cartData);
    },
    setUpdateCart: (state, action) => {
      if (action.payload?.type === "increment") {
        const cartList = [...state.cartData];
        const itemToUpdate = cartList?.find(
          (item) => item?.cartItemId === action?.payload?.cartItemId
        );
        if (itemToUpdate) {
          itemToUpdate.quantity = itemToUpdate?.quantity + 1;
        }

        state.cartData = cartList;
      } else if (action.payload?.type === "decrement") {
        const cartList = [...state.cartData];
        const itemToUpdate = cartList?.find(
          (item) => item?.cartItemId === action?.payload?.cartItemId
        );
        if (itemToUpdate) {
          itemToUpdate.quantity = itemToUpdate?.quantity - 1;
        }

        state.cartData = cartList;
      } else {
        const updatedCart = state.cartData?.map((item) => {
          if (item.cartItemId === action.payload?.updatedMenuItem?.cartItemId) {
            return action.payload?.updatedMenuItem;
          } else {
            return item;
          }
        });
        state.cartData = updatedCart;
      }
    },
    setDeleteCart: (state, action) => {
      const cartItemId = action.payload?.cartItemId;
      const filteredCartData = state.cartData?.filter(
        (object) => object?.cartItemId !== cartItemId
      );
      state.cartData = filteredCartData;
    },
    setEmptyCartAfterOrder: (state) => {
      state.cartData = [];
    },
  },
});
export const {
  setAddToCart,
  setUpdateCart,
  setDeleteCart,
  setBackendList,
  setEmptyCartAfterOrder,
} = cartSlice.actions;
export default cartSlice.reducer;
