import {
  useLazyGetLoyaltyPointsQuery,
  useLazyGetRedeemItemsListQuery,
  useLazyGetUserRewardPointQuery,
  useLazyGetMenuItemsListQuery,
} from "../../redux/features/loyaltypoints/loyaltyPointsApiSlice";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { businessId } from "../../config";
import Loader from "../../shared/UI/Loader/Loader";
import RedeemCard from "../../components/LoyaltyPoints/RedeemCard";
import LoyaltyPointsCard from "../../components/LoyaltyPoints/LoyaltyPointsCard";
import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import LoyaltyPointsBadge from "../../components/Home/LoyaltyPointsBadge";
export default function LoyaltyPoints(params) {
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const location = useAppSelector((state) => state?.common?.location);
  const d = new Date();
  const [loyaltyData, setData] = useState([]);
  const [basicActive, setBasicActive] = useState("tab1");
  const [userRewardPoints, setUserRewardPoints] = useState();
  const [menuData, setMenuData] = useState([]);
  const [getPointsData, result] = useLazyGetLoyaltyPointsQuery();
  const [getUserRewardPointsData, userRewardPointsResult] =
    useLazyGetUserRewardPointQuery();
  const [getRedeemItemsListData, itemsResult] =
    useLazyGetRedeemItemsListQuery();

  const [getMenuItemsListData, menuResult] = useLazyGetMenuItemsListQuery();

  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getPointsData({
      userId: user?.userId ?? 0,
      businessId: businessId,
      locationId: user?.pickupAddress ?? location?.locationId,
    });
    getRedeemItemsListData({
      userId: user?.userId ?? 0,
      businessId: businessId,
      locationId: user?.pickupAddress ?? location?.locationId,
      dateTime: d?.toISOString(),
    });
    if (user)
      getUserRewardPointsData({
        userId: user?.userId ?? 0,
        businessId: businessId,
        dateTime: d?.toISOString(),
      });
    getMenuItemsListData({
      userId: user?.userId ?? 0,
      businessId: businessId,
      locationId: user?.pickupAddress,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (menuResult?.data) {
      setMenuData(menuResult?.data);
    }
  }, [menuResult?.data, menuData]);

  useEffect(() => {
    if (result?.data) {
      setData(result?.data);
    }
  }, [result.data]);
  useEffect(() => {
    if (userRewardPointsResult?.data) {
      setUserRewardPoints(userRewardPointsResult?.data);
    }
  }, [userRewardPointsResult.data]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  return (
    <>
      <div className="col-11  mx-auto py-lg-3 py-5 " style={{ background: " #FAFAFA" }}>
        <div className="d-flex flex-md-row flex-column  justify-content-between align-items-center pb-4">
          <h1 className="black fw600 mb-md-0 mb-3">
            Rewards
          </h1>
       
            <LoyaltyPointsBadge
              user={user}
              userRewardPoints={userRewardPoints}
            />
        </div>

        <div
          className="d-flex  mt-md-5 mt-3  "
          style={{ overflowX: "auto", overflowY: "hidden" }}
        >
          {menuResult?.isLoading ? (
            <Loader />
          ) : (
            <>
              {menuData?.length > 0 ? (
                menuData?.map((item, index) => (
                  <RedeemCard
                    item={item}
                    key={index}
                    index={index}
                    userRewardPoints={userRewardPoints}
                  />
                ))
              ) : (
                <h6 className="text-center fw-bolder black d-block mx-auto">
                  No Redeem Points Data Found
                </h6>
              )}
            </>
          )}
        </div>

        <div className=" mt-5 py-4 px-md-2">
          <div>
            <div className="d-flex justify-content-between flex-md-row flex-column align-items-center">
              <h2 className="fw600 black mb-0">Points&nbsp;History</h2>
              <div>
                <MDBTabs className="my-4">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleBasicClick("tab1")}
                      active={basicActive === "tab1"}
                      style={{
                        padding: "0.2rem 1rem",
                        fontSize: "14px",
                        background: basicActive === "tab1" && "#fafafa",
                      }}
                    >
                      All
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleBasicClick("tab2")}
                      active={basicActive === "tab2"}
                      style={{
                        padding: "0.2rem 1rem",
                        fontSize: "14px",
                        background: basicActive === "tab2" && "#fafafa",
                      }}
                    >
                      Earned
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleBasicClick("tab3")}
                      active={basicActive === "tab3"}
                      style={{
                        padding: "0.2rem 1rem",
                        fontSize: "14px",
                        background: basicActive === "tab3" && "#fafafa",
                      }}
                    >
                      Redeemed
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              </div>
            </div>
            <div>
              {result?.isLoading ? (
                <div style={{ margin: "5rem 0rem" }}>
                  <Loader />
                </div>
              ) : (
                <MDBTabsContent>
                  <MDBTabsPane show={basicActive === "tab1"}>
                    <div className="row mt-2 mx-auto">
                      {loyaltyData?.length > 0 ? (
                        loyaltyData?.map((item, index) => (
                          <LoyaltyPointsCard
                            item={item}
                            key={index}
                            index={index}
                          />
                        ))
                      ) : (
                        <h6 className="text-center fw-bold black my-5">
                          No History Found
                        </h6>
                      )}
                    </div>
                  </MDBTabsPane>
                  <MDBTabsPane show={basicActive === "tab2"}>
                    <div className="row mt-2 mx-auto">
                      {loyaltyData?.length > 0 ? (
                        loyaltyData?.map((item, index) => (
                          <LoyaltyPointsCard
                            item={item}
                            key={index}
                            index={index}
                          />
                        ))
                      ) : (
                        <h6 className="text-center fw-bold black my-5">
                          No History Found
                        </h6>
                      )}
                    </div>
                  </MDBTabsPane>
                  <MDBTabsPane show={basicActive === "tab3"}>
                    <div className="row mt-2 mx-auto">
                      {loyaltyData?.length > 0 ? (
                        loyaltyData?.map((item, index) => (
                          <LoyaltyPointsCard
                            item={item}
                            key={index}
                            index={index}
                          />
                        ))
                      ) : (
                        <h6 className="text-center fw-bold black my-5">
                          No History Found
                        </h6>
                      )}
                    </div>
                  </MDBTabsPane>
                </MDBTabsContent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
