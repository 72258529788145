import { apiSlice } from "../../api/apiSlice";
import { store } from "../../store";
import { setSuccessMessage } from "../common/commonSlice";

export const paymentApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Payments"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPayments: builder.query({
        query: (data) => ({
          url:
            "/payment/getfromUser?" + new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["Payments"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
      addPayment: builder.mutation({
        query: (credentials) => ({
          url:
            "/payment/add?" +
            new URLSearchParams({ ...credentials }).toString(),
          method: "POST",
        }),
        invalidatesTags: ["Payments"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result?.message));
          return result;
        },
      }),
      deletePaymentMethod: builder.mutation({
        query: (credentials) => ({
          url:
            "/payment/deleteUserStripe?" +
            new URLSearchParams({ ...credentials }).toString(),
          method: "POST",
        }),
        invalidatesTags: ["Payments"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result?.message));
          return result;
        },
      }),
    }),
  });

export const {
  useLazyGetPaymentsQuery,
  useAddPaymentMutation,
  useDeletePaymentMethodMutation,
} = paymentApiSlice;
