import { Controller } from "react-hook-form";
import FormValidationError from "../../../shared/UI/FormValidationError";
import { MDBBtn, MDBCheckbox, MDBInput, MDBSelect } from "mdb-react-ui-kit";
import PhoneInput from "react-phone-input-2";
import ButtonLoader from "../../../shared/UI/Loader/ButtonLoader";

export default function AddressModalForm({
  control,
  errors,
  onSubmit,
  countriesList,
  regionsList,
  citiesList,
  isLoading,
  type,
  addresslist,
}) {
  const countryDataList = (id) => {
    return countriesList?.map((status) => {
      return {
        text: status.name,
        value: status.countryId,
        defaultSelected: status.countryId === id,
      };
    });
  };

  const regionDataList = (id) => {
    return regionsList?.map((status) => {
      return {
        text: status.name,
        value: status.provinceCode,
        defaultSelected: status.provinceCode === id,
      };
    });
  };

  const cityDataList = (id) => {
 
    return citiesList?.map((status) => {
     
      return {
        text: status.name,
        value: status.cityId,
        defaultSelected: status.cityId === id,
      };
    });
  };
  return (
    <>
      <div className="my-3">
        <Controller
          control={control}
          name="street"
          render={({ field: { onChange, value } }) => (
            <MDBInput
              className={`${errors?.street && "is-invalid"}`}
              label="Street Address"
              type="text"
              onChange={onChange}
              value={value}
              size="lg"
            />
          )}
        />
        <FormValidationError errorMessage={errors?.street?.message} />
      </div>
      <div className="my-3">
        <Controller
          control={control}
          name="additionalInfo"
          render={({ field: { onChange, value } }) => (
            <MDBInput
              className={`${errors?.additionalInfo && "is-invalid"}`}
              label="Additional Information"
              type="text"
              onChange={onChange}
              value={value}
              size="lg"
            />
          )}
        />
        <FormValidationError errorMessage={errors?.additionalInfo?.message} />
      </div>
      <div className="my-3">
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, value } }) => (
            <MDBSelect
              inputClassName={`${errors?.country && "is-invalid"}`}
              label="Country"
              size="lg"
              type="text"
              search
              data={countryDataList(value)}
              preventFirstSelection
              onValueChange={(data) => {
                onChange(data.value);
              }}
            />
          )}
        />

        <FormValidationError errorMessage={errors?.country?.message} />
      </div>
      <div className="my-3">
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, value } }) => (
            <MDBSelect
              inputClassName={`${errors?.state && "is-invalid"}`}
              label="State"
              type="text"
              size="lg"
              search
              // preventFirstSelection
              data={regionDataList(value)}
              onValueChange={(data) => {
                onChange(data.value);
              }}
            />
          )}
        />
        <FormValidationError errorMessage={errors?.state?.message} />
      </div>
      <div className="my-3">
        <Controller
          control={control}
          name="city"
          render={({ field: { onChange, value } }) => (
            <>
            <MDBSelect
              inputClassName={`${errors?.city && "is-invalid"}`}
              label="City"
              size="lg"
              search
              data={cityDataList(value)}
              // preventFirstSelection
              onValueChange={(data) => {
                onChange(data.value);
              }}
            />
            </>
          )}
        />

        <FormValidationError errorMessage={errors?.city?.message} />
      </div>

      <div className="row mx-0 px-0 my-3">
        <div className=" px-0 col-lg-4 col-xl-4 col-md-5 col-12 my-1 me-auto">
          <Controller
            control={control}
            name="zipCode"
            render={({ field: { onChange, value } }) => (
              <MDBInput
                inputClassName={`${errors?.zipCode && "is-invalid"}`}
                label="Zip/Postal/Code"
                onChange={onChange}
                value={value}
                size="lg"
              />
            )}
          />
          <FormValidationError errorMessage={errors?.zipCode?.message} />
        </div>
        <div className=" px-0 my-1 col-lg-7 ms-auto col-md-6 col-12" >
          <Controller
            control={control}
            name="mblnumber"
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                country={"us"}
                inputStyle={{ width: "100%", height: "2.75rem" }}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <FormValidationError errorMessage={errors?.mblnumber?.message} />
        </div>
      </div>
      <div className="my-3">
        <Controller
          control={control}
          name="isDefault"
          render={({ field: { onChange, value } }) => (
            <MDBCheckbox
              label={"Set as Default"}
              labelStyle={{
                color: "#000000",
                cursor:
                  type ===
                    !(
                      "Add" &&
                      addresslist?.filter((xx) => xx.setDefault === true)
                        ?.length > 0
                    ) && "pointer",
              }}
              checked={value === true}
              
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="my-4 mt-4">
        <MDBBtn
          className="bgRed shadow-none col-12"
          onClick={onSubmit}
          size="lg"
        >
          {" "}
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <>{type === "Edit" ? "Update Address" : "Add Address"}</>
          )}
        </MDBBtn>
      </div>
    </>
  );
}
