import React, { useEffect } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { ReactComponent as Cross } from "../../../assets/authcross.svg";
import PaymentModalForm from "../../../components/Payment/PaymentModalForm";
import { cardResolver } from "../../../validators/accountResolver";
import { useForm } from "react-hook-form";
const defaultValues = {
  cardholderName: "",
  cardNo: "",
  expDate: "",
  cvv: "",
  isDefault: false,
};
export default function EditPaymentModal({ open, setOpen, data }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: cardResolver,
  });
  const toggleShow = () => setOpen(!open);
  useEffect(() => {
    reset(data);
  }, [data, reset]);
  const onSubmit = (values) => {};
  return (
    <>
      <MDBModal show={open} setShow={setOpen} tabIndex="-1" staticBackdrop>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody>
              <div className="pb-3">
                <Cross className="cursor float-end" onClick={toggleShow} />
              </div>
              <div className="mt-3">
                <h5 className="black text-center fw-bold">
                  Edit Payment Method
                </h5>
                <p className="text-center">
                  This payment method will be edited to your methods list
                </p>
                <div className="mx-3 mt-5">
                  <PaymentModalForm
                    control={control}
                    errors={errors}
                    onSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
