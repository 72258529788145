import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
// import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as Logo } from "../../../assets/logo1.svg";
import UploadImage from "./../../../assets/uploadImageIcon.png";
import { ReactComponent as Settings } from "../../../assets/settings.svg";
// import { ReactComponent as Loyalty } from "../../../assets/loyalty.svg";
import { ReactComponent as Logout } from "../../../assets/logout.svg";
import { ReactComponent as History } from "../../../assets/orderhistory.svg";
import { ReactComponent as Gift } from "../../../assets/gift.svg";
import { ReactComponent as CartMobile } from "../../../assets/navCartIcon.svg";
import { ReactComponent as Login } from "../../../assets/login.svg";
import { ReactComponent as Bars } from "../../../assets/bars.svg";
import { ReactComponent as Cross } from "../../../assets/crossIcon.svg";
// import { ReactComponent as BunduKhan } from "../../../assets/bundukhan.svg";
import Authentication from "../../../container/Authentication/Authentication";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { logOut, setLoginState } from "../../../redux/features/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetProfileQuery } from "../../../redux/features/account/profileApiSlice";
import { businessId } from "../../../config";
import GreetingsModal from "../../../components/Home/GreetingsModal";
import styles from "./styles.module.css";

export default function Navbar({
  showDrawer,
  setShowDrawer,
  setShowTimingModal,
}) {
  const token = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [openGreetingModal, setOpenGreetingModal] = useState(true);
  const loginState = useAppSelector((state) => state?.auth?.loginState);
  const guestCartData = useAppSelector((state) => state?.cart?.cartData);
  const GreetModal = useAppSelector((state) => state?.auth?.greetingModal);
  const isRestaurantOpen = useAppSelector(
    (state) => state?.common?.isRestaurantOpen
  );

  const [getProfile, result] = useLazyGetProfileQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const browserLocation = useLocation();

  const [image, setImage] = useState(null);
  const [name, setName] = useState();
  const cartNavigate = () => {
    navigate("/pickdetail");
  };
  useEffect(() => {
    if (token) {
      getProfile({
        userId: token?.userId,
        businessId: businessId,
      });
    }
  }, [token]);
  useEffect(() => {
    if (result?.data) {
      setImage(result?.data?.imageURL);
      setName(result?.data?.firstName ?? "-");
    }
  }, [result?.data]);

  const MenuItem = ({ title, onClick, isActive }) => {
    return (
      <div
        className={styles[`menu-item${isActive ? "-active" : ""}`]}
        role="button"
        onClick={onClick}
      >
        <h6 className="m-0 p-0 fw-bold">{title}</h6>
      </div>
    );
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          zIndex: "1050",
        }}
      >
        {!isRestaurantOpen && (
          <div className="bgRed white py-2 px-0">
            <p className="mb-0 d-block mx-auto text-center">
              Restaurant is Closed!{" "}
              <u
                className="underline"
                role="button"
                onClick={() => setShowTimingModal(true)}
              >
                View Time Details
              </u>
            </p>
          </div>
        )}
        <MDBNavbar
          expand="md"
          style={{
            background: "#E9E9E9",
            padding: "0rem",
            boxShadow:
              "0 1px 3px rgba(0,0,0,.1), 0 2px 2px rgba(0,0,0,.06), 0 0 2px rgba(0,0,0,.07)",
          }}
        >
          <MDBContainer
            fluid
            className="col-lg-11 mx-3 mx-md-4 mx-lg-5 mx-2 p-0"
          >
            <MDBNavbarBrand className="col-lg-6 col-sm-8 col-6 d-flex  align-items-center p-0">
              <Logo className="me-2" onClick={() => navigate("/")} style={{width:'4rem', height:'4rem',display: "flex",alignItems: "center",cursor: "pointer", }}/>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <Logo  />
                {/* <BunduKhan /> */}
              {/* </div> */}
              <div className="d-flex d-none d-md-flex">
                <MenuItem
                  title={"MENU"}
                  isActive={browserLocation.pathname.includes("menu")}
                  onClick={() => navigate("/menu")}
                />
                {/* <MenuItem
                  title={"GIFT CARDS"}
                  isActive={browserLocation.pathname.includes("giftcards")}
                  onClick={() => navigate("/giftcards")}
                /> */}
                {token && (
                  <MenuItem
                    title={"REWARDS"}
                    isActive={browserLocation.pathname.includes(
                      "loyaltypoints"
                    )}
                    onClick={() => navigate("/loyaltypoints")}
                  />
                )}
                {/* <MenuItem
                  title={"ORDER HISTORY"}
                  isActive={browserLocation.pathname.includes("orderhistory")}
                  onClick={() => navigate("/orderhistory")}
                /> */}
              </div>
            </MDBNavbarBrand>
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex justify-content-end align-items-center mx-md-1">
                <div
                  className="mx-1 mx-md-3"
                  style={{ marginTop: window.innerWidth < 576 && "0.2rem" }}
                >
                  <>
                    <div className="position-relative d-inline-block me-2 mt-2">
                      <CartMobile
                        width={25}
                        className="cursor me-2"
                        onClick={() => {
                          cartNavigate();
                        }}
                      />
                      <MDBBadge
                        style={{
                          background: "#F54222",
                          position: "absolute",
                          bottom: "15px",
                          left: "15px",
                          border: "1.5px solid #E9E9E9",
                        }}
                        light
                        pill
                      >
                        <span style={{ color: "white" }}>
                          {guestCartData?.length}
                        </span>
                      </MDBBadge>
                    </div>
                  </>
                </div>
                {token ? (
                  <>
                    <div className="d-md-flex d-none align-items-center">
                      <div className="">
                        <MDBDropdown className="shadow-none">
                          <MDBDropdownToggle
                            style={{
                              background: "none",
                              boxShadow: "none",
                              padding: "0",
                            }}
                          >
                            <div className="d-flex align-items-center black">
                              <img
                                src={image ? image : UploadImage}
                                alt=""
                                style={{
                                  verticalAlign: "middle",
                                  width: "35px",
                                  height: "35px",
                                  borderRadius: "50%",
                                }}
                              />
                              <div className="black  ms-2 mx-1 fs-6">
                                Account
                              </div>
                              <MDBIcon fas icon="caret-down" />
                            </div>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu className="my-3">
                            <MDBDropdownItem
                              link
                              onClick={() => navigate("/account")}
                              className="my-2"
                            >
                              <Settings className="me-2" />
                              Account Settings
                            </MDBDropdownItem>
                            {/* <MDBDropdownItem
                              link
                              onClick={() => navigate("/loyaltypoints")}
                            >
                              <Loyalty className="me-2" /> Loyalty Points
                            </MDBDropdownItem> */}
                            {/* <MDBDropdownItem
                              link
                              onClick={() => navigate("/giftcards")}
                            >
                              <Gift className="me-2" /> Gift Cards
                            </MDBDropdownItem> */}
                            {/* <MDBDropdownItem
                              link
                              onClick={() => navigate("/giftcards")}
                            >
                              <Gift className="me-2" /> Gift Cards
                            </MDBDropdownItem> */}
                            <MDBDropdownItem
                              link
                              onClick={() => navigate("/orderhistory")}
                              className="my-2"
                            >
                              <History className="me-2" /> Order History
                            </MDBDropdownItem>
                            <hr className="my-0 mx-2" />
                            <MDBDropdownItem
                              link
                              onClick={() => {
                                dispatch(logOut());
                                navigate("/");
                              }}
                              className="my-2"
                            >
                              <Logout className="me-2" /> Sign Out
                            </MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    <div
                      onClick={() => dispatch(setLoginState(true))}
                      className="d-flex align-items-center  d-md-block d-none"
                      role="button"
                    >
                      {/* <Login className="ms-md-0 ms-2" /> */}
                      {/* <MDBIcon size="lg" far icon="user" />
                      <small className="fw-bold ms-1">Sign In</small> */}
                      <MDBBtn
                        size="sm"
                        style={{
                          color: "#000",
                          border: "2px solid #000",
                          borderRadius: "8px",
                          boxShadow: "none",
                          background: "#E9E9E9",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        Sign In
                      </MDBBtn>
                    </div>
                    <div
                      className="mx-2 d-md-none"
                      role="button"
                      onClick={() => {
                        setShowDrawer(!showDrawer);
                      }}
                    >
                      {showDrawer ? <Cross width={30} /> : <Bars width={30} />}
                    </div>
                  </div>
                )}
              </div>
              <div className="d-md-none d-flex justify-content-between align-items-center ms">
                {token && (
                  <div
                    className="mx-2"
                    role="button"
                    onClick={() => {
                      setShowDrawer(!showDrawer);
                    }}
                  >
                    {showDrawer ? <Cross width={30} /> : <Bars width={30} />}
                  </div>
                )}
              </div>
            </div>
          </MDBContainer>
        </MDBNavbar>
      </div>
      {loginState && <Authentication open={loginState} />}
      {token && GreetModal && (
        <div>
          <GreetingsModal
            openGreetingModal={openGreetingModal}
            setOpenGreetingModal={setOpenGreetingModal}
            name={name}
          />
        </div>
      )}
    </>
  );
}
