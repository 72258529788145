import { apiSlice } from "../../api/apiSlice";
import { store } from "../../store";
import { setSuccessMessage } from "../common/commonSlice";

export const profileApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Profile"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      updateProfile: builder.mutation({
        query: (data) => ({
          url:
            "/User/update?" +
            new URLSearchParams({
              firstName: data?.firstName,
              lastName: data?.lastName,
              userId: data?.userId,
              mobileNumber: data?.mobileNumber,
              email: data?.email,
              dob: data?.dob,
              businessId: data?.businessId,
            }).toString(),
          method: "POST",
          body: data?.image,
        }),
        invalidatesTags: ["Profile"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result.message));
          return result;
        },
      }),
      getProfile: builder.query({
        query: (data) => ({
          url:
            "/User/userProfile?" + new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["Profile"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
    }),
  });

export const { useUpdateProfileMutation, useLazyGetProfileQuery } =
  profileApiSlice;
