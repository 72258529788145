import React from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBTabs,
  MDBTabsLink,
  MDBTabsItem,
} from "mdb-react-ui-kit";
import { ReactComponent as MessageIcon } from "../../assets/messageIcon.svg";
import styles from "./styles.module.css";

const Table = ({
  tableCategories,
  selectedTableCategory,
  handleTableCategoryClick,
  handleSort,
  sortConfig,
  data,
}) => {
  return (
    <div className={`${styles["table-container"]} my-4`}>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className={" fw600 black mb-0"}>History</h5>
        <MDBTabs className="top-nav-bar">
          {tableCategories.map((category) => (
            <MDBTabsItem key={category.id}>
              <MDBTabsLink
                onClick={() => handleTableCategoryClick(category.name)}
                active={selectedTableCategory === category.name}
                style={{
                  color:
                    selectedTableCategory === category.name
                      ? "#F54222"
                      : "#212121",
                  backgroundColor:
                    selectedTableCategory === category.name
                      ? "transparent"
                      : "",
                      padding: "0.4rem 0.5rem",
                      fontSize: "14px",
                }}
              >
                <span data-to-scrollspy-id={category.name}>
                  {category.name}
                </span>
              </MDBTabsLink>
            </MDBTabsItem>
          ))}
        </MDBTabs>
      </div>
      <div className="custom-table">
         <MDBTable
          bordered
          responsive
          className={`${styles["table-custom"]} mt-4`}
        >
          <MDBTableHead style={{ backgroundColor: "#F9FAFB" }}>
            <tr>
              <th onClick={() => handleSort("title")}>
                Gift&nbsp;Card's&nbsp;Title
                {sortConfig.key === "title" && (
                  <MDBIcon
                    icon={
                      "arrow-" +
                      (sortConfig.direction === "ascending" ? "down" : "up") +
                      " fill ms-1"
                    }
                  />
                )}
              </th>
              {selectedTableCategory === "Received" ? (
                <>
                  <th>Sender's&nbsp;Name</th>
                  <th>Sender's&nbsp;Email</th>
                </>
              ) : (
                <>
                  <th>Receiver's&nbsp;Name</th>
                  <th>Receiver's&nbsp;Email</th>
                </>
              )}

              {selectedTableCategory === "Received" ? (
                <>
                  <th>Spent&nbsp;Amount</th>
                  <th>Remaining&nbsp;Amount</th>
                </>
              ) : (
                <>
                  <th>Amount</th>
                  <th>Status</th>
                </>
              )}

              {selectedTableCategory === "Received" && <th>Message</th>}
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {data.map((val, index) => (
              <tr key={index}>
                <td>{val.title}</td>
                {selectedTableCategory === "Received" ? (
                  <>
                    <td>{val.name}</td>
                    <td>{val.email}</td>
                  </>
                ) : (
                  <>
                    <td>{val.name}</td>
                    <td>{val.email}</td>
                  </>
                )}

                {selectedTableCategory === "Received" && (
                  <td>{val.spentAmount}</td>
                )}

                <td>{val.receivedAmount}</td>
                {selectedTableCategory === "Sent" ? (
                  <>
                    <td>
                      {val.status === "applied" ? (
                        <span className={styles["status-applied"] + " fw-bold"}>
                          Applied
                        </span>
                      ) : (
                        <span className={styles["status-sent"] + " fw-bold"}>
                          Sent
                        </span>
                      )}
                    </td>
                  </>
                ) : (
                  <td>
                    <MessageIcon />
                  </td>
                )}
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
       
    </div>
  );
};

export default Table;
