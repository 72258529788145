import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./features/auth/authSlice";
import cartReducer from "./features/cart/cartSlice";
import { apiSlice } from "./api/apiSlice";
import commonSlice from "./features/common/commonSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "cart"], // Reducers you want to persist
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    common: commonSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    cart: cartReducer,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      apiSlice.middleware,
    ]),
});

export const persistor = persistStore(store);
