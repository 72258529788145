import { useState, useEffect } from "react";
import { MDBBtn, MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./styles.module.css";
import BuySendModal from "../../components/GiftCards/BuySendModal";
import Table from "../../components/GiftCards/Table";
import PaymentModal from "../../components/GiftCards/PaymentModal";
import PaymentSuccessModal from "../../components/GiftCards/PaymentSuccessModal";

const dummyCategories = [
  { id: 1, name: "Eid ul Fitr" },
  { id: 2, name: "Birthdays" },
  { id: 3, name: "New Year" },
];

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 993 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 992, min: 768 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 769, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const images = [
  {
    id: 1,
    src: "https://cdn.pixabay.com/photo/2017/02/08/14/25/computer-2048983_960_720.jpg",
    title: "Eid ul Fitr Day-01",
    category: "Eid ul Fitr",
    price: "$30",
  },
  {
    id: 2,
    src: "https://cdn.pixabay.com/photo/2017/02/08/14/25/computer-2048983_960_720.jpg",
    title: "Birthdaay-02",
    category: "Birthdays",
    price: "$30",
  },
  {
    id: 3,
    src: "https://cdn.pixabay.com/photo/2017/02/08/14/25/computer-2048983_960_720.jpg",
    title: "NewYear-03",
    category: "New Year",
    price: "$30",
  },
  {
    id: 4,
    src: "https://cdn.pixabay.com/photo/2017/02/08/14/25/computer-2048983_960_720.jpg",
    title: "NewYear-03",
    category: "New Year",
    price: "$30",
  },
];

const tableCategories = [
  { id: 1, name: "Sent" },
  { id: 2, name: "Received" },
];

export default function GiftCards() {
  // eslint-disable-next-line
  const [basicActive, setBasicActive] = useState(dummyCategories[0].name);

  const [data, setData] = useState([
    {
      id: 1,
      title: "Gift Card 1",
      name: "John Doe",
      email: "johndoe@example.com",
      status: "applied",
      spentAmount: "$10",
      remainingAmount: "$20",
      receivedAmount: "$50",
    },
    {
      id: 2,
      title: "Gift Card 4",
      name: "Alice Johnson",
      email: "alicejohnson@example.com",
      spentAmount: "$10",
      remainingAmount: "$20",
      receivedAmount: "$50",
      status: "sent",
    },
    {
      id: 3,
      title: "Gift Cards Title",
      name: "Receiver's Name",
      email: "Receiver's Email",
      spentAmount: "$10",
      remainingAmount: "$20",
      receivedAmount: "$50",
      status: "Status",
    },
  ]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });

  const handleSort = (key) => {
    if (key !== "title") return;
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
        if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
        return 0;
      });
      return sortedData;
    });
    setSortConfig({ key: key, direction: direction });
  };

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedTableCategory, setSelectedTableCategory] = useState("Sent");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const handleTableCategoryClick = (category) => {
    setSelectedTableCategory(category);
  };

  // Set default selected category to "Eid ul Fitr"
  useEffect(() => {
    setSelectedCategory("Eid ul Fitr");
  }, []);

  useEffect(() => {
    setSelectedTableCategory("Sent");
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const handleBuyCard = (card) => {
    setSelectedCard(card);
    setShowModal(true);
  };
  return (
    <>
      <div className="bg-white">
        <div className=" col-11 mx-md-5 mx-auto py-4">
          <div className="mt-4">
            <h2 className={"  fw-bolder black"}>Gift Cards </h2>
          </div>
          <MDBTabs className="top-nav-bar mt-3">
            {dummyCategories.map((category) => (
              <MDBTabsItem key={category.id}>
                <MDBTabsLink
                  onClick={() => handleCategoryClick(category.name)}
                  active={selectedCategory === category.name}
                  style={{
                    backgroundColor:
                      basicActive === category.name
                        ? "transparent"
                        : "transparent",
                    color: "black",
                    padding: "0.2rem 0.5rem",
                    fontSize: "14px",
                  }}
                >
                  {category.name}
                </MDBTabsLink>
              </MDBTabsItem>
            ))}
          </MDBTabs>

          <div className="mt-4">
            <Carousel
              className={styles["carousel"]}
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {images.map((image) => (
                <div
                  key={image.id}
                  className={styles["btn-title-container"] + " me-md-4"}
                >
                  <div className={styles["image-container"]}>
                    <img
                      src={image.src}
                      className={styles["carousel-image"]}
                      alt={image.title}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center py-2 my-1">
                    <h6 className="ms-3 fw600 black mb-0">{image.title}</h6>
                    {selectedCategory === "Birthdays" && (
                      <MDBBtn
                        className={"bgRed me-3 shadow-none"}
                        onClick={() => handleBuyCard(image)}
                      >
                        Buy Now
                      </MDBBtn>
                    )}
                    {selectedCategory === "New Year" && (
                      <MDBBtn
                        className={"bgRed me-3 shadow-none"}
                        onClick={() => handleBuyCard(image)}
                      >
                        Buy Now
                      </MDBBtn>
                    )}
                    {selectedCategory === "Eid ul Fitr" && (
                      <MDBBtn
                        className={"bgRed me-3 shadow-none"}
                        onClick={() => handleBuyCard(image)}
                      >
                        Buy Now
                      </MDBBtn>
                    )}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <BuySendModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleCloseModal={() => {
              setShowModal(false);
              setOpenPaymentModal(true);
            }}
            selectedCard={selectedCard}
          />
          <Table
            tableCategories={tableCategories}
            selectedTableCategory={selectedTableCategory}
            handleTableCategoryClick={handleTableCategoryClick}
            sortConfig={sortConfig}
            handleSort={handleSort}
            data={data}
            // Pass other necessary props
          />
          {openPaymentModal && (
            <PaymentModal
              open={openPaymentModal}
              setOpen={setOpenPaymentModal}
              setOpenSuccessModal={setOpenSuccessModal}
            />
          )}
          {openSuccessModal && (
            <PaymentSuccessModal
              open={openSuccessModal}
              setOpen={setOpenSuccessModal}
            />
          )}
        </div>
      </div>
    </>
  );
}
