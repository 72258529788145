import React from "react";
import { useState } from "react";
import styles from "./styles.module.css";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ReactComponent as Cart } from "../../assets/cartIcon.svg";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Minus } from "../../assets/minusIcon.svg";
import DeleteAlert from "../../shared/UI/MessageDialog/DeleteAlert";
import {
  setDeleteCart,
  setUpdateCart,
} from "../../redux/features/cart/cartSlice";
import { subTotal } from "../../helper/utilis";
import AddToCartModal from "./AddToCartModal";

export default function HomeCart() {
  const dispatch = useAppDispatch();
  const cartDataRedux = useAppSelector((state) => state?.cart?.cartData);
  const [deleteCartIdRedux, setDeleteCartIdRedux] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [menuItem, setMenuItem] = useState();
  const onDeletSubmit = async () => {
    dispatch(setDeleteCart({ cartItemId: deleteCartIdRedux }));
    setOpenDelete(false);
  };
  const enableDeleteModal = (item) => {
    setDeleteCartIdRedux(item?.cartItemId);
    setOpenDelete(true);
  };
  const incrementQuantity = async (item, index) => {
    dispatch(
      setUpdateCart({ cartItemId: item?.cartItemId, type: "increment" })
    );
  };
  const decrementQuantity = async (item, index) => {
    if (item?.quantity > 1) {
      dispatch(
        setUpdateCart({ cartItemId: item?.cartItemId, type: "decrement" })
      );
    }
  };
  const enableAddToCartModal = (item) => {
    const meaningfulData = {
      description: item?.description,
      imageURL: item?.imageURL,
      itemSize: item?.itemSize,
      listofVariations: item?.listofVariations?.map((variation) => ({
        variation: {
          variationName: variation?.variation?.variationName,
          listofSubVariations: variation?.variation?.listofSubVariations?.map(
            (subVariation) => ({
              price: subVariation?.price,
              name: subVariation?.name
                ? subVariation?.name
                : subVariation?.item?.name,
              quantity: subVariation?.quantity,
              checked: subVariation?.checked,
              subVariationId: subVariation?.subVariationId,
            })
          ),
        },
      })),
      name: item?.name,
      comment: item?.comment,
      quantity: item?.quantity,
      cartItemId: item?.cartItemId,
      shopItemId: item?.shopItemId,
      price: item?.itemSize[0]?.price,
      sizeId: item?.sizeId,
    };

    setMenuItem(meaningfulData);
    setShowModal(true);
  };
  return (
    <>
      {cartDataRedux?.length > 0 ? (
        <>
          {cartDataRedux?.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                enableAddToCartModal(item);
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <img
                    src={item?.imageURL}
                    alt=""
                    width={80}
                    height={80}
                    style={{ objectFit: "cover" }}
                    className="rounded"
                  />
                  <div className="ms-2">
                    <span className="fw-bold elipses-1 mb-1">{item?.name}</span>
                    <span className={styles.paragraph}>
                      {item?.description}
                    </span>
                  </div>
                </div>
                <div className="ms-1 me-2">
                  <p>${subTotal(cartDataRedux[index])}</p>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <div
                    className="red cursor"
                    onClick={(e) => {
                      e.stopPropagation();
                      enableDeleteModal(item);
                    }}
                  >
                    Delete Item
                  </div>
                  <div>
                    <Minus
                      onClick={(e) => {
                        e.stopPropagation();
                        decrementQuantity(item, index);
                      }}
                      className="cursor"
                    />
                    <span className="ms-2 me-2">{item?.quantity}</span>
                    <Plus
                      className="me-2 cursor"
                      onClick={(e) => {
                        e.stopPropagation();
                        incrementQuantity(item, index);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="me-2">
                <hr className="horiRow" />
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="text-center align-item-center">
          <Cart />
          <p>
            Your cart is empty
            <br /> Add items to get started{" "}
          </p>
        </div>
      )}
      <AddToCartModal
        showModal={showModal}
        setShowModal={setShowModal}
        menuItem={menuItem}
      />
      <DeleteAlert
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        heading={"Delete Item?"}
        cancelText={"Proceed"}
        message={"Are you sure want to Delete this Item?"}
        onOk={onDeletSubmit}
      />
    </>
  );
}
