import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export const accountResolver = yupResolver(
  Yup.object().shape({
    firstname: Yup.string().required("First Name is Required"),
    lastname: Yup.string().required("Last Name is Required"),
    emailAddress: Yup.string()
      .required("Email is Required")
      .matches(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Invalid Email"
      ),
    mobile: Yup.string().required("Mobile Number is Required"),
    dateOfBirth: Yup.string()
      .required("Date is Required")
      .matches(
        /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
        "Inavlid Date Format"
      ),
  })
);
export const cardResolver = yupResolver(
  Yup.object().shape({
    cardholderName: Yup.string().required("Card Holder Name is Required"),
    cardNo: Yup.string()
      .required("Card No is Required")
      .min(16, "Please Type Correct Card Number")
      .max(16, "Please Type Correct Card Number"),
    cvv: Yup.number().required("CVV is Required"),
    expDate: Yup.string()
      .required("Expiry Date is Required")
      .matches(
        /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
        "Inavlid Date Format"
      ),
  })
);

export const addressResolver = yupResolver(
  Yup.object().shape({
    country: Yup.string().required("Select Your Country"),
    street: Yup.string().required("Street Address is Required"),
    // additionalInfo: Yup.string().required("Additional Information is Required"),
    // city: Yup.string().required("Select Your City"),
    // state: Yup.string().required("Select Your Province"),
    zipCode: Yup.number().required("Zip Code is Required"),
    mblnumber: Yup.number().required("Mobile Number is Required"),
  })
);
