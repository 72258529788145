import {
  useLazyGetOrderHistoryQuery,
  useLazyGetOrderHistorybyDateAndTimeQuery,
} from "../../../redux/features/products/OrderHistoryApiSlice";
import { useEffect, useState } from "react";
import OrderCard from "../../../components/MyOrders/OrderCard";
import { useAppSelector } from "../../../redux/hooks";
import { businessId } from "../../../config";
import Loader from "../../../shared/UI/Loader/Loader";
import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
export default function OrderHistory(params) {
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  let date = new Date();
  const [getOrder, result] = useLazyGetOrderHistoryQuery();
  const [data, setData] = useState([]);
  const [basicActive, setBasicActive] = useState("tab1");
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  const getData = async () => {
    getOrder({
      userId: user?.userId,
      businessId: businessId,
    });
  };
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (result?.data) {
      setData(result?.data);
    }
  }, [result?.data]);

  const [getOrderHistorybyDateAndTime, activeResult] =
    useLazyGetOrderHistorybyDateAndTimeQuery();

  const [activeData, setActiveData] = useState([]);
  const getActiveData = async () => {
    getOrderHistorybyDateAndTime({
      customerId: user?.userId,
      businessId: businessId,
      locationId: user?.pickupAddress,
      day: false,
      month: false,
      year: false,
      locationFlag: false,
      multipleStatus: "Ordered,Progress,Ready",
      date: date?.toISOString(),
    });
  };
  useEffect(() => {
    getActiveData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (activeResult?.data) {
      setActiveData(activeResult?.data);
    }
  }, [activeResult?.data]);

  return (
    <>
      <div
        className="col-12  mx-auto py-4  bg-white"
        style={{ minHeight: "80vh" }}
      >
        <div className="col-10 col-md-8 mx-auto">
          <div className="d-flex justify-content-between flex-md-row flex-column align-items-md-center">
            <h3 className="fw600 black mb-0">Order&nbsp;History</h3>
            <div>
              <MDBTabs className="my-4">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleBasicClick("tab1")}
                    active={basicActive === "tab1"}
                    style={{ padding: "0.2rem 1rem", fontSize: "14px" }}
                  >
                    Active
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleBasicClick("tab2")}
                    active={basicActive === "tab2"}
                    style={{ padding: "0.2rem 1rem", fontSize: "14px" }}
                  >
                    Past
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
            </div>
          </div>
          <MDBTabsContent>
            <MDBTabsPane show={basicActive === "tab1"}>
              <div>
                <h4 className="black fw600">Active&nbsp;Orders</h4>
                <div>
                  {activeResult?.isLoading ? (
                    <div style={{ margin: "5rem 0rem" }}>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {activeData?.length > 0 ? (
                        activeData.map((item, index) => {
                          return (
                            <>
                              <OrderCard
                                key={index}
                                item={item}
                                active={true}
                              />
                            </>
                          );
                        })
                      ) : (
                        <h6 className="fw-bold black text-center my-5">
                          You have No Active Orders{" "}
                        </h6>
                      )}
                    </>
                  )}
                </div>
              </div>
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab2"}>
              <h4 className="black fw600">Past&nbsp;Orders</h4>
              <div>
                {result?.isLoading ? (
                  <div style={{ margin: "5rem 0rem" }}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    {data?.length > 0 ? (
                      data.map((item, index) => {
                        return (
                          <>
                            <OrderCard item={item} key={index} />
                          </>
                        );
                      })
                    ) : (
                      <h6 className="fw-bold black text-center my-5">
                        You have no past orders
                      </h6>
                    )}
                  </>
                )}
              </div>
            </MDBTabsPane>
          </MDBTabsContent>
        </div>
      </div>
    </>
  );
}
