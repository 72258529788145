import { formatISO } from "date-fns";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { AddToCart } from "../../helper/utilis";
import { businessId } from "../../config";
function OrderCard({ item, active }) {
  const navigate = useNavigate();
  const ReOrder = () => {
    item?.itemList?.map((item) => {
      AddToCart(
        item?.item?.category?.categoryId,
        item?.item?.shopItemId,
        item?.quantity
      );
    });
    navigate("/pickdetail");
  };
  return (
    <div
      className="col-12 pt-3 my-3 pb-2"
      style={{
        background: "#FFFFFF",
        border: "1.5px solid #E2E2E2",
        borderRadius: "8px",
        minHeight: "5rem",
      }}
    >
      <div className="d-flex flex-lg-row flex-column  justify-content-between mx-4 ">
        <div className="d-flex">
          <p className="fw-bold black mb-1 fw600">Order # {item?.cartId}</p>
        </div>
        <div className="d-flex  ">
          <p className="me-4 fw-bold fw500 mb-1  black">
            ${(item?.totalPrice )?.toFixed(2)}
          </p>
          {item.status === "Ordered" ||
          item.status === "Pending" ||
          item.status === "In Progress" ? (
            <span className=" text-center fw600" style={{ color: "#DB7712" }}>
              In Progress
            </span>
          ) : item?.status === "Delivered" ? (
            <span className=" text-center" style={{ color: "#0EA472" }}>
              Completed
            </span>
          ) : item?.status === "Cancelled" ? (
            <span className="text-center" style={{ color: "#EF4352" }}>
              Cancelled
            </span>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div>
        {item?.itemList?.map((data, index) => {
          return (
            <>
              <div
                className="d-flex align-items-center justify-content-between mx-4"
                key={index}
              >
                <span className="ellipses-2 lightBlack fw600 mt-1  ">
                  <span>{data?.quantity} x</span> {data?.item?.name ?? "-"}{" "}
                </span>{" "}
                <span className="fw600 lightBlack mb-1 d-lg-block d-none">
                  {data?.size?.size}
                </span>
              </div>
              <div className="d-lg-none d-flex mx-4">
                <span className="lightBlack">Size: {data?.size?.size}</span>
              </div>
            </>
          );
        })}
      </div>
      <hr className="mx-3" />
      <div
        className={
          styles["trackButton"] +
          " d-flex align-items-center ms-lg-4 flex-lg-row flex-column "
        }
      >
        {active && (
          <p
            className={" red mb-1   text-center mb-3 ms-2 fs-7 cursor mx-lg-3"}
            onClick={() => navigate("/trackorder/" + item?.cartId)}
          >
            Track Order
          </p>
        )}
        <button
          className={
            " border-0 mb-3 mx-lg-3 text-center px-3 rounded py-1 shadow-none"
          }
          style={{
            backgroundColor: "rgb(245, 66, 34, 0.3)",
            borderRadius: "8px",
            color: "#f54222",
          }}
        >
          {" "}
          <a
            style={{ color: "#f54222" }}
            href={`https://d13di7fagpzobw.cloudfront.net/generateReceipt?cartId=${item?.cartId}&businessId=${businessId}`}
            // href={`https://mywyzer.com:8443/generateReceipt?cartId=${item?.cartId}&businessId=${businessId}`}
          >
            Download Receipt
          </a>
        </button>
        <button
          onClick={() => {
            ReOrder();
          }}
          className={
            styles["reorder"] +
            " border-0 mb-3 mx-lg-3 text-center px-5 rounded py-1 bgRed shadow-none white"
          }
        >
          Reorder
        </button>
      </div>
      <div className={styles["accordian"]}>
        {" "}
        <MDBAccordion borderless>
          <MDBAccordionItem
            btnClassName="margin-auto"
            collapseId={item?.cartDetailId ?? 1}
            key={item?.cartDetailId}
            headerTitle={
              <div
                className={
                  styles["accordianItem"] +
                  " d-flex justify-content-end align-items-center ms-lg-0  "
                }
              >
                <small className="lightBlack fw600 mt-lg-1 mb-2 mb-lg-3">
                  View&nbsp;Details{" "}
                </small>
              </div>
            }
          >
            <div>
              <hr className="my-0" style={{ height: "2px" }} />
              {item?.itemList?.map((data, index) => {
                return (
                  <>
                    <div
                      className="d-flex  justify-content-between"
                      key={index}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="ellipses-2 lightBlack fw600 my-1  ">
                          {data?.item?.name ?? "-"}{" "}
                        </span>{" "}
                      </div>
                    </div>
                    {data?.subvariations && (
                      <>
                        <div className="d-flex  justify-content-between mx-4">
                          <div className="d-flex">
                            <span className="ellipses-2 text-muted ms-lg-3 md-1 ">
                              Extras
                            </span>{" "}
                          </div>
                        </div>
                        <div className="mx-2">
                          {data?.subvariations?.map((itemm, index) => {
                            return (
                              <>
                                <div
                                  className="d-flex justify-content-between mx-lg-5 mx-3"
                                  key={index}
                                >
                                  <li>
                                    {itemm?.quantity ?? 1} x{" "}
                                    {itemm?.subVariation?.item
                                      ? itemm?.subVariation?.item.name
                                      : itemm?.subVariation?.name}
                                  </li>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>
            <div>
              <hr className="my-1" style={{ height: "1.5px" }} />
              <p className="mb-0">
                <span className="lightBlack fw600">Order Type: </span>
                {item?.itemList[0]?.cart?.pickup ? "Pickup" : "Delivery"}
              </p>
              <p className="mb-0">
                <span className="lightBlack fw600">Date & Time:</span>{" "}
                {item?.cartOrderDate
                  ? formatISO(item?.cartOrderDate, "MMM dd,yyyy hh:mm a")
                  : "-"}
              </p>
              <p className="mb-0">
                <span className="lightBlack fw600">Payment Method: </span>
                {item?.cart?.cod === true
                  ? " Cash On Delivery"
                  : " Credit/Debit Card"}
              </p>
              <p className="mb-0">
                <span className="lightBlack fw600">Payment Status:</span>

                {item?.cart?.cod === true ? (
                  <span style={{ color: "red" }}> Unpaid</span>
                ) : (
                  <span style={{ color: "#0EA472" }}> Paid</span>
                )}
              </p>
              <p className="mb-0">
                <span className="lightBlack fw600"> Special Instructions:</span>{" "}
                {item?.cart?.instructions ?? "-"}
              </p>
            </div>
          </MDBAccordionItem>
        </MDBAccordion>
      </div>

      {/* </div> */}
    </div>
  );
}

export default OrderCard;
