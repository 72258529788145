import { useState } from "react";
import { ReactComponent as DiamondIcon } from "../../assets/diamond-01.svg";
import { ReactComponent as DiamondRed } from "../../assets/diamondred.svg";
import styles from "./styles.module.css";
export default function LoyaltyPointsCard({ item, index }) {
  const [hovered, setHovered] = useState(false);
  const handleMouseOver = () => {
    setHovered(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
  };
  return (
    <div
      key={index}
      className={styles["card"] + " col-md-6 col-12   my-2   "}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        boxShadow: hovered ? "0px 2px 8px rgba(0, 0, 0, 0.08)" : "none",
        borderRadius: hovered && "8px",
        //border: hovered && "1.5px solid #E2E2E2",
      }}
    >
      <div className="d-flex align-items-center justify-content-between flex-md-row flex-column  pt-2">
        <div className="col-md-3 col-12">
          <img
            src={item.item.imageURL}
            alt=""
            style={{
              width: "100%",
              objectFit: "cover",
              height: "120px",
              borderRadius: "8px",
            }}
          />
          
        </div>
        <div
          className="col-md-9 col-12 px-3 mx-auto"
          style={{ marginBottom: "10px", marginTop: "10px" }}
        >
          <div className="d-flex justify-content-between align-items-center mt-md-0 mt-2">
            <h6 className="mb-0 fw600 black elipses-1">{item.item.name}</h6>
            <div className="d-md-flex d-none align-items-center justify-content-between ms-3 ms-md-0 ">
              <h6 className="mb-0 text-nowrap">
                <span className="fw600">Size:</span> {item.size.size}
              </h6>
              <h6 className="mb-0 ms-4 text-nowrap">
                <span className="fw600">Quantity:</span> {item.quantity}
              </h6>
            </div>
          </div>
          <div className="d-flex d-md-none align-items-center  ms-md-0  mt-3">
            <h6 className="mb-0">
              <span className="fw600 text-nowrap">Size:</span> {item.size.size}
            </h6>
            <h6 className="mb-0 ms-4">
              <span className="fw600 text-nowrap">Quantity:</span> {item.quantity}
            </h6>
          </div>
          <p className="elipses-2 mt-3">{item.item.description}</p>

          <div className=" d-flex justify-content-between align-items-center mt-3 mb-1">
            <h6 className="mb-0 black fw600">${item.price}.00</h6>
            <div className="d-flex align-items-center fw-bold">
              {parseInt(item?.point) < 0 ? (
                <>
                  <span style={{ color: "#EF4352" }}>
                    <DiamondRed className="me-1" />
                    {item?.point ? parseInt(item?.point)?.toFixed(1) : 0}{" "}
                  </span>
                </>
              ) : (
                <>
                  <span style={{ color: "#0EA472" }}>
                    <DiamondIcon className="me-1" width={24} />+
                    {item?.point ? parseInt(item?.point)?.toFixed(1) : 0}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
