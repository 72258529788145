import { apiSlice } from "../../api/apiSlice";

export const categoryApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Categories"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCategories: builder.query({
        query: (data) => ({
          url: "/Menu/get?" + new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["Categories"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
    }),
  });

export const { useLazyGetCategoriesQuery } = categoryApiSlice;
