

  export default function GroupOrderButton(props) {
   


    return (
      <>

                <button className=" border-0 rounded-4 btn  w-100 h-10 "
                    style={{
                        backgroundColor:props.backgroundColor ?? '#F54222',
                        color:props.color? props.color : '#fff',
                        fontWeight:'600'
                        }}
                        onClick={props.OnClickHandler}
                        >
                        <div style={{fontSize: '13px' }}>{props.text}</div>
                </button>
              
      </>
    );
  }
  