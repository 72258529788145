import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import Greetings from "../../assets/wave.svg";
import { useEffect, useState } from "react";
import { setGreetingModal } from "../../redux/features/auth/authSlice";
import { useAppDispatch } from "../../redux/hooks";

export default function GreetingsModal({
  openGreetingModal,
  setOpenGreetingModal,
  name
}) {
    const [greetingMessage, setGreetingMessage] = useState("");
    const dispatch = useAppDispatch()

  useEffect(() => {
    
    const currentTime = new Date().getHours();

    if (currentTime >= 5 && currentTime < 12) {
      setGreetingMessage("Good morning");
    } else if (currentTime >= 12 && currentTime < 17) {
      setGreetingMessage("Good afternoon");
    } else {
      setGreetingMessage("Good evening");
    }

   
  }, []);

  const capitalizeFirstLetter = (str) => {
    if (!str) return ""; // Return an empty string if str is undefined or null
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const capitalizedName = capitalizeFirstLetter(name);
  return (
    <>
      <MDBModal
        show={openGreetingModal}
        tabIndex="-1"
        setShow={(show) => {
          if (!show && openGreetingModal){
            setOpenGreetingModal(false);
            dispatch(setGreetingModal({type:"disable"}))
          } 
        }}
        
      >
        <MDBModalDialog size="md" centered>
          <MDBModalContent>
            <MDBModalBody className="pt-5 pb-5 text-center">
              <img src={Greetings} alt="" />
              <div className="mt-4"><span className="black fw600">{greetingMessage}, {capitalizedName}</span></div>
              <small style={{color:"#868686;"}}>We hope that you are having a great {greetingMessage.split(' ')[1]}! </small>
              
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
