import { apiSlice } from "../../api/apiSlice";
import { store } from "../../store";
import { setIsRestaurantOpen } from "../common/commonSlice";
import { setLocation } from "./commonSlice";
import {
  getCurrentDayName,
  getCurrentPosition,
  getRoadDistance,
  isRestaurantOpen,
} from "../../../helper/utilis";
import { googleMapsAPIKey } from "../../../config";

export const commonApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: (credentials) => ({
        url:
          "/Location/customerSide/getList?" +
          new URLSearchParams({ ...credentials }).toString(),
        method: "GET",
      }),
      transformResponse: async (result) => {
        if (result?.payLoad?.length > 0) {
          let shortestDistance = Infinity;
          let closestLocationIndex = 0;
          const position = await getCurrentPosition();
          if (position) {
            for (let i = 0; i < result.payLoad.length; i++) {
              const element = result.payLoad[i];
              const distance = await getRoadDistance(
                googleMapsAPIKey,
                position.coords,
                {
                  latitude: element.latitude,
                  longitude: element.longitude,
                }
              );
              if (distance && distance < shortestDistance) {
                shortestDistance = distance;
                closestLocationIndex = i;
              }
            }
          }
          const closestLocation = result?.payLoad[closestLocationIndex];
          store.dispatch(setLocation(closestLocation));
          let openingTime, closingTime;

          if (closestLocation.flexTime) {
            const currentDayTime = closestLocation.flexTime.find(
              (daytime) => daytime.weekDay === getCurrentDayName()
            );
            if (currentDayTime) {
              openingTime = currentDayTime.openingTime;
              closingTime = currentDayTime.closingTime;
            }
          } else {
            openingTime = closestLocation.openingTime;
            closingTime = closestLocation.closingTime;
          }

          const isRestautantOpen = isRestaurantOpen(openingTime, closingTime);
          store.dispatch(setIsRestaurantOpen(isRestautantOpen));
        }
        return result;
      },
    }),
    getTimings: builder.query({
      query: (credentials) => ({
        url:
          "/Location/get?" + new URLSearchParams({ ...credentials }).toString(),
        method: "GET",
      }),
      transformResponse: (result) => {
        return result.payLoad ?? [];
      },
    }),
  }),
});

export const { useGetLocationsQuery, useLazyGetTimingsQuery } = commonApiSlice;
