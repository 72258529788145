import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export const giftCardResolver = yupResolver(
  Yup.object().shape({
    emailAddress: Yup.string()
      .required("Email is Required")
      .matches(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Invalid Email"
      ),
    mobile: Yup.string().required("Mobile Number is Required"),
    message: Yup.string().required("Message is Required"),

  })
);
