import { MDBBtn, MDBRadio, MDBTextArea } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import styles from "../Checkout/styles.module.css";
import AddAddress from "../Account/Addresses/AddAddress";
import Cart from "../../components/Cart/Cart";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetAddressesQuery } from "../../redux/features/account/addressesApiSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setLoginState,
  setOrderType,
} from "../../redux/features/auth/authSlice";
import Loader from "../../shared/UI/Loader/Loader";
export default function DeliveryDetail() {
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const token = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.token
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cartDataRedux = useAppSelector((state) => state?.cart?.cartData);
  const [address, setAddress] = useState();
  const [fullAddress, setFullAddress] = useState();
  const [addressList, setAddressList] = useState([]);
  const [getAddresses, result] = useLazyGetAddressesQuery();
  const [instruction, setInstruction] = useState("");
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const openLoginPopUp = () => {
    dispatch(setLoginState(true));
  };
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (result?.data) {
      setAddressList(result?.data);
      if (result?.data?.length > 0) {
        setAddress(result?.data[0]?.addressId);
        setFullAddress(
          result?.data[0]?.address +
            (result?.data[0]?.city ? ", " + result?.data[0]?.city?.name : " ") +
            (result?.data[0]?.province
              ? ", " + result?.data[0]?.province?.name
              : " ") +
            (result?.data[0]?.country
              ? ", " + result?.data[0]?.country?.name
              : " ")
        );
      }
    }
  }, [result?.data]);
  useEffect(() => {
    getAddresses({
      userId: user?.userId ?? 0,
    });
    // eslint-disable-next-line
  }, []);
  const goBack = () => {
    dispatch(setOrderType(0));
    navigate(-1);
  };
  const navigateTOCheckOut = () => {
    if (!token === true) {
      dispatch(setLoginState(true));
    } else {
      navigate("/checkout", {
        state: { deliveryAddress: fullAddress,addressId:address, instructions: instruction },
      });
    }
  };
  return (
    <>
      <div className="row col-11 mx-auto">
        {result?.isLoading ? (
          <div className="text-center" style={{ margin: "5rem 0rem" }}>
            <Loader />
          </div>
        ) : (
          <>
            <div
              className="col-lg-6 col-xl-7 my-1 my-md-4 my-lg-4 my-xl-4 col-12 mx-auto bg-white p-lg-5 p-4 order-lg-1 order-2"
              style={{ border: "1px solid #EAEAEA", borderRadius: "12px" }}
            >
              <div className="d-flex flex-column justify-content-between h-100 col-lg-11 col-12 mx-auto">
                <div>
                  <h5 className="fw-bold black">Delivery Details</h5>
                  <h6 className="fw-bold black mt-3">
                    Select delivery address
                  </h6>
                  {!token === true ? (
                    <div
                      className="mt-4 cursor"
                      onClick={() => {
                        openLoginPopUp();
                      }}
                    >
                      <small className="black fw600">
                        Please, <span className="red">Login</span> to select
                        delivery address
                      </small>
                    </div>
                  ) : null}
                  {!token === false && (
                    <>
                      <div>
                        {addressList?.length > 0 ? (
                          addressList?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  styles["border"] + " col-12 my-3 px-3 py-2"
                                }
                              >
                                <MDBRadio
                                  label={
                                    item?.address +
                                    (item?.city
                                      ? ", " + item?.city?.name
                                      : " ") +
                                    (item?.province
                                      ? ", " + item?.province?.name
                                      : " ") +
                                    (item?.country
                                      ? ", " + item?.country?.name
                                      : " ")
                                  }
                                  labelStyle={{
                                    color: "#606060",
                                    marginLeft: "0.5rem",
                                    fontWeight: "bold",
                                  }}
                                  checked={item?.addressId === address}
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setAddress(item?.addressId);
                                      setFullAddress(
                                        item?.address +
                                          (item?.city
                                            ? ", " + item?.city?.name
                                            : " ") +
                                          (item?.province
                                            ? ", " + item?.province?.name
                                            : " ") +
                                          (item?.country
                                            ? ", " + item?.country?.name
                                            : " ")
                                      );
                                    }
                                  }}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <h6 className="text-center black fw-bold my-5">
                            No Address Available
                          </h6>
                        )}
                      </div>
                      <h6
                        className="my-3 red fw600 cursor"
                        onClick={() => setOpenAddressModal(true)}
                      >
                        Add New Address
                      </h6>
                      <div className="my-4">
                        <MDBTextArea
                          value={instruction}
                          onChange={(e) => setInstruction(e.target.value)}
                          label={"Special Instructions"}
                          rows={4}
                        />
                      </div>{" "}
                    </>
                  )}
                </div>
                <div>
                  <div className="d-flex flex-md-row flex-column align-items-center mx-0 mb-4">
                    <MDBBtn
                      className={` ${styles["btnUpload"]}  ${
                        cartDataRedux?.length > 0 ? "col-xl-3 " : "col-xl-12 "
                      } shadow-none border-0 fw-bold col-xl-3 col-md-5 col-12 my-1`}
                      size="lg"
                      onClick={() => {
                        goBack();
                      }}
                    >
                      Go Back
                    </MDBBtn>
                    {!(cartDataRedux?.length === 0) && (
                      <MDBBtn
                        className={
                          "shadow-none border-0  fw-bold bgRed my-1 ms-md-2 col-xl-9 col-lg-8 col-md-7 col-12"
                        }
                        size="lg"
                        onClick={() => {
                          navigateTOCheckOut();
                        }}
                      >
                        Continue to Checkout
                      </MDBBtn>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 my-1 my-md-4 my-lg-4 my-xl-4 rounded-3 col-12 mx-auto radius12 order-lg-2 order-1 px-0">
              {<Cart checkout={false} />}
            </div>
          </>
        )}
      </div>
      {!!openAddressModal && (
        <AddAddress open={openAddressModal} setOpen={setOpenAddressModal} />
      )}
    </>
  );
}
