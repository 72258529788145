import {
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
} from "mdb-react-ui-kit";
import { ReactComponent as Cross } from "../../assets/authcross.svg";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Otp from "./Otp/Otp";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import SetPassword from "./SetPassword/SetPassword";
import PasswordChanged from "./PasswordChanged/PasswordChanged";
import CheckEmail from "./CheckEmail/CheckEmail";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { setLoginState } from "../../redux/features/auth/authSlice";

export default function Authentication({ open, setOpen }) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    location.pathname.includes("register") ? (
      <Register />
    ) : location.pathname.includes("login") ||
      location.pathname.includes("home") ? (
      <Login />
    ) : location.pathname.includes("forgetpassword") ? (
      <ForgetPassword />
    ) : location.pathname.includes("setpassword") ? (
      <SetPassword />
    ) : location.pathname.includes("otp") ? (
      <Otp />
    ) : location.pathname.includes("passwordchanged") ? (
      <PasswordChanged />
    ) : location.pathname.includes("checkemail") ? (
      <CheckEmail />
    ) : (
      <Login />
    );
    // eslint-disable-next-line
  }, [location.pathname]);
  return (
    <>
      <MDBModal
        show={open}
        setShow={(show) => {
          if (!show && open) {
            dispatch(setLoginState(false));
            setOpen(false);
          }
        }}
        staticBackdrop
        tabIndex="-1"
      >
        <MDBModalDialog centered size="md">
          <MDBModalContent>
            <MDBModalBody>
              <Cross
                className="cursor float-end"
                onClick={() => dispatch(setLoginState(false))}
              />
              {location.pathname.includes("register") ? (
                <Register />
              ) : location.pathname.includes("login") ||
                location.pathname.includes("home") ? (
                <Login setOpen={setOpen} />
              ) : location.pathname.includes("forgetpassword") ? (
                <ForgetPassword />
              ) : location.pathname.includes("setpassword") ? (
                <SetPassword />
              ) : location.pathname.includes("otp") ? (
                <Otp setOpen={setOpen} />
              ) : location.pathname.includes("passwordchanged") ? (
                <PasswordChanged />
              ) : location.pathname.includes("onboarding") ? (
                <CheckEmail />
              ) : (
                <Login />
              )}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
