import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";

import { ReactComponent as Cross } from "../../../assets/authcross.svg";
import { useForm } from "react-hook-form";
import AddressModalForm from "./AddressModalForm";
import { addressResolver } from "../../../validators/accountResolver";
import {
  useLazyGetCityListQuery,
  useLazyGetCountryListQuery,
  useLazyGetRegionListQuery,
} from "../../../redux/features/account/cityApiSlice";
import {
  useEditAddressMutation,
  useLazyGetAddressByIdQuery,
} from "../../../redux/features/account/addressesApiSlice";
import Loader from "../../../shared/UI/Loader/Loader";
import { useAppSelector } from "../../../redux/hooks";
const defaultValues = {
  country: "",
  street: "",
  additionalInfo: "",
  state: "",
  city: "",
  zipCode: "",
  mblnumber: 0,
  isDefault: false,
};
export default function EditAddress({ open, setOpen, addressId }) {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: addressResolver,
  });
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [getCountries, result] = useLazyGetCountryListQuery();
  const [getRegions, resultRegionsList] = useLazyGetRegionListQuery();
  const [editAddress, { isLoading }] = useEditAddressMutation();
  const [getCities, resultCitiesList] = useLazyGetCityListQuery();
  const [getAddressById, resultAddress] = useLazyGetAddressByIdQuery();
  const toggleShow = () => setOpen(!open);
  const [countries, setCountries] = useState();
  const [regions, setRegions] = useState();
  const [cities, setCities] = useState();

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, [result?.data]);
  useEffect(() => {
    if (result?.data) {
      setCountries(result?.data);
    }
  }, [result?.data]);
  useEffect(() => {
    if (countries?.length > 0) {
      getRegions({ cId: watch("country") });
    }
    // eslint-disable-next-line
  }, [countries, watch("country")]);
  useEffect(() => {
    if (
      countries?.length > 0 &&
      resultRegionsList?.data &&
      !!watch("country")
    ) {
      setRegions(resultRegionsList?.data);
    }
    // eslint-disable-next-line
  }, [resultRegionsList?.data, countries]);
  useEffect(() => {
    if (countries?.length > 0 && regions?.length > 0) {
      getCities({ provinceCode: watch("state") });
    }
    // eslint-disable-next-line
  }, [countries, watch("state")]);
  useEffect(() => {
    if (countries?.length > 0 && resultCitiesList?.data) {
      setCities(resultCitiesList?.data);
    }
  }, [resultCitiesList?.data, countries, regions]);

  useEffect(() => {
    getAddressById({ addressesId: addressId });
    // eslint-disable-next-line
  }, [addressId]);
  useEffect(() => {
    if (resultAddress?.data) {
      setValue("country", resultAddress?.data?.country?.countryId ?? "");
      setValue("state", resultAddress?.data?.province?.provinceCode ?? "");
      setValue("city", resultAddress?.data?.city?.cityId ?? "");
      setValue(
        "additionalInfo",
        resultAddress?.data?.additionalInformation ?? ""
      );
      setValue("street", resultAddress?.data?.address ?? "");
      setValue("zipCode", resultAddress?.data?.postalCode ?? "");
      setValue("mblnumber", resultAddress?.data?.phoneNo ?? "");
      setValue("isDefault", resultAddress?.data?.setDefault);
    }
    // eslint-disable-next-line
  }, [resultAddress?.data]);
  const onSubmit = async (values) => {
    const result = await editAddress({
      addressesId: addressId,
      address: values?.street ?? "",
      stateId: values?.state ?? "",
      userId: user?.userId ?? 0,
      setDefault: values?.isDefault,
      phoneNo: "+" + values?.mblnumber,
      postalCode: values?.zipCode,
      cityId: values?.city ?? 0,
      countryId: values?.country ?? 0,
    });
    if ("data" in result && result.data.status === "SUCCESS") {
      setOpen(false);
    }
  };

  return (
    <>
      <MDBModal show={open} setShow={setOpen} tabIndex="-1" staticBackdrop>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody>
              <div className="pb-3">
                <Cross className="cursor float-end" onClick={toggleShow} />
              </div>
              <div className="mt-3">
                <h5 className="black text-center fw-bold">Edit Address</h5>
                <p className="text-center">
                  This address will be added to your saved addresses list
                </p>
                <div className="mx-3 mt-5">
                  {resultAddress?.isLoading || result?.isLoading ? (
                    <div className="my-5">
                      <Loader />
                    </div>
                  ) : (
                    <AddressModalForm
                      type={"Edit"}
                      countriesList={countries ?? []}
                      regionsList={regions ?? []}
                      citiesList={cities ?? []}
                      isLoading={isLoading}
                      control={control}
                      errors={errors}
                      onSubmit={handleSubmit(onSubmit)}
                    />
                  )}
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
