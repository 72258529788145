import React, { useState } from "react";
import {
  ElementsConsumer,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useAddPaymentMutation } from "../../redux/features/account/paymentApiSlice";
import ButtonLoader from "../../shared/UI/Loader/ButtonLoader";
import { store } from "../../redux/store";
import { setErrorMessage } from "../../redux/features/common/commonSlice";
import { useAppSelector } from "../../redux/hooks";
import { businessId } from "../../config";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import FormValidationError from "../../shared/UI/FormValidationError";

const PaymentForm = ({ setOpen, giftcard, setOpenSuccessModal }) => {
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const stripe = useStripe();
  const elements = useElements();
  const [holderName, setHolderName] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(false);

  const [addPayment, { isLoading }] = useAddPaymentMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (holderName === "") {
      setNameError("Enter Card Holder Name");
    } else {
      setLoading(true);
      if (!stripe || !elements) {
        return;
      }
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
      if (result.error) {
        setLoading(false);
        store.dispatch(setErrorMessage(result.error.message));
      } else {
        setLoading(false);
        const resultPayment = await addPayment({
          stripeToken: result?.token?.id ?? "",
          businessId: businessId ?? "",
          userId: user?.userId ?? 0,
          cardNo: result?.token?.card?.last4,
          holderName: holderName,
          cardType: result?.token?.card?.brand,
          expiry:
            result?.token?.card?.exp_month +
            "/" +
            result?.token?.card?.exp_year,
        });
        if (
          "data" in resultPayment &&
          resultPayment.data.status === "SUCCESS"
        ) {
          setOpen(false);
        }
      }
    }
  };
  const onPaymentSubmit = (e) => {
    e.preventDefault();
    if (holderName === "") {
      setNameError("Enter Card Holder Name");
    } else {
      setLoading(true);
      if (!stripe || !elements) {
        return;
      }
      setOpen(false);
      setOpenSuccessModal(true);
    }
  };
  return (
    <div>
      <form onSubmit={giftcard ? onPaymentSubmit : handleSubmit}>
        <MDBInput
          className={`${nameError && "is-invalid"} mb-3`}
          label="Card Holder Name"
          size="lg"
          type="text"
          value={holderName}
          onChange={(e) => {
            setHolderName(e?.target?.value);
            setNameError("");
          }}
        />
        <FormValidationError errorMessage={nameError} />
        <div className="mt-2 mb-3">
          <div
            className="form-control"
            style={{
              height: "2.6em",
              paddingTop: ".7em",
              paddingBottom: ".7em",
            }}
          >
            <CardElement
              options={{
                style: {
                  base: {
                    color: "#000000",
                    fontSize: "16px",
                    fontFamily: "sans-serif",
                    fontSmoothing: "antialiased",
                    "::placeholder": {
                      color: "#7a7a7a",
                    },
                  },
                  invalid: {
                    color: "#e5424d",
                    ":focus": {
                      color: "#303238",
                    },
                  },
                },
              }}
            />{" "}
          </div>
        </div>
        <MDBBtn
          className="bgRed shadow-none col-12 mt-3"
          disabled={!stripe || !elements}
          size="lg"
        >
          {giftcard ? (
            "Pay Now"
          ) : (
            <>
              {loading || isLoading ? <ButtonLoader /> : "Add Payment Method"}
            </>
          )}
        </MDBBtn>
      </form>
    </div>
  );
};

export default function InjectedPaymentForm({
  setOpen,
  giftcard,
  setOpenSuccessModal,
}) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <PaymentForm
          stripe={stripe}
          elements={elements}
          setOpen={setOpen}
          giftcard={giftcard}
          setOpenSuccessModal={setOpenSuccessModal}
        />
      )}
    </ElementsConsumer>
  );
}
