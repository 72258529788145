import { MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import Loader from "../../shared/UI/Loader/Loader";
import {
  useLazyGetPromotionsQuery,
  useLazyGetUserPromotionsListQuery,
} from "../../redux/features/loyaltypoints/loyaltyPointsApiSlice";
import { businessId } from "../../config";
import { format } from "date-fns";
import Footer from "../../shared/UI/Footer/Footer";
import PromotionModal from "../../components/Home/PromotionModal";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styles from "./styles.module.css";
import { setLoginState } from "../../redux/features/auth/authSlice";
export default function Promotions() {
  const dispatch = useAppDispatch();
  const [getPromotions, promotionData] = useLazyGetPromotionsQuery();
  const [promotionList, setPromotionList] = useState();
  const [getUserPromotions, userPromotionData] =
    useLazyGetUserPromotionsListQuery();
  const [promList, setPromList] = useState([]);
  const [userProms, setUserProms] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [id, setId] = useState();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const token = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.token
  );
  //console.log(promotionData);
  const location = useAppSelector((state) => state?.common?.location);
  const getPromotionList = async () => {
    getPromotions({
      userId: user?.userId ?? 0,
      businessId: businessId,
      locationId: user?.pickupAddress ?? location?.locationId,
      day: false,
      month: false,
      year: false,
    });
  };
  useEffect(() => {
    if (promotionData?.data) {
      setPromotionList(promotionData?.data);
    }
    // eslint-disable-next-line
  }, [promotionData?.data]);
  useEffect(() => {
    getPromotionList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (userPromotionData?.data) {
      setPromList(
        userPromotionData?.data?.map((item) => item?.promotionDetail)
      );
      setUserProms(userPromotionData?.data);
    }
    // eslint-disable-next-line
  }, [userPromotionData?.data]);
  useEffect(() => {
    if (user) {
      getUserPromotions({
        businessId: businessId,
        userId: user?.userId ?? 0,
      });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (promotionData?.data) {
      setPromotionList(promotionData?.data);
    }
  }, [promotionData?.data]);
  useEffect(() => {
    getPromotionList();
  }, []);
  const openPromotinalModal = (item) => {
    setData(item);
    setId(item?.promotion?.promotionId);
    setOpen(true);
  };
  return (
    <>
      <div style={{ maxWidth: "1450px" }} className="text-center m-auto">
        {promotionData?.isLoading ? (
          <div style={{ margin: "10rem 0", minHeight: "30vh" }}>
            <Loader />
          </div>
        ) : (
          promotionList?.map((item, index) => (
            <>
              <div className={`d-flex flex-md-row flex-column  ${index === 0 && 'mt-4' }  `} >
                <div
                  style={{ background: "#f5f5f5" }}
                  className={`col-12 col-md-6 col-lg-6 col-xl-6 py-5 px-3 text-center 
                ${index % 2 === 0 ? "order-md-1" : "order-md-2"}
                `}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <h1 className="black fw600">{item?.promotion?.name}</h1>
                    <h4 className="black fw400 mt-3">How it works?</h4>
                    <h6 className="black mt-3 px-3">
                      {item?.promotion?.description}
                    </h6>
                    <h6 className="black">
                      {"Will expire on " +
                        format(
                          item?.promotion?.endDate,
                          "MMM dd, yyyy hh:mm a"
                        )}
                    </h6>
                    <MDBBtn
                      className={`shadow-none bgNone px-3 py-1 mt-3 fw400 bgRed`}
                      onClick={() => {
                        if (user) {
                          openPromotinalModal(item, index);
                        } else {
                          dispatch(setLoginState(true));
                        }
                      }}
                      size="lg"
                    >
                      {userProms?.find(
                        (itemm) =>
                          itemm?.promotionId ===
                          promList
                            ?.flat()
                            ?.find(
                              (items) =>
                                items?.promotion?.promotionId ===
                                item?.promotion?.promotionId
                            )?.promotion?.promotionId
                      )?.status === "Started" ||
                      userProms?.find(
                        (itemm) =>
                          itemm?.promotionId ===
                          promList
                            ?.flat()
                            ?.find(
                              (items) =>
                                items?.promotion?.promotionId ===
                                item?.promotion?.promotionId
                            )?.promotion?.promotionId
                      )?.status === "Completed"
                        ? "View Details"
                        : "Start Promotion"}
                    </MDBBtn>
                  </div>
                </div>
                <div
                  className={`${styles.imagee} ${styles.promotionContainer}  ${
                    index % 2 === 0 ? "order-2" : "order-1"
                  } col-12 col-md-6 col-lg-6 col-xl-6`}
                >
                  <img
                    // src={item?.promotion?.imageURL}
                    src={item?.promotion?.imageURL}
                    className="px-4 py-4"
                    alt=""
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </>
          ))
        )}
        <div className="mt-5 pt-5">
          <Footer />
        </div>
      </div>
      <PromotionModal
        showModal={open}
        setShowModal={setOpen}
        data={data}
        id={id}
        setId={setId}
        setData={setData}
      />
    </>
  );
}
