import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBSideNav,
  MDBSideNavItem,
  MDBSideNavMenu,
} from "mdb-react-ui-kit";

import { ReactComponent as Cross } from "../../../assets/x.svg";
import { ReactComponent as BunduKhan } from "../../../assets/bundukhan.svg";
import UploadImage from "./../../../assets/uploadImageIcon.png";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { useLazyGetProfileQuery } from "../../../redux/features/account/profileApiSlice";
import { useAppSelector } from "../../../redux/hooks";
import { businessId } from "../../../config";
import { useDispatch } from "react-redux";
import { logOut, setLoginState } from "../../../redux/features/auth/authSlice";

export default function Drawer({ showDrawer, setShowDrawer }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRestaurantOpen = useAppSelector(
    (state) => state?.common?.isRestaurantOpen
  );

  const [getProfile, result] = useLazyGetProfileQuery();
  const token = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (token) {
      getProfile({
        userId: token?.userId,
        businessId: businessId,
      });
    }
  }, [token]);
  useEffect(() => {
    if (result?.data) {
      setImage(result?.data?.imageURL);
    }
  }, [result?.data]);
  return (
    <div>
      <MDBSideNav
        isOpen={showDrawer}
        right
        getOpenState={(e) => setShowDrawer(e)}
        style={{
          height: "100vh",
          width: "80%",
          top: isRestaurantOpen ? "4rem" : "8rem",
          background: "#E9E9E9",
        }}
      >
        <MDBSideNavMenu className="px-0">
          <MDBSideNavItem>
            <div
              className="d-flex flex-column  mx-5"
              style={{ height: "70vh" }}
            >
              <div>
                <div className="d-flex flex-column justify-content-between mt-3">
                  <h5
                    className="black my-3"
                    role="button"
                    onClick={() => {
                      navigate("/menu");
                      setShowDrawer(false);
                    }}
                  >
                    Menu
                  </h5>
                  {/* <h5
                    className="black my-3"
                    role="button"
                    onClick={() => {
                      navigate("/giftcards");
                      setShowDrawer(false);
                    }}
                  >
                    Gift Cards
                  </h5> */}
                  {token && (
                    <h5
                      className="black my-3"
                      role="button"
                      onClick={() => {
                        navigate("/loyaltypoints");
                        setShowDrawer(false);
                      }}
                    >
                      Rewards
                    </h5>
                  )}
                  <hr />
                  {token && (
                    <>
                      <h5
                        className="black my-3"
                        role="button"
                        onClick={() => {
                          navigate("/orderhistory");
                          setShowDrawer(false);
                        }}
                      >
                        Order History
                      </h5>
                      <h5
                        className="black my-3"
                        role="button"
                        onClick={() => {
                          navigate("/account");
                          setShowDrawer(false);
                        }}
                      >
                        Account Settings
                      </h5>
                      <hr />
                    </>
                  )}
                </div>
              </div>
              {token ? (
                <div className="mt-5">
                  <MDBBtn
                    style={{
                      color: "#000",
                      border: "2px solid #000",
                      borderRadius: "8px",
                      boxShadow: "none",
                      background: "#E9E9E9",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      dispatch(logOut());
                      navigate("/");
                      setShowDrawer(false);
                    }}
                  >
                    Sign Out
                  </MDBBtn>
                </div>
              ) : (
                <div className="mt-5">
                  <MDBBtn
                    style={{
                      color: "#000",
                      border: "2px solid #000",
                      borderRadius: "8px",
                      boxShadow: "none",
                      background: "#E9E9E9",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      dispatch(setLoginState(true));
                      navigate("/");
                      setShowDrawer(false);
                    }}
                  >
                    Sign In
                  </MDBBtn>
                </div>
              )}
            </div>
          </MDBSideNavItem>
        </MDBSideNavMenu>
      </MDBSideNav>
    </div>
  );
}
