import { format } from "date-fns";
import { useLazyGetProfileQuery } from "../../redux/features/account/profileApiSlice";
import { useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import { businessId } from "../../config";

export default function DownloadReceipt({ item, cartData }) {
  const date = new Date();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );

  const [getProfile, result] = useLazyGetProfileQuery();
  useEffect(() => {
    if (user)
      getProfile({
        userId: user?.userId ?? 0,
        businessId: businessId,
      });
    // eslint-disable-next-line
  }, [user, result?.data]);
  var total;
  total = cartData?.reduce(function (prev, current) {
    return prev + current?.perItemTotal * current?.quantity;
  }, 0);
  // const generatePDF = () => {
  //   const doc = new jsPDF('p', 'px', 'a4');
  // const contentElement = document.getElementById('report');

  // const contentWidth = doc.internal.pageSize.getWidth();
  // const scaleFactor = contentWidth / contentElement.offsetWidth;
  // const contentHeight = contentElement.offsetHeight * scaleFactor;

  // doc.html(contentElement, {
  //   callback: () => {
  //     doc.save('example.pdf');
  //   },
  //   x: 0,
  //   y: 0,
  //   width: contentWidth,
  //   height: contentHeight,
  //   html2canvas: { scale: scaleFactor }
  // });
  // };
  return (
    <>
   
      {/* <button onClick={generatePDF} type="button">Export</button> */}
     <div id="report"
      >
      <div>
        <table style={{ width: "100%", fontFamily: "inter" }}>
          <tbody>
            <tr>
              <td
                align="left"
                style={{ fontWeight: "bold", fontSize: 22, color: "#212121" }}
              >
                Resturant's BBQ Receipt
              </td>
              <td align="right">
                <a
                  style={{ textDecoration: "none" }}
                  href="https://mywyzer.com"
                >
                  {/* <img
                    width="72px"
                    src="https://mywyzer.com/businessLogo/8983-Bundu-Khan's-BBQ.png"
                    alt="logo"
                  /> */}
                </a>
              </td>
            </tr>
            <tr>
              <td
                align="left"
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "#212121",
                  padding: "0 0 5px 0",
                }}
              >
                Order # {cartData[0]?.cart?.cartId ?? "-"}
              </td>
            </tr>
            <tr>
              <td
                align="left"
                style={{ fontSize: 10, fontWeight: "bold", color: "#212121" }}
              >
                {format(date, "dd-MM-yyyy H:mm:ss")}
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%", margin: "20px 0", fontFamily: "inter" }}>
          <tbody>
            <tr>
              <td align="left" style={{ fontSize: 10 }}>
                Thank you for your purchase at WAIM's BBQ.
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{
            width: "100%",
            marginTop: 10,
            background: "#feece9",
            padding: "12px 14px",
            fontSize: 10,
            fontWeight: "bold",
            fontFamily: "inter",
          }}
        >
          <tbody>
            <tr>
              <td align="left" style={{ paddingLeft: "14px" }}>
                Customer Name:{" "}
                {result?.data
                  ? (result?.data?.firstName ?? "") +
                    " " +
                    (result?.data?.lastName ?? "")
                  : "-"}
              </td>
            </tr>
            <tr>
              <td align="left" style={{ paddingLeft: "14px" }}>
                Point Balance: 0
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{
            width: "100%",
            marginTop: 30,
            background: "#f54222",
            padding: "12px 14px",
            textTransform: "uppercase",
            color: "#ffffff",
            fontFamily: "inter",
          }}
        >
          <tbody>
            <tr>
              <td
                align="left"
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  padding: "10px 0px 10px 14px",
                }}
              >
                Order Details
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{
            width: "100%",
            color: "#ffffff",
            background: "#feece9",
            borderCollapse: "collapse",
            fontFamily: "inter",
          }}
        >
          <tbody>
            <tr
              style={{
                textTransform: "uppercase",
                fontSize: 8,
                fontWeight: "bold",
                color: "rgba(33, 33, 33, 0.9)",
              }}
            >
              <td align="left" style={{ padding: "12px 14px" }}>
                Items List
              </td>
              <td align="right" style={{ padding: "12px 14px" }}>
                Size
              </td>
              <td align="right" style={{ padding: "12px 14px" }}>
                Price
              </td>
              <td align="right" style={{ padding: "12px 14px" }}>
                Total
              </td>
            </tr>
            {cartData?.map((item, index) => {
              return (
                <tr
                  style={{
                    backgroundColor: "white",
                    fontSize: 10,
                    color: "rgba(33, 33, 33, 0.9)",
                  }}
                >
                  <td align="left" style={{ padding: "12px 14px" }}>
                    {item?.quantity ?? 0} x {item?.item?.name ?? "-"}
                  </td>
                  <td
                    align="right"
                    style={{ fontWeight: "bold", padding: "12px 14px" }}
                  >
                    {item?.size?.size ?? "-"}
                  </td>
                  <td
                    align="right"
                    style={{ fontWeight: "bold", padding: "12px 14px" }}
                  >
                    ${item?.perItemTotal?.toFixed(2) ?? "-"}
                  </td>
                  <td
                    align="right"
                    style={{ fontWeight: "bold", padding: "12px 14px" }}
                  >
                    ${(item?.perItemTotal * item?.quantity)?.toFixed(2) ?? "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <table
          style={{
            width: "100%",
            marginTop: 10,
            fontFamily: "inter",
            borderCollapse: "collapse",
            borderTop: "1px solid #efefef",
            fontSize: 10,
            fontWeight: "bold",
            color: "rgba(33, 33, 33, 0.9)",
          }}
        >
          <tbody>
            <tr style={{ textTransform: "uppercase" }}>
              <td align="left" style={{ padding: "6.875px 14px" }}>
                Sub-total
              </td>
              <td align="right" style={{ padding: "6.875px 14px" }}>
                ${total?.toFixed(2) ?? "-"}
              </td>
            </tr>
            <tr style={{ textTransform: "uppercase" }}>
              <td align="left" style={{ padding: "6.875px 14px" }}>
                GST-8.25%
              </td>
              <td align="right" style={{ padding: "6.875px 14px" }}>
                ${((total / 100) * 8.25)?.toFixed(2) ?? "-"}
              </td>
            </tr>
            <tr style={{ textTransform: "uppercase" }}>
              <td align="left" style={{ padding: "6.875px 14px" }}>
                Total
              </td>
              <td align="right" style={{ padding: "6.875px 14px" }}>
                ${(total + (total / 100) * 8.25)?.toFixed(2) ?? "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ padding: 40, paddingBottom: 20, background: "#f54222" }}>
        <div style={{ width: "100%" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              color: "#fafafa",
            }}
          >
            <tbody>
              <tr style={{ textTransform: "uppercase" }}>
                <td align="left" style={{ fontSize: 10, fontWeight: "bold" }}>
                  Restaurant Information
                </td>
                <td
                  align="right"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    fontFamily: "Space Mono",
                  }}
                >
                  Total Amount
                </td>
              </tr>
              <tr style={{ textTransform: "uppercase" }}>
                <td align="left" style={{ fontSize: 10, width: "50%" }}>
                  13608 Midway Road, Dallas TX, 75244, Dallas, Taxes, United
                  States of America
                </td>
                <td
                  align="right"
                  style={{
                    fontSize: 32,
                    fontWeight: "bold",
                    fontFamily: '"Space Mono"',
                  }}
                >
                  ${(total + (total / 100) * 8.25)?.toFixed(2) ?? "-"}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 10, paddingTop: 5 }}>
                  Tax Number: 85-3565184
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 10, fontWeight: "bold", paddingTop: 5 }}>
                  Thank you!
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 10, fontWeight: "bold" }}>
                  WAIM BBq's Team
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 10, fontWeight: "bold", paddingTop: 5 }}>
                  Please do not reply this e-mail
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ marginTop: 15, width: "100%" }}>
            <tbody>
              <tr align="center">
                <td align="center" style={{ fontSize: 10 }}>
                  <span style={{ color: "#fafafa" }}> Powered by </span>
                  <a
                    style={{ color: "#4e0000", textDecoration: "none" }}
                    href="https://mywyzer.com"
                  >
                    by Wyzer AIM
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </>
  );
}
