import React, { useState } from "react";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBInputGroup,
  MDBBtn,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import styles from "./styles.module.css";

export default function InputField(props) {
  return (
    <MDBValidation className=" row" style={{ paddingLeft: "0px" }}>
      <MDBValidationItem>
        <MDBInput
          style={{
            fontSize: "13px",
            color: "rgb(96, 96, 96", //"#CECECE",
            height: "2.3rem",
          }}
          // className={`form-control ${styles["custom-input-label"]}`}
          value={props.value}
          //   name="Aizaz Abbasi"
          onChange={props.onChange}
          id="validationCustom01"
          required
          label={props.label}
        />
      </MDBValidationItem>
    </MDBValidation>
  );
}
