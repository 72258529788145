import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import "react-multi-carousel/lib/styles.css";
import { ReactComponent as People } from "../../assets/peopleIcon.svg";
import { ReactComponent as Forward } from "../../assets/forwardIcon.svg";
import TabCardContent from "../../components/Home/TabCardContent";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ProfileModal from "../Account/Profile/ProfileModal";
import { businessId } from "../../config";
import AddToCartModal from "../../components/Home/AddToCartModal";
import GroupOrderModal from "../GroupOrder/GroupOrderModal";
import { setAddressType } from "../../redux/features/auth/authSlice";
import { useLazyGetProfileQuery } from "../../redux/features/account/profileApiSlice";
import Loader from "../../shared/UI/Loader/Loader";
import { useLazyGetUserRewardPointQuery } from "../../redux/features/loyaltypoints/loyaltyPointsApiSlice";
import { useNavigate } from "react-router";
import { setLoginState } from "../../redux/features/auth/authSlice";
import LoyaltyPointsBadge from "../../components/Home/LoyaltyPointsBadge";
import { useRef } from "react";
import Cart from "../../components/Home/Cart";
import Footer from "../../shared/UI/Footer/Footer";
import { findRenderedDOMComponentWithTag } from "react-dom/test-utils";
import SelectGroupModal from "../GroupOrder/SelectGroupModal";

export default function Home() {
  const d = new Date();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isRestaurantOpen = useAppSelector(
    (state) => state?.common?.isRestaurantOpen
  );
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const token = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.token
  );
  const categories = useAppSelector((state) => state?.common?.categories);
  const addressType = useAppSelector((state) => state?.auth?.addressType);
  const location = useAppSelector((state) => state?.common?.location);
  const [basicActive, setBasicActive] = useState();
  const [showModal, setShowModal] = useState(false);
  const [groupOrderModal, setGroupOrderModal] = useState(false);
  const [selectGroupModal, setSelectGroupModal] = useState(false);
  const [menuItem, setMenuItem] = useState();
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [groupName, setGroupName] = useState();
  const [getUserRewardPointsData, userRewardPointsResult] =
    useLazyGetUserRewardPointQuery();

  const [getProfile, profileResult] = useLazyGetProfileQuery();
  const [userRewardPoints, setUserRewardPoints] = useState();

  const cartNavigate = () => {
    if (addressType === "pickUp") {
      dispatch(setAddressType({ type: "pickUp" }));
      navigate("/pickdetail");
    } else {
      dispatch(setAddressType({ type: "delivery" }));
      navigate("/deliverydetail");
    }
  };
  const guestCheckOut = () => {
    dispatch(setLoginState(true));
  };
  const enableAddToCartModal = (item) => {
    const meaningfulData = {
      description: item?.description,
      imageURL: item?.imageURL,
      itemSize: item?.itemSize,
      listofVariations: item?.listofVariations?.map((variation) => ({
        variation: {
          variationName: variation?.variation?.variationName,
          listofSubVariations: variation?.variation?.listofSubVariations?.map(
            (subVariation) => ({
              price: subVariation?.price,
              name: subVariation?.name
                ? subVariation?.name
                : subVariation?.item?.name,
              quantity: subVariation?.quantity,
              checked: false,
              subVariationId: subVariation?.subVariationId,
            })
          ),
        },
      })),
      name: item?.name,
      comment: "",
      quantity: 1,
      cartItemId: -1,
      shopItemId: item?.shopItemId,
      price: item?.itemSize[0]?.price,
      sizeId: item?.itemSize[0]?.size?.sizeId,
    };

    setMenuItem(meaningfulData);

    setShowModal(true);
  };
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (user && profileResult.data && !profileResult?.data?.firstName) {
      setOpenProfileModal(true);
    }
  }, [user, profileResult?.data]);

  useEffect(() => {
    if (user) {
      getProfile({
        userId: user?.userId ?? 0,
        businessId: businessId,
      });
      getUserRewardPointsData({
        userId: user?.userId ?? 0,
        businessId: businessId,
        dateTime: d?.toISOString(),
      });
    }
    // eslint-disable-next-line
  }, [user]);
  useEffect(() => {
    if (userRewardPointsResult?.data) {
      setUserRewardPoints(userRewardPointsResult?.data);
    }
  }, [userRewardPointsResult.data]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  useEffect(() => {
    if (categories) {
      handleBasicClick(categories[0]?.categoryId);
    }
    // eslint-disable-next-line
  }, [categories]);

  function smoothScroll(id) {
    const element = document.getElementById(id);
    if (!element) return;
    element.scrollIntoView({});
  }
  function isScrolledIntoView() {
    for (let i = 0; i < categories?.length; i++) {
      const category = categories[i];
      var element = document.getElementById(category.categoryId);
      var position = element.getBoundingClientRect();
      if (
        position.top + 350 < window.innerHeight &&
        position.bottom - 350 >= 0
      ) {
        const container = document?.getElementById("menu-categories");
        const targetElement = document?.getElementById(
          "tab#" + category.categoryId
        );
        container?.scrollTo({
          left: targetElement?.offsetLeft,
          behavior: "smooth",
        });
        handleBasicClick(category.categoryId);
        break;
      }
    }
  }

  const scrollTimeout = useRef(null);
  const handleScroll = () => {
    const menuCategories = document.getElementById("menu-categories");
    const cart = document.getElementById("sticky-cart");
    if (!cart?.style || !menuCategories?.style) return;
    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
      setTimeout(() => {
        isScrolledIntoView();
      }, 0);
    }, 100);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      clearTimeout(scrollTimeout.current);
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [basicActive, scrollTimeout?.current]);
  return (
    <div className="">
      <div className="bg-white px-2 px-md-4 px-lg-5">
        <div id="menu-categories-start"></div>
        <div
          className={
            styles["menu-categories"] + " d-flex justify-content-center w-100"
          }
          style={{ top: isRestaurantOpen ? "0" : "0" }}
        >
          <div
            id="menu-categories"
            className={styles["menu-categories"] + " mx-auto"}
          >
            <MDBTabs className={styles["tabsOverFlow"] + " pb-1"}>
              {categories?.map((category, index) => (
                <div key={index}>
                  <MDBTabsItem
                    onClick={() => {
                      handleBasicClick(category?.categoryId);
                      smoothScroll(category?.categoryId);
                    }}
                    key={index}
                  >
                    <MDBTabsLink
                      active={basicActive === category?.categoryId}
                      id={"tab#" + category.categoryId}
                      className={
                        styles[
                          `menu-categories-item${
                            basicActive === category?.categoryId
                              ? "-active"
                              : ""
                          }`
                        ]
                      }
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          fontSize: "14px",
                        }}
                      >
                        {category.name}
                      </span>
                    </MDBTabsLink>
                  </MDBTabsItem>
                </div>
              ))}
            </MDBTabs>
          </div>
        </div>
        <div>
          <div className="mb-5 pb-5">
            <div className="d-flex">
              {categories.length === 0 ? (
                <div className="text-center " style={{ margin: "5rem auto" }}>
                  <Loader />
                </div>
              ) : (
                <>
                  <div
                    id="categoryContainer"
                    className={
                      styles["categoryContainer"] +
                      " mt-2 col-lg-8 col-md-8 col-12"
                    }
                  >
                    {/* <div className="col-12 col-sm-4 col-md-4 col-lg-4  p-0 my-2">
                      <div
                        className={styles["groupOrderDiv"]}
                        onClick={
                          groupName
                            ? () => setSelectGroupModal(!selectGroupModal)
                            : () => setGroupOrderModal(!groupOrderModal)
                        }
                      >
                        <div className="ms-lg-2 ms-md-2 ms-2">
                          <People />
                          {localStorage.getItem("selectedGroupName") ? (
                            <span className="ms-3">
                              {localStorage.getItem("selectedGroupName")}
                            </span>
                          ) : (
                            <span className="ms-3">Start a group order</span>
                          )}
                        </div>
                        <div className="me-2">
                          <Forward width={8} />
                        </div>
                      </div>
                    </div> */}

                    <div className="row mx-0">
                      {categories?.map((category, i) => (
                        <React.Fragment key={category.categoryId}>
                          <div
                            className={styles["categoryDiv"] + " mb-4 px-0"}
                            key={category?.categoryId}
                            id={category?.categoryId}
                          >
                            <div>
                              <h4 className="fw-bold black">
                                {category?.name}
                              </h4>
                            </div>

                            <div className="row mx-0">
                              {category?.item?.map((item, index) => (
                                <div
                                  key={item?.shopItemId}
                                  onClick={() => {
                                    enableAddToCartModal(item);
                                  }}
                                  className="col-12 col-xl-6 my-2 mx-0 ps-0 pe-md-4 pe-0"
                                  style={{ cursor: "pointer" }}
                                >
                                  <TabCardContent item={item} key={index} />
                                </div>
                              ))}
                            </div>
                            {i !== categories.length - 1 && (
                              <div className="d-none d-lg-block">
                                <hr />
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>

                  <div className={"d-none d-md-block col-lg-4 mt-lg-5 mt-md-4"}>
                    <LoyaltyPointsBadge
                      user={user}
                      userRewardPoints={userRewardPoints}
                    />
                    <Cart
                      address={location?.address ?? "Loading..."}
                      user={user}
                      isSuccess={!!location}
                      cartNavigate={cartNavigate}
                      guestCheckOut={guestCheckOut}
                      token={token}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block">
          <hr />
        </div>
        <div className="mt-5 pt-5">
          <Footer />
        </div>
      </div>
      {!!openProfileModal && (
        <ProfileModal open={openProfileModal} setOpen={setOpenProfileModal} />
      )}
      <AddToCartModal
        showModal={showModal}
        setShowModal={setShowModal}
        menuItem={menuItem}
        groupName={groupName}
      />

      <GroupOrderModal
        showModal={groupOrderModal}
        setShowModal={setGroupOrderModal}
        setGroupName={setGroupName}
      />

      <SelectGroupModal
        selectGroupModal={selectGroupModal}
        setSelectGroupModal={setSelectGroupModal}
        setGroupName={setGroupName}
      />
    </div>
  );
}
