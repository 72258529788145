import React from "react";

export default function FormValidationError({errorMessage}) {
  return (
    <>
      {errorMessage ? (
        <div
          style={{
            fontSize: "smaller",
            color: "#dc4c64",
          }}
        >
          {errorMessage}
        </div>
      ) : null}
    </>
  );
}
