import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { MDBBtn, MDBRadio } from "mdb-react-ui-kit";
import { ReactComponent as Star } from "../../assets/star.svg";
import Cart from "../../components/Cart/Cart";
import { useLocation, useNavigate } from "react-router-dom";
import AddPaymentModal from "../Account/Payments/AddPaymentModal";
import OrderPlacedModal from "../../components/OrderPlacedModal/OrderPlacedModal";
import { useLazyGetPaymentsQuery } from "../../redux/features/account/paymentApiSlice";
import { useAppSelector } from "../../redux/hooks";
import { businessId } from "../../config";
import {
  useLazyGetCartQuery,
  usePlaceOrderMutation,
  usePlaceGroupOrderMutation,
} from "../../redux/features/cart/cartApiSlice";

import ButtonLoader from "../../shared/UI/Loader/ButtonLoader";

export default function Checkout() {
  const navigate = useNavigate();
  const Location = useLocation();
  const cartDataRedux = useAppSelector((state) => state?.cart?.cartData);
  const deliveryAddress = Location?.state?.deliveryAddress;
  const addressId = Location?.state?.addressId;
  const instructions = Location?.state?.instructions;
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const addressType = useAppSelector((state) => state?.auth?.addressType);
  const location = useAppSelector((state) => state?.common?.location);
  const [getPayments, paymentResult] = useLazyGetPaymentsQuery();
  const [cod, setCod] = useState(true);
  const [getCart, result] = useLazyGetCartQuery();
  const [card, setCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const [paymentsList, setPaymentsList] = useState([]);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openOrderPlacedModal, setOpenOrderPlacedModal] = useState(false);
  const [placeOrdr, { isLoading: placeOrderLoading }] = usePlaceOrderMutation();
  const [
    placeGroupOrder,
    {
      data: placeGroupOrderdata,
      isLoading: placeGroupOrderLoading,
      isError: placeGroupOrderIsError,
      error: placeGroupOrderError,
    },
  ] = usePlaceGroupOrderMutation();
  const [cartId, setCartId] = useState();
  const [item, setItem] = useState();
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (result?.data?.length > 0) {
    }
  }, [result?.data]);

  useEffect(() => {
    if (user)
      getCart({
        userId: user?.userId ?? 0,
        businessId: businessId,
        locationId: user?.pickupAddress ?? location,
      });
    // eslint-disable-next-line
  }, [user, location]);
  useEffect(() => {
    getPayments({
      // userId: user?.userId??0,
      userId: user?.userId ?? 0,
      businessId: businessId,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (paymentResult?.data) {
      setPaymentsList(paymentResult?.data);
      if (paymentResult?.data?.length > 0) {
        setSelectedCard(paymentResult?.data[0]?.userStripeId);
      }
    }
  }, [paymentResult?.data]);

  const addItemsInCart = async () => {
    if (cartDataRedux?.length > 0) {
      const cartData = cartDataRedux?.map((obj) => {
        const {
          name,
          description,
          imageURL,
          price,
          cartItemId,
          itemSize,
          ...rest
        } = obj;
        return {
          ...rest,
        };
      });

      const updatedcart = cartData?.map((item) => {
        const subvariations = [];
        const subvariationsQuantity = [];

        if (item?.listofVariations) {
          item.listofVariations?.forEach((variation) => {
            if (
              variation.variation &&
              variation.variation?.listofSubVariations
            ) {
              variation.variation.listofSubVariations?.forEach(
                (subVariation) => {
                  if (subVariation?.checked) {
                    subvariations?.push(subVariation?.subVariationId);
                    subvariationsQuantity?.push(subVariation?.quantity);
                  }
                }
              );
            }
          });
        }

        return {
          comment: item?.comment || "",
          quantity: item?.quantity,
          sizeId: item?.sizeId,
          itemId: item?.shopItemId,
          subvariations,
          subvariationsQuantity,
        };
      });

      const userInformation = {
        userId: user?.userId ?? 0,
        locationId: user?.pickupAddress ?? 0,
        businessId: businessId ?? 0,
        status: "ordered",
        instructions: instructions,
      };
      // if (addressType === "pickUp") {
      userInformation.isPickup = true;
      //}
      //else{
      //userInformation.isPickup = false;
      //}

      if (cod) {
        userInformation.COD = cod;
      } else {
        userInformation.userStripeId = selectedCard;
      }

      //if (addressType === "delivery") {
      //userInformation.deliveryAddress = deliveryAddress;
      //userInformation.addressId = addressId;

      //}

      const wholeData = {};
      wholeData.bodyData = updatedcart;
      wholeData.paramsData = userInformation;
      const result = await placeOrdr(wholeData);

      if ("data" in result && result.data?.status === "SUCCESS") {
        setCartId(result?.data?.payLoad?.cartId);
        setOpenOrderPlacedModal(true);
      }
    }
  };

  const placeGroupOrderHandler = () => {
    placeGroupOrder({
      userId: user?.userId ?? 0, //12387, //user?.userId ?? 0,, 13000
      groupId: localStorage.getItem("selectedGroupId"),
      businessId: 8983, //businessId,
      dateTime: "2023-05-30 06:20:17",
    });

    placeGroupOrderdata && setOpenOrderPlacedModal(true);
  };

  const placeOrder = () => {
    localStorage.getItem("selectedGroupName")
      ? placeGroupOrderHandler()
      : addItemsInCart();
  };

  return (
    <>
      <div className="row col-11 mx-auto">
        <div
          className="col-lg-6 col-xl-7 my-4 col-12 mx-auto bg-white p-xl-5 ps-lg-0 pe-lg-5 py-lg-5 p-sm-4 order-lg-1 order-2"
          style={{ border: "1px solid #EAEAEA", borderRadius: "12px" }}
        >
          <div className="d-flex flex-column justify-content-between col-sm-11 py-sm-3 py-4 mx-auto h-100">
            <div>
              <h6 className="fw-bold black ms-2">Checkout</h6>
              <div className="d-flex flex-sm-row flex-column mx-sm-0 mx-1 align-items-center my-2">
                <div
                  className={
                    styles["border"] +
                    " col-xl-5 col-lg-6 mx-md-2 col-md-5 col-12 col-sm-5 px-3 py-2 my-2"
                  }
                >
                  <MDBRadio
                    label={<>Cash&nbsp;on&nbsp;Delivery</>}
                    labelStyle={{ color: "#606060" }}
                    checked={cod === true}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setCod(true);
                        setCard(false);
                      }
                    }}
                  />
                </div>
                <div
                  className={
                    styles["border"] +
                    " col-xl-6 col-lg-6 mx-sm-2 col-md-6 col-12 col-sm-7 px-3 py-2 my-2"
                  }
                >
                  <MDBRadio
                    label={<>Credit&nbsp;/&nbsp;Debit&nbsp;Card</>}
                    labelStyle={{ color: "#606060" }}
                    checked={card === true}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setCod(false);
                        setCard(true);
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                {cod && (
                  <p className="lightBlack mx-2">
                    You’ll pay upon delivery at your doorstep
                  </p>
                )}
                {card && (
                  <>
                    <h6 className="fw-bold black ms-2">
                      Select Payment Method
                    </h6>
                    {paymentResult?.isLoading ? (
                      <ButtonLoader />
                    ) : (
                      <div style={{ maxHeight: "16rem", overflowY: "scroll" }}>
                        {paymentsList?.length > 0 ? (
                          paymentsList?.map((item, index) => {
                            return (
                              <div key={index}>
                                <div
                                  className={
                                    styles["border"] +
                                    " col-md-11 col-lg-12 col-xl-11 col-12 ms-md-2 px-3 py-2 my-2"
                                  }
                                >
                                  <MDBRadio
                                    label={
                                      <>
                                        {item?.cardType}
                                        <Star className="ms-1" />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star className="me-1" />
                                        {item?.cardNo}
                                      </>
                                    }
                                    labelStyle={{
                                      color: "#606060",
                                      marginLeft: "0.5rem",
                                      fontWeight: "bold",
                                    }}
                                    checked={
                                      item?.userStripeId === selectedCard
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked === true) {
                                        setSelectedCard(item?.userStripeId);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <h6 className="text-center black fw-bold">
                            No Card Exists
                          </h6>
                        )}
                      </div>
                    )}
                    <h6
                      className="fw-bold red ms-md-2 ms-sm-0 ms-2 mt-3 cursor"
                      onClick={() => setOpenPaymentModal(true)}
                    >
                      Add New Payment Method
                    </h6>
                  </>
                )}
              </div>
            </div>
            <div>
              <div className="d-flex flex-sm-row flex-column align-items-center col-sm-11 col-12 col-lg-12 mx-0">
                <MDBBtn
                  className={
                    styles["btnUpload"] +
                    " shadow-none border-0 ms-md-2 ms-lg-2 ms-xl-2 fw-bold col-lg-4 col-xl-3 col-md-5 col-sm-5 col-12 my-1"
                  }
                  size="lg"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </MDBBtn>
                <MDBBtn
                  className={
                    " shadow-none border-0  fw-bold bgRed my-1 ms-sm-3 col-xl-8 col-lg-8 col-md-7 col-sm-8 col-12"
                  }
                  size="lg"
                  onClick={() => {
                    placeOrder();
                  }}
                >
                  {placeOrderLoading ? <ButtonLoader /> : "Place Order"}
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5 my-4 rounded-3 col-12 mx-auto radius12 order-lg-2 order-1 px-0">
          <Cart checkout={true} />
        </div>
      </div>
      {!!openPaymentModal && (
        <AddPaymentModal
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
        />
      )}
      {!!openOrderPlacedModal && (
        <OrderPlacedModal
          open={openOrderPlacedModal}
          setOpen={setOpenOrderPlacedModal}
          item={item}
          cartId={cartId}
        />
      )}
    </>
  );
}
