import { Controller } from "react-hook-form";
import FormValidationError from "../../shared/UI/FormValidationError";
import { MDBBtn, MDBCheckbox, MDBDatepicker, MDBInput } from "mdb-react-ui-kit";

export default function PaymentModalForm({ control, errors, onSubmit }) {
  return (
    <>
      <div className="my-3">
        <Controller
          control={control}
          name="cardholderName"
          render={({ field: { onChange, value } }) => (
            <MDBInput
              className={`${errors.cardholderName && "is-invalid"}`}
              label="Card Holder Name*"
              type="text"
              onChange={onChange}
              value={value}
              size="lg"
            />
          )}
        />
        <FormValidationError errorMessage={errors.cardholderName?.message} />
      </div>
      <div className="my-3">
        <Controller
          control={control}
          name="cardNo"
          render={({ field: { onChange, value } }) => (
            <MDBInput
              className={`${errors.cardNo && "is-invalid"}`}
              label="Card Number*"
              type="number"
              onChange={onChange}
              value={value}
              size="lg"
            />
          )}
        />
        <FormValidationError errorMessage={errors.cardNo?.message} />
      </div>
      <div className="row mx-0 px-0 my-3">
        <div className=" px-0" style={{ width: "48%" }}>
          <Controller
            control={control}
            name="expDate"
            render={({ field: { onChange, value } }) => (
              <MDBDatepicker
                className={`${errors.expDate && "is-invalid"}`}
                label="Expiry Date"
                format="yyyy-mm-dd"
                disablePast
                onChange={onChange}
                value={value}
                size="lg"
              />
            )}
          />
          <FormValidationError errorMessage={errors.expDate?.message} />
        </div>
        <div className=" px-0 ms-3" style={{ width: "48%" }}>
          <Controller
            control={control}
            name="cvv"
            render={({ field: { onChange, value } }) => (
              <MDBInput
                className={`${errors.cvv && "is-invalid"}`}
                label="CVV*"
                type="number"
                onChange={onChange}
                value={value}
                size="lg"
              />
            )}
          />
          <FormValidationError errorMessage={errors.cvv?.message} />
        </div>
      </div>
      <div className="my-3">
        <Controller
          control={control}
          name="isDefault"
          render={({ field: { onChange, value } }) => (
            <MDBCheckbox
              label={"Set as Default"}
              labelStyle={{ color: "#BFBFBF" }}
              checked={value === true}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="my-4">
        <MDBBtn
          className="bgRed shadow-none col-12"
          onClick={onSubmit}
          size="lg"
        >
          Add Payment Method
        </MDBBtn>
      </div>
    </>
  );
}
