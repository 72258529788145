import { ReactComponent as Invoice } from "../../assets/invoice.svg";
import { ReactComponent as Cross } from "../../assets/authcross.svg";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { setEmptyCartAfterOrder } from "../../redux/features/cart/cartSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useLazyGetCartDetailsQuery } from "../../redux/features/cart/cartApiSlice";
import { useEffect, useState } from "react";
import { businessId } from "../../config";

export default function OrderPlacedModal({ open, setOpen, cartId, item }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [cartData, setCartData] = useState([]);

  const [getCartDetails, result] = useLazyGetCartDetailsQuery();

  useEffect(() => {
    getCartDetails({
      businessId,
      userId: user?.userId ?? 0,
      cartId: cartId,
    });
    // eslint-disable-next-line
  }, [cartId]);
  useEffect(() => {
    if (result.data?.length) {
      setCartData(result?.data);
    }
  }, [result?.data]);
  return (
    <>
      <MDBModal show={open} staticBackdrop setShow={setOpen} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody>
              <div className="pb-3">
                <Cross
                  className="cursor float-end"
                  onClick={() => {
                    setOpen(false);
                    navigate("/home");
                    dispatch(setEmptyCartAfterOrder());
                  }}
                />
              </div>

              <div className="my-4">
                <h6 className="text-center">
                  <Invoice />
                </h6>
                <h6 className="fw-bold black text-center my-3">
                  Order Placed Successfully!
                </h6>
                <small className="text-center mx-auto d-block">
                  Your order has been placed successfully!
                  <br />
                  It will take 45 minutes to be ready, Please be on-time.
                </small>
                {/* <PDFViewer>{MyDocument}</PDFViewer> */}

                <div className="d-flex flex-column align-items-center justify-content-center mt-4 col-md-10 col-12 mx-auto">
                  <MDBBtn
                    className={" shadow-none ms-2 fw-bold col-10 my-2"}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      border: "2px solid #F54222",
                      color: "#F54222",
                    }}
                    size="lg"
                    onClick={() => {
                      navigate("/trackorder/" + cartId);
                      dispatch(setEmptyCartAfterOrder());
                    }}
                  >
                    Track Order
                  </MDBBtn>
                  <MDBBtn
                    className={" shadow-none border-0 ms-2 fw-bold col-10 my-2"}
                    style={{
                      backgroundColor: "rgb(245, 66, 34, 0.3)",
                      borderRadius: "8px",
                      color: "#f54222",
                    }}
                    size="lg"
                  >
                    <a
                      style={{ color: "#f54222" }}
                      href={`https://d13di7fagpzobw.cloudfront.net/generateReceipt?cartId=${cartId}&businessId=${businessId}`}
                      // href={`https://mywyzer.com:8443/generateReceipt?cartId=${cartId}&businessId=${businessId}`}
                    >
                      Download Receipt
                    </a>
                  </MDBBtn>
                  <MDBBtn
                    className={
                      " shadow-none border-0 ms-2 fw-bold bgRed col-10 my-2"
                    }
                    size="lg"
                    onClick={() => {
                      navigate("/home");
                      dispatch(setEmptyCartAfterOrder());
                    }}
                  >
                    Continue Ordering
                  </MDBBtn>
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
