import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { ReactComponent as SuccessIcon } from "../../../assets/success.svg";
import { ReactComponent as FailureIcon } from "../../../assets/failure.svg";
import { useEffect } from "react";
import { store } from "../../../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../redux/features/common/commonSlice";

export default function MessageDialog({ open, setOpen, message, type }) {
  useEffect(() => {
    setTimeout(() => {
      store.dispatch(setSuccessMessage(""));
      store.dispatch(setErrorMessage(""));
    }, 1500);
  }, [message]);
  return (
    <>
      <MDBModal show={open} staticBackdrop setShow={setOpen} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody>
              <div>
                <div className="d-flex justify-content-center">
                  {type === "failure" ? (
                    <FailureIcon width={60} />
                  ) : (
                    type === "success" && <SuccessIcon width={60} />
                  )}
                </div>
                <div className="text-center mt-3">{message}</div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
