import { Stepper } from "react-form-stepper";
import ItemCard from "./ItemCard";
import { MDBTextArea } from "mdb-react-ui-kit";
import { useLazyGetCartDetailsQuery } from "../../redux/features/cart/cartApiSlice";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { businessId } from "../../config";
import styles from "./styles.module.css";
import Loader from "../../shared/UI/Loader/Loader";
import { getDateFromMillis, getTimeFromMillis } from "../../helper/utilis";
import { useState } from "react";

export default function OrderTracking() {
  const [orderStates, setOrderStates] = useState([
    "Pending",
    "Ordered",
    "In Progress",
    "Ready",
    "Delivered",
  ]);
  const location = useSelector((state) => state?.common?.location);

  const token = useSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const params = useParams();
  const [getCartDetails, result] = useLazyGetCartDetailsQuery();

  useEffect(() => {
    if (!token || !params?.cartId) return;
    getCartDetails({
      businessId,
      userId: token?.userId ?? 0,
      cartId: params?.cartId,
    });
    // eslint-disable-next-line
  }, [params?.cartId]);

  useEffect(() => {
    if (result.data?.length && result?.data[0]?.cart?.status === "Cancelled") {
      setOrderStates(["Pending", "Ordered", "Cancelled"]);
    }
  }, [result?.data]);
  const getActiveStep = () => {
    if (result?.data?.length > 0) {
      return orderStates.findIndex(
        (value) => value === result?.data[0]?.cart?.status
      );
    }
    return -1;
  };
  const getTax = () => {
    const data = result.data;
    if (data?.length > 0) {
      let total = data?.reduce((prev, curr) => {
        return prev + curr?.perItemTotal;
      }, 0);
      return parseFloat(((total * location?.taxRate) / 100).toFixed(2));
    }
    return "-";
  };
  const getSubTotal = () => {
    const data = result.data;
    if (data?.length > 0) {
      const subTotal = data.reduce((prev, curr) => {
        return prev + curr?.perItemTotal;
      }, 0);
      return parseFloat(subTotal?.toFixed(2));
    }
    return "-";
  };
  return (
    <div className="bg-white">
      <div className="col-md-11 col-11 mx-auto">
        <div className="px-md-1 ">
          <h3 className="pt-5 black fw600 ps-md-2 ps-3">Order Status</h3>
          {result?.isLoading ? (
            <div style={{ margin: "5rem 0rem" }}>
              <Loader />
            </div>
          ) : (
            <>
              <div className="overflow-auto">
                <div style={{ minWidth: "400px" }}>
                  <Stepper
                    steps={orderStates?.map((state) => {
                      return { label: state };
                    })}
                    className={" px-0"}
                    activeStep={getActiveStep()}
                    connectorStateColors={true}
                    connectorStyleConfig={{
                      size: 3,
                      activeColor: "#F54222",
                      completedColor: "#F54222",
                      disabledColor: "#BFBFBF",
                    }}
                    styleConfig={{
                      activeBgColor: "#F54222",
                      completedBgColor: "#F54222",
                      labelFontSize: "0.8rem",
                      fontWeight: "600",
                    }}
                  />
                </div>
              </div>
              <div className={styles["detail"] + " py-5 m-auto bg-white"}>
                <div className="d-flex justify-content-between flex-wrap">
                  <h4 className="fw600 black text-nowrap ms-md-2">
                    Order Details
                  </h4>
                  <div className="d-flex flex-wrap">
                    <div className="mx-md-2 me-3">
                      <span className="fw600 black"> Order Date & Time: </span>
                      {result?.data?.length > 0
                        ? getDateFromMillis(result?.data[0]?.cart?.orderDate) +
                          " " +
                          getTimeFromMillis(result?.data[0]?.cart?.orderDate)
                        : "Loading..."}
                    </div>
                    <div className="mx-md-2 ms-0 me-3">
                      <span className="fw600 black">Order Type: </span>
                      {result?.data?.length > 0 ? (
                        <>
                          {/* {result?.data[0]?.cart?.deliveryType === "pickup"
                            ? "Pickup"
                            : "Delivery"} */}
                            {result?.data[0]?.cart?.pickup ? "Pickup" : "Delivery"}
                        </>
                      ) : (
                        "Loading..."
                      )}
                    </div>
                    <div className="mx-md-2">
                      {" "}
                      <span className="fw600 black"> Order #</span>{" "}
                      {params?.cartId ?? "-"}
                    </div>
                  </div>
                </div>
                <div className="row mx-0 mb-2">
                  <>
                    {result?.data?.map((data) => {
                      return (
                        <ItemCard
                          key={data?.cartDetailId}
                          imageURL={data?.item?.imageURL}
                          title={data?.item?.name}
                          description={data?.item?.description}
                          size={data?.size?.size}
                          price={data?.perItemTotal}
                          quantity={data?.quantity}
                        />
                      );
                    })}
                  </>
                </div>
                <div className="row mx-0">
                  <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3 px-md-2 px-0">
                    <label className="fw600 lightBlack mb-2">
                      Special instructions
                    </label>
                    <MDBTextArea
                      rows={4}
                      value={
                        result?.data?.length > 0
                          ? result?.data[0]?.cart?.instructions ?? ""
                          : "No Instructions"
                      }
                      disabled
                    ></MDBTextArea>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3 px-md-2 px-0">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="fw600 lightBlack">Payment Details</div>
                      <div className="d-md-flex d-none">
                        <div>
                          <span className="fw600 lightBlack"> Type:&nbsp;</span>
                          {result?.data?.length > 0 ? (
                            <>
                              {result?.data[0]?.cart?.cod === true
                                ? "Cash On Delivery "
                                : "Credit/Debit Card "}
                            </>
                          ) : (
                            "Loading..."
                          )}{" "}
                        </div>
                        <div className="ms-2">
                          <span className="fw600 lightBlack">Status: </span>
                          {result?.data?.length > 0 ? (
                            <>
                              {result?.data[0]?.cart?.paid === true ? (
                                <span style={{ color: "#0EA472" }}> Paid</span>
                              ) : (
                                <span style={{ color: "red" }}> Unpaid</span>
                              )}
                            </>
                          ) : (
                            "Loading..."
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between d-md-none mb-2">
                      <div>
                        <span className="fw600 lightBlack"> Type:&nbsp;</span>
                        {result?.data?.length > 0 ? (
                          <>
                            {result?.data[0]?.cart?.cod === true
                              ? "Cash On Delivery "
                              : "Credit/Debit Card "}
                          </>
                        ) : (
                          "Loading..."
                        )}{" "}
                      </div>
                      <div className="">
                        <span className="fw600 lightBlack">Status: </span>
                        {result?.data?.length > 0 ? (
                          <>
                            {result?.data[0]?.cart?.paid === true ? (
                              <span style={{ color: "#0EA472" }}> Paid</span>
                            ) : (
                              <span style={{ color: "red" }}> Unpaid</span>
                            )}
                          </>
                        ) : (
                          "Loading..."
                        )}
                      </div>
                    </div>
                    <div className="p-3 border" style={{ borderRadius: "8px" }}>
                      <div className="d-flex justify-content-between">
                        <div className="fs-small fw600 lightBlack">
                          Sub Total
                        </div>
                        <div className="fs-small">${getSubTotal()}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="fs-small fw600 lightBlack">
                          Tax {location?.taxRate}%
                        </div>
                        <div className="fs-small">${getTax()}</div>
                      </div>
                      <hr className=" mb-1" />
                      <div className="d-flex justify-content-between">
                        <div className="fs-small fw600 lightBlack">
                          Total (incl. GST)
                        </div>
                        <div className="fs-small">
                          $
                          {result?.data?.length > 0
                            ? `${(getSubTotal()+getTax()).toFixed(2)}` 
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
