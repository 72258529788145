import styles from "./styles.module.css";
import { ReactComponent as ProfileIcon } from "../../assets/profile.svg";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
// import { ReactComponent as LockIcon } from "../../assets/lock.svg";
import { ReactComponent as Address } from "../../assets/address.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Payments } from "../../assets/payments.svg";
import { useEffect } from "react";
export default function Account(params) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="py-4 mx-3 mx-md-4 mx-lg-5 my-1  ">
      <div
        className={styles["changepasswordbox"]}
        style={{
          minHeight: "75vh",
        }}
      >
        <div className="col-md-11 col-12 mx-auto">
          <h5 className="fw-bold black mx-md-5 mx-4 ps-md-2 ps-lg-0 mt-5 py-2">
            Account Settings
          </h5>

          <div className="row mx-md-4 mx-3 ">
            <div
              className={`col-lg-5 col-12 col-md-5 py-2 col-sm-11 my-2 py-3 py-md-2 mx-md-3 ${styles.profile}`}
            >
              <div
                className="d-flex justify-content-between cursor"
                onClick={() => navigate("/account/profile")}
              >
                <p className="black fw400 mb-0">
                  <ProfileIcon width={30} className=" me-4" />
                  Profile Settings
                </p>
                <div>
                  <ArrowIcon className="me-sm-4" />
                </div>
              </div>
            </div>

            <div
              className={`col-lg-5 col-12 col-md-5 py-2 col-sm-11 my-2 py-3 py-md-2 mx-md-3 ${styles.profile}`}
            >
              <div
                className="d-flex justify-content-between cursor"
                onClick={() => navigate("/account/addresses")}
              >
                <div className="black fw400">
                  <Address width={30} className=" me-4" />
                  Addresses
                </div>
                <div>
                  <ArrowIcon className="me-sm-4" />
                </div>
              </div>
            </div>

            <div
              className={`col-lg-5 col-12 col-md-5 py-2 col-sm-11 my-2 py-3 py-md-2 mx-md-3 ${styles.profile}`}
            >
              <div
                className="d-flex justify-content-between cursor"
                onClick={() => navigate("/account/payments/payment")}
              >
                <div className="black fw400">
                  <Payments width={30} className=" me-4" />
                  Payments
                </div>
                <div>
                  <ArrowIcon className="me-sm-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
