import { apiSlice } from "../../api/apiSlice";
import { store } from "../../store";
import { setFavouriteItems, setSuccessMessage } from "../common/commonSlice";

export const cartApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Cart"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      placeOrder: builder.mutation({
        query: (data) => {
          return {
            url:
              "/Cart/placeOrder?" +
              new URLSearchParams({
                ...data?.paramsData,
              }).toString(),
            method: "POST",
            body: data?.bodyData,
          };
        },
        invalidatesTags: ["Cart"],
        transformResponse: (result) => {
          return result;
        },
      }),
      getCartDetails: builder.query({
        query: (data) => ({
          url:
            "/Cart/getCartDetail?" +
            new URLSearchParams({
              ...data,
            }).toString(),

          method: "GET",
        }),
        invalidatesTags: ["Cart"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
      deleteCart: builder.mutation({
        query: (data) => ({
          url:
            "/Cart/deleteCartItem?" +
            new URLSearchParams({
              ...data,
            }).toString(),

          method: "POST",
        }),
        invalidatesTags: ["Cart"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result.message));
          return result.payLoad;
        },
      }),
      updateCart: builder.mutation({
        query: (data) => ({
          url:
            "/Cart/updateCart?" +
            new URLSearchParams({
              ...data,
            }).toString(),

          method: "POST",
        }),
        invalidatesTags: ["Cart"],
        transformResponse: (result) => {
          return result;
        },
      }),

      getCart: builder.query({
        query: (data) => ({
          url:
            "/Cart/getCartOfUser?" +
            new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["Cart"],
        transformResponse: (result) => {
          return result?.payLoad;
        },
      }),
      //addItemByGroup
      addItemByGroup: builder.mutation({
        query: (data) => ({
          url: `/Cart/groupCart/addItemByGroup?userId=${data.userId}&itemId=${data.itemId}&sizeId=${data.sizeId}&businessId=${data.businessId}&locationId=${data.locationId}&quantity=${data.quantity}&groupId=${data.groupId}`,
          method: "POST",
          // body: data.bodyData,
        }),
        invalidatesTags: ["Cart"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),

      //place order bygroup
      placeGroupOrder: builder.mutation({
        query: (data) => ({
          url: `/Cart/placeGroupOrder?userId=${data.userId}&groupId=${data.groupId}&businessId=${data.businessId}&dateTime=${data.dateTime}`,
          method: "POST",
          // body: data.bodyData,
        }),
        invalidatesTags: ["Cart"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
    }),
  });
export const favApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Fav"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      addToFavourite: builder.mutation({
        query: (data) => ({
          url:
            "/Favorite/add?" +
            new URLSearchParams({
              ...data,
            }).toString(),

          method: "POST",
        }),
        invalidatesTags: ["Fav"],
        transformResponse: (result) => {
          return result;
        },
      }),

      deleteFromFavourite: builder.mutation({
        query: (data) => ({
          url:
            "/Favorite/delete?" +
            new URLSearchParams({
              ...data,
            }).toString(),

          method: "POST",
        }),
        invalidatesTags: ["Fav"],
        transformResponse: (result) => {
          return result;
        },
      }),
      getFavList: builder.query({
        query: (data) => ({
          url:
            "Favorite/getList?" +
            new URLSearchParams({
              ...data,
            }).toString(),

          method: "GET",
        }),
        providesTags: ["Fav"],
        transformResponse: (result) => {
          store.dispatch(setFavouriteItems(result.payLoad ?? []));
          return result.payLoad;
        },
      }),
    }),
  });
export const {
  useDeleteCartMutation,
  useLazyGetCartQuery,
  useLazyGetCartDetailsQuery,
  useUpdateCartMutation,
  usePlaceOrderMutation,
  useAddItemByGroupMutation,
  usePlaceGroupOrderMutation,
} = cartApiSlice;
export const {
  useAddToFavouriteMutation,
  useDeleteFromFavouriteMutation,
  useLazyGetFavListQuery,
} = favApiSlice;
