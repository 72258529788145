
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import 'firebase/auth';
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxEelFe77wzvvMbPH5cSVh058nfMAujNA",
  authDomain: "loyality-system-customer.firebaseapp.com",
  databaseURL: "https://loyality-system-customer.firebaseio.com",
  projectId: "loyality-system-customer",
  storageBucket: "loyality-system-customer.appspot.com",
  messagingSenderId: "944615446230",
  appId: "1:944615446230:web:8c51f63235fb0090549b77"
};

// Initialize Firebase
const Firebase = initializeApp(firebaseConfig);
// const app = ​​initializeApp(firebaseConfig);
export const auth = getAuth(Firebase);
// ​​const db = getFirestore(Firebase);
export default Firebase; 

