import { MDBInput } from "mdb-react-ui-kit";
import React, { useState } from "react";
import './styles.css'

export default function MDBPassword(props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <MDBInput {...props} type={`${showPassword ? "text" : "password"}`}>
      <div
        role={"button"}
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <div className={`${showPassword && "d-none"}`}>
          <i className={`fas fa-eye-slash trailing`} style={{color:"#A3A3A3"}}></i>
        </div>
        <div className={`${!showPassword && "d-none"}`}>
          <i className={`fas fa-eye trailing`} style={{color:"#A3A3A3"}}></i>
        </div>
      </div>
    </MDBInput>
  );
}
