import { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { ReactComponent as MinusIcon } from "../../assets/minus.svg";
import {
  useDeleteCartMutation,
  useLazyGetCartQuery,
} from "../../redux/features/cart/cartApiSlice";

import { ReactComponent as People } from "../../assets/peopleIcon.svg";
import DownwardArrow from "../../assets/DownwardArrow.png";
import selfOrder from "../../assets/selfOrder.png";

import DeleteAlert from "../../shared/UI/MessageDialog/DeleteAlert";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { businessId } from "../../config";
import ButtonLoader from "../../shared/UI/Loader/ButtonLoader";
import {
  setDeleteCart,
  setUpdateCart,
} from "../../redux/features/cart/cartSlice";
import { ReactComponent as CartIcon } from "../../assets/cartIcon.svg";
import { getTotal, subTotal } from "../../helper/utilis";
export default function Cart() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const addressType = useAppSelector((state) => state?.auth?.addressType);
  const cartDataRedux = useAppSelector((state) => state?.cart?.cartData);
  const location = useAppSelector((state) => state?.common?.location);
  const [getCart, result] = useLazyGetCartQuery();
  const [{ isLoading }] = useDeleteCartMutation();
  const [deleteCartIdRedux, setDeleteCartIdRedux] = useState();
  const [openDelete, setOpenDelete] = useState(false);

  // const changeToDeliveryTab = () => {
  //   dispatch(setAddressType({ type: "delivery" }));
  //   navigate("/deliverydetail");
  // };
  // const changeToPickUpTab = () => {
  //   dispatch(setAddressType({ type: "pickUp" }));
  //   navigate("/pickdetail");
  // };

  useEffect(() => {
    if (user)
      getCart({
        userId: user?.userId ?? 0,
        businessId: businessId,
        locationId: user?.pickupAddress ?? location?.locationId,
      });
    // eslint-disable-next-line
  }, [user]);

  const enableDeleteModal = (item) => {
    setDeleteCartIdRedux(item?.cartItemId);
    setOpenDelete(true);
  };

  const onDeletSubmit = () => {
    dispatch(setDeleteCart({ cartItemId: deleteCartIdRedux }));
    setOpenDelete(false);
  };
  const IncrementQuantity = (item) => {
    dispatch(
      setUpdateCart({ cartItemId: item?.cartItemId, type: "increment" })
    );
  };

  const DecrementQuantity = (item, index) => {
    if (item?.quantity > 1) {
      dispatch(
        setUpdateCart({ cartItemId: item?.cartItemId, type: "decrement" })
      );
    }
  };

  let taxAmount = 0;
  if (cartDataRedux?.length > 0) {
    taxAmount = getTotal(cartDataRedux);
    taxAmount = ((location?.taxRate * taxAmount) / 100).toFixed(2);
  }

  let delivery;
  if (!(addressType === "pickUp") && cartDataRedux?.length > 0) {
    delivery = location?.deliveryCharges?.toFixed(2) || 0.0;
  } else {
    delivery = 0.0;
  }
  return (
    <>
      <div
        className="bg-white py-5 mb-3"
        style={{
          border: "1px solid #EAEAEA",
          borderRadius: "12px",
          minHeight: "26.5rem",
        }}
      >
        <h5 className="black fw600 px-3">Your Cart</h5>
        {/* ///// */}
        <div className="col-11 col-sm-11 col-md-11 col-lg-11  p-1 my-2">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "75px",
              alignItems: "center",
              border: "2px solid #e6e6e6",
              width: "100%",
              marginLeft: "0.5rem",
              height: "3.3rem",
              padding: "0 0.5rem",
              whiteSpace: "nowrap",
            }}
          >
            <div className="ms-lg-2 ms-md-2 ms-2">
              {localStorage.getItem("selectedGroupName") ? (
                <>
                  <People />
                  <span className="ms-3">
                    {localStorage.getItem("selectedGroupName")}
                  </span>
                </>
              ) : (
                <>
                  <img src={selfOrder} alt="" />
                  <span className="ms-3">Pick Up</span>
                </>
              )}
            </div>
            {/* <div className="me-2">
              <img src={DownwardArrow} alt="" />
            </div> */}
          </div>
        </div>
        {/* ///////////////////////////// */}
        {/* <div
          className={
            styles["pickupDeliveryDiv"] +
            " d-flex justify-content-between align-items-center px-3 my-3 col-lg-10 col-md-6 col-9 mx-auto"
          }
        >
          <div
            style={{
              background: addressType === "pickUp" ? "white" : null,
              width: "50%",
              borderRadius: "75px",
              padding: "0.5rem 1.5rem",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              !checkout && changeToPickUpTab();
            }}
          >
            <span className="fw-bold">Pickup</span>
          </div>
          <div
            style={{
              background: addressType === "delivery" ? "white" : null,
              width: "50%",
              textAlign: "center",
              borderRadius: "75px",
              padding: "0.5rem 1.5rem",
              cursor: "pointer",
            }}
            onClick={() => {
              !checkout && changeToDeliveryTab();
            }}
          >
            <span className="fw-bold">Delivery</span>
          </div>
        </div> */}
        {result?.isLoading ? (
          <div className="text-center d-flex justify-content-center">
            <ButtonLoader />
          </div>
        ) : (
          <>
            <div
              style={{
                maxHeight: "22rem",
                overflow: cartDataRedux?.length > 0 ? "auto" : "",
              }}
              className="px-3"
            >
              {cartDataRedux?.length === 0 ? (
                <hr className="mx-2 mt-4" />
              ) : null}
              {cartDataRedux?.length > 0 ? (
                cartDataRedux?.map((item, index) => {
                  return (
                    <>
                      <hr className="mx-2" key={item?.itemId} />
                      <div
                        key={index}
                        className="d-flex align-items-center my-2"
                      >
                        <div>
                          <img
                            src={item?.imageURL}
                            alt=""
                            style={{
                              width: "96px",
                              height: "96px",
                              objectFit: "cover",
                              borderRadius: "12px",
                            }}
                          />
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-10 col-sm-9 col-8 ms-3 pe-1">
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="black fw400">
                              {item?.name ?? "-"}
                            </span>
                            <span className="black fw400">
                              ${subTotal(cartDataRedux[index]) ?? 0.0}
                            </span>
                          </div>
                          <div>
                            <small className="lightBlack elipses-2">
                              {item?.description ?? "-"}
                            </small>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-2">
                            <span
                              className="red cursor"
                              onClick={() => {
                                enableDeleteModal(item);
                              }}
                            >
                              Delete Item
                            </span>

                            <span className="black">
                              <MinusIcon
                                width={20}
                                className="cursor me-2"
                                onClick={() => {
                                  DecrementQuantity(item, index);
                                }}
                              />
                              {item?.quantity}
                              <PlusIcon
                                width={20}
                                className="cursor ms-2"
                                onClick={() => {
                                  IncrementQuantity(item, index);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <h6
                  className="fw400 black text-center"
                  style={{ margin: "7.5rem 0rem" }}
                >
                  <div className="mb-3">
                    <CartIcon />
                  </div>

                  <small style={{ color: "#BFBFBF" }}>
                    Your cart is empty
                    <br /> Add items to get started{" "}
                  </small>
                </h6>
              )}
            </div>
          </>
        )}
      </div>
      {cartDataRedux?.length > 0 ? (
        <div
          className="bg-white p-3"
          style={{ border: "1px solid #EAEAEA", borderRadius: "12px" }}
        >
          <div className="d-flex justify-content-between align-items-center my-2">
            <span className="lightBlack fw-bold">Sub Total</span>
            <span className="lightBlack fw-bold">
              $ {getTotal(cartDataRedux) ?? 0}
            </span>
          </div>
          {addressType === "delivery" ? (
            <div className="d-flex justify-content-between align-items-center my-2">
              <span className="lightBlack fw-bold">Delivery</span>
              <span className="lightBlack fw-bold">$ {delivery ?? 0}</span>
            </div>
          ) : null}
          <div className="d-flex justify-content-between align-items-center my-2">
            <span className="lightBlack fw-bold">Tax {location?.taxRate}%</span>
            <span className="lightBlack fw-bold">${taxAmount ?? 0}</span>
          </div>
          <hr className="mx-2" />
          <div className="d-flex justify-content-between align-items-center my-2">
            <span className="lightBlack fw-bold">Total (incl. GST)</span>
            <span className="lightBlack fw-bold">
              ${" "}
              {(+getTotal(cartDataRedux) + +delivery + +taxAmount)?.toFixed(2)}
            </span>
          </div>
        </div>
      ) : null}
      <DeleteAlert
        isOpen={openDelete}
        isLoading={isLoading}
        onClose={() => setOpenDelete(false)}
        heading={"Delete Item?"}
        cancelText={"Proceed"}
        message={"Are you sure want to Delete this Item?"}
        onOk={onDeletSubmit}
      />
    </>
  );
}
