import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../container/Home/Home";
import DeliveryDetail from "../container/DeliveryDetails/DeliveryDetails";
import PickupDetails from "../container/PickupDetail/PickupDetails";
import Promotions from "../container/Promotions/Promotions";

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Promotions />} />
      <Route path="/menu" element={<Home />} />
      <Route path="/deliverydetail/*" element={<DeliveryDetail />} />
      <Route path="/pickdetail/*" element={<PickupDetails />} />
      <Route path="login/*" element={<Home />} />
      <Route path="register/*" element={<Home />} />
      <Route path="forgetpassword/*" element={<Home />} />
      <Route path="setpassword/*" element={<Home />} />
      <Route path="home/*" element={<Home />} />
      <Route path="passwordchanged/*" element={<Home />} />
      <Route path="checkemail/*" element={<Home />} />
      <Route path="otp/*" element={<Home />} />
      <Route path="/" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
