import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Cross from "../../assets/Cancel2.png";
import trashIcon from "../../assets/trash.png";
import GroupOrderButton from "../../components/GroupOrder/GroupOrderButton";
import ViewDetailsModal from "./ViewDetailsModal";
import CreateNewGroup from "./CreateNewGroup";
import { businessId } from "../../config";
import { useAppSelector } from "../../redux/hooks";
import { useGetGroupsByLocationQuery } from "../../redux/features/groups/groupsApiSlice";
import { useDeleteGroupMutation } from "../../redux/features/groups/groupsApiSlice";
import { useGetLocationsQuery } from "../../redux/features/common/commonApiSlice";
import Loader from "../../shared/UI/Loader/Loader";
import { useNavigate } from "react-router";

export default function SelectGroupModal({
  selectGroupModal,
  setSelectGroupModal,
  setGroupName,
}) {
  useGetLocationsQuery({ businessId });
  const d = new Date();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState(null); // State to store the selected group
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [createNewGroupModal, setCreateNewGroupModal] = useState(false);
  const [groupDetailsState, setGroupDetailsState] = useState(); //state to store the group to view its details
  const [
    deleteGroup,
    {
      data: DeleteData,
      isLoading: DeleteIsLoading,
      isError: DeleteIsError,
      error: DeleteError,
    },
  ] = useDeleteGroupMutation();

  const deleteGroupHandler = (e) => {
    // setSelectedGroup(e);
    console.log("deleteGroupHandler", e); //13000
    // Call the deleteGroup mutation
    deleteGroup({
      businessId: 8983, //businessId,
      userId: user?.userId ?? 0, //,, 12387
      groupId: e?.groupId,
    });
  };

  const selectGroupHandler = () => {
    if(selectedGroup?.name){
      setGroupName(selectedGroup.name);
    }
    // selectedGroup
    setSelectGroupModal(false);
  };
  // Call the useGetGroupsByLocationQuery hook
  const { data, error, isLoading } = useGetGroupsByLocationQuery({
    businessId: 8983, //businessId,
    userId: user?.userId ?? 0, //12387, //user?.userId ?? 0,, 13000
    locationId: 11072,
    dateTime: "2023-05-30 06:20:17",
  });

  useEffect(() => {
    if (isLoading) {
      console.log("res osloadinggggggg", isLoading);
    } else if (error) {
      console.log("res errorrrrrrrrrr", error);
    } else if (data) {
      console.log("res data", data);
    }
  }, [data, error, isLoading]);

  // useEffect(() => {
  //   if (DeleteIsLoading) {
  //     console.log("deleteeeeeeeeeeeeeeed osloadinggggggg", DeleteIsLoading);
  //   } else if (DeleteError) {
  //     console.log("deleteeeeeeeeeeeeeeed errorrrrrrrrrr", DeleteError);
  //   } else if (DeleteData) {
  //     console.log("deleteeeeeeeeeeeeeeed DeleteData", DeleteData);
  //   }
  // }, [DeleteData, DeleteError, DeleteIsLoading]);
  const handleRadioChange = (event, group) => {
    console.log(group, "group");
    setSelectedGroup(group);
    localStorage.setItem("selectedGroupName", group.name);
    localStorage.setItem("selectedGroupId", group.groupId);
  };

  const disableModal = () => {
    setSelectGroupModal(false);
  };

  const viewDetailsHandler = (e) => {
    console.log("setGroupDetailsState", e.groupId);
    setSelectGroupModal(false);
    setViewDetailsModal(!viewDetailsModal);
    setGroupDetailsState(e);
  };

  return (
    <>
      <MDBModal
        show={selectGroupModal}
        tabIndex="-1"
        staticBackdrop
        appendToBody
      >
        <MDBModalDialog size="fullscreen-sm-down" centered>
          <MDBModalContent style={{ overflowY: "auto" }}>
            <MDBModalBody className="ps-0 pt-0 pe-0 pb-4">
              <div style={{ position: "relative" }}>
                <img
                  src={Cross}
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    marginTop: "6px",
                  }}
                  className={styles[`crossIcon`]}
                  onClick={() => {
                    disableModal();
                  }}
                />
              </div>

              {isLoading ? (
                <Loader />
              ) : (
                <div className="col-lg-12 col-md-12 col-12 mx-lg-auto  mt-4 ">
                  <div
                    className="d-flex justify-content-between"
                    style={{ paddingRight: "2rem", paddingLeft: "2rem" }}
                  >
                    <div className="mt-4">
                      <div
                        style={{
                          color: "#606060",
                          fontWeight: "600",
                          fontSize: "17px",
                        }}
                      >
                        Select Group
                      </div>
                      <div
                        className="mt-2 mb-4"
                        style={{ color: "#606060", fontSize: "13px" }}
                      >
                        Invite friends and guests to join and add their favorite
                        items to a shared cart.
                      </div>

                      {/*  */}
                      {DeleteIsLoading && <Loader />}
                      {/* // Radio buttons*/}
                      {data?.map((group) => (
                        <div key={group?.name}>
                          <div className="mt-4 row align-items-center">
                            <div className="col-8">
                              <div
                                className="custom-control custom-radio"
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                }}
                              >
                                <input
                                  type="radio"
                                  //   className={"custom-radio-style"}
                                  style={{
                                    borderRadius: "50%",
                                    border: "2px solid #606060",
                                    backgroundColor: "#606060",
                                    width: "16px",
                                    height: "16px",
                                    marginTop: "2px",
                                    cursor: "pointer",
                                  }}
                                  id={`radio_${group?.name}`}
                                  checked={
                                    selectedGroup?.groupId === group?.groupId
                                  }
                                  onChange={(e) => handleRadioChange(e, group)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`radio_${group.name}`}
                                >
                                  <div
                                    style={{
                                      marginLeft: "0.7rem",
                                      color: "#606060",
                                    }}
                                    className={styles[`orderHeading`] + " mb-1"}
                                  >
                                    {group.name}
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div
                              className="col-3"
                              style={{
                                color: "#DB7712",
                                textAlign: "right",
                                paddingRight: "0px",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() => viewDetailsHandler(group)}
                            >
                              <div>View Details</div>
                            </div>

                            <div
                              key={group?.name}
                              className="col-1"
                              style={{
                                marginBottom: "5px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={trashIcon}
                                style={{
                                  width: "1.1rem",
                                  height: "1.1rem",
                                }}
                                onClick={() => deleteGroupHandler(group)}
                              />
                            </div>
                          </div>

                          <div
                            className={styles[`orderText`]}
                            style={{ marginLeft: "1.7rem" }}
                          >
                            {group.groupMembers
                              .map((item, index) => (
                                <span key={index}>
                                  {item.userBusiness.user.firstName}
                                  {index < group.groupMembers.length - 1 &&
                                    ", "}
                                </span>
                              ))
                              ?.slice(0, 4)}
                            {group.groupMembers.length > 4 && (
                              <span> and 4 others</span>
                            )}
                            {/* {group.groupMembers
                            .map((i) => i.split(" ")[0])
                            ?.slice(0, 4)
                            .join(", ")}{" "}
                          and {group.count - 4} others */}
                          </div>
                        </div>
                      ))}

                      {/* // button */}
                      <div className="mt-5 mb-3">
                        <GroupOrderButton
                          backgroundColor={"#F54222"}
                          color={"#fff"}
                          OnClickHandler={selectGroupHandler}
                          text={"Select Group"}
                        />
                      </div>
                      <div className=" mb-4">
                        <GroupOrderButton
                          backgroundColor={"#FCC6BD"}
                          color={"#F54222"}
                          OnClickHandler={() => {
                            setSelectGroupModal(false);
                            setCreateNewGroupModal(true);
                          }}
                          text={"Create New Group"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <ViewDetailsModal
        viewDetailsModal={viewDetailsModal}
        setViewDetailsModal={setViewDetailsModal}
        setSelectGroupModal={setSelectGroupModal}
        selectedGroup={groupDetailsState}
      />

      <CreateNewGroup
        createNewGroupModal={createNewGroupModal}
        setCreateNewGroupModal={setCreateNewGroupModal}
        setSelectGroupModal={setSelectGroupModal}
      />
    </>
  );
}
