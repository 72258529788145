import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { ReactComponent as Cross } from "../../../assets/authcross.svg";
import { useForm } from "react-hook-form";
import AddressModalForm from "./AddressModalForm";
import { addressResolver } from "../../../validators/accountResolver";
import {
  useLazyGetCityListQuery,
  useLazyGetCountryListQuery,
  useLazyGetRegionListQuery,
} from "../../../redux/features/account/cityApiSlice";
import {
  useAddAddressMutation,
  useLazyGetAddressesQuery,
} from "../../../redux/features/account/addressesApiSlice";
import { useAppSelector } from "../../../redux/hooks";
const defaultValues = {
  country: "",
  street: "",
  additionalInfo: "",
  state: "",
  city: "",
  zipCode: "",
  mblnumber: 0,
  isDefault: false,
};
export default function AddAddress({ open, setOpen, setGuestAddress }) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: addressResolver,
  });
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const token = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [getCountries, result] = useLazyGetCountryListQuery();
  const [getRegions, resultRegionsList] = useLazyGetRegionListQuery();
  const [addAddress, { isLoading }] = useAddAddressMutation();
  const [getAddresses, addressResult] = useLazyGetAddressesQuery();
  const [getCities, resultCitiesList] = useLazyGetCityListQuery();
  const toggleShow = () => setOpen(!open);
  const [addressList, setAddressList] = useState([]);
  const [countries, setCountries] = useState();
  const [regions, setRegions] = useState();
  const [cities, setCities] = useState();
  useEffect(() => {
    getAddresses({
      userId: user?.userId ?? 0,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (addressResult?.data) {
      setAddressList(addressResult?.data);
    }
    // eslint-disable-next-line
  }, [result?.data]);
  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, [result?.data]);
  useEffect(() => {
    if (result?.data) {
      setCountries(result?.data);
    }
  }, [result?.data]);
  useEffect(() => {
    if (countries?.length > 0 && !!watch("country")) {
      getRegions({ cId: watch("country") });
    }
    // eslint-disable-next-line
  }, [countries, watch("country")]);
  useEffect(() => {
    if (countries?.length > 0 && resultRegionsList?.data) {
      setRegions(resultRegionsList?.data);
    }
  }, [resultRegionsList?.data, countries]);
  useEffect(() => {
    if (countries?.length > 0 && regions?.length > 0) {
      getCities({ provinceCode: watch("state") });
    }
    // eslint-disable-next-line
  }, [countries, watch("state")]);
  useEffect(() => {
    if (countries?.length > 0 && resultCitiesList?.data) {
      setCities(resultCitiesList?.data);
    }
  }, [resultCitiesList?.data, countries, regions]);

  const onSubmit = async (values) => {
    if (!token) {
      const address = {
        address: values?.street ?? "",
        stateCode: values?.state ?? "",
        userId: user?.userId ?? 0,
        setDefault: values?.isDefault,
        phoneNo: "+" + values?.mblnumber,
        postalCode: values?.zipCode,
        cityId: values?.city ?? "",
        countryId: values?.country ?? "",
        additionalInformation: values?.additionalInfo ?? "",
      };
      setGuestAddress(address);
      setOpen(false);
    } else {
      const result = await addAddress({
        address: values?.street ?? "",
        stateCode: values?.state ?? "",
        userId: user?.userId ?? 0,
        setDefault: values?.isDefault,
        phoneNo: "+" + values?.mblnumber,
        postalCode: values?.zipCode,
        cityId: values?.city ?? "",
        countryId: values?.country ?? "",
        additionalInformation: values?.additionalInfo ?? "",
      });
      if ("data" in result && result.data.status === "SUCCESS") {
        setOpen(false);
      }
    }
  };

  return (
    <>
      <MDBModal show={open} setShow={setOpen} tabIndex="-1" staticBackdrop>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody>
              <div className="pb-3">
                <Cross className="cursor float-end" onClick={toggleShow} />
              </div>
              <div className="mt-3">
                <h5 className="black text-center fw-bold">Add New Address</h5>
                <p className="text-center">
                  This address will be added to your saved addresses list
                </p>
                <div className="mx-md-3 mt-5">
                  <AddressModalForm
                    countriesList={countries ?? []}
                    regionsList={regions ?? []}
                    citiesList={cities ?? []}
                    isLoading={isLoading}
                    control={control}
                    addresslist={addressList}
                    type="Add"
                    errors={errors}
                    onSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
