import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  successMessage: "",
  errorMessage: "",
  location: null,
  isRestaurantOpen: true,
  scheduledOrderTime: "Now",
  currentLocation: null,
  categories: [],
  favouriteItems: [],
};
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setIsRestaurantOpen: (state, action) => {
      state.isRestaurantOpen = action.payload;
    },
    setScheduledOrderTime: (state, action) => {
      state.scheduledOrderTime = action.payload;
    },
    setCurrentLocation: (state, action) => {
      state.currentLocation = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setFavouriteItems: (state, action) => {
      state.favouriteItems = action.payload;
    },
  },
});

export const {
  setErrorMessage,
  setSuccessMessage,
  setLocation,
  setIsRestaurantOpen,
  setScheduledOrderTime,
  setCurrentLocation,
  setCategories,
  setFavouriteItems,
} = commonSlice.actions;

export default commonSlice.reducer;
