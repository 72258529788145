import { apiSlice } from "../../api/apiSlice";

export const cityApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Countries"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCountryList: builder.query({
        query: () => ({
          url: "https://d13di7fagpzobw.cloudfront.net/Country/getList?" + new Date(),
          // url: "https://mywyzer.com:8443/Country/getList?" + new Date(),
          method: "GET",
        }),
        providesTags: ["Countries"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
      getRegionList: builder.query({
        query: (data) => ({
          url:
            "https://d13di7fagpzobw.cloudfront.net/Province/getListByCountry?cId=" +
            // "https://mywyzer.com:8443/Province/getListByCountry?cId=" +
            data?.cId +
            "&" +
            new Date(),
          method: "GET",
        }),
        providesTags: ["Countries"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
      getCityList: builder.query({
        query: (data) => ({
          url:
            "https://d13di7fagpzobw.cloudfront.net/City/getListByStateCode?stateCode=" +
            // "https://mywyzer.com:8443/City/getListByStateCode?stateCode=" +
            data?.provinceCode +
            "&" +
            new Date(),
          method: "GET",
        }),
        providesTags: ["Countries"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
    }),
  });

export const {
  useLazyGetCountryListQuery,
  useLazyGetRegionListQuery,
  useLazyGetCityListQuery,
} = cityApiSlice;
