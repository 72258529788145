import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { globalVariables } from "../../helper/globalVariables";
import { logOut } from "../features/auth/authSlice";
import { setErrorMessage } from "../features/common/commonSlice";

const baseQuery = fetchBaseQuery({
  baseUrl,
  mode: "cors",
  credentials: "same-origin",

  prepareHeaders: (headers, { getState }) => {
    const token = (getState()).auth.token;
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let data = result.data;
  if (data?.status === "ERROR") {
    api.dispatch(setErrorMessage(data.message));
    if (data?.errorCode === 401) {
      api.dispatch(logOut);
    }
  }
  if (!data) {
    api.dispatch(setErrorMessage(globalVariables.ErrorMessage));
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
