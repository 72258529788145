import { apiSlice } from "../../api/apiSlice";
import { store } from "../../store";
import { setSuccessMessage } from "../common/commonSlice";

export const addressesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Addresses"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAddresses: builder.query({
        query: (data) => ({
          url:
            "/Addresses/getList?" + new URLSearchParams({ ...data }).toString(),
          // "/Addresses/getList?userId=12429",
          method: "GET",
        }),
        providesTags: ["Addresses"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
      addAddress: builder.mutation({
        query: (credentials) => ({
          url:
            "/Addresses/add?" +
            new URLSearchParams({ ...credentials }).toString(),
          method: "POST",
        }),
        invalidatesTags: ["Addresses"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result?.message));
          return result;
        },
      }),
      editAddress: builder.mutation({
        query: (credentials) => ({
          url:
            "/Addresses/modify?" +
            new URLSearchParams({ ...credentials }).toString(),
          method: "POST",
        }),
        invalidatesTags: ["Addresses"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result?.message));
          return result;
        },
      }),
      getAddressById: builder.query({
        query: (credentials) => ({
          url:
            "/Addresses/get?" +
            new URLSearchParams({ ...credentials }).toString(),
          method: "GET",
        }),
        invalidatesTags: ["Addresses"],
        transformResponse: (result) => {
          return result?.payLoad;
        },
      }),
      deleteAddress: builder.mutation({
        query: (credentials) => ({
          url:
            "/Addresses/delete?" +
            new URLSearchParams({ ...credentials }).toString(),
          method: "POST",
        }),
        invalidatesTags: ["Addresses"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result?.message));
          return result;
        },
      }),
    }),
  });

export const {
  useLazyGetAddressesQuery,
  useAddAddressMutation,
  useLazyGetAddressByIdQuery,
  useEditAddressMutation,
  useDeleteAddressMutation,
} = addressesApiSlice;
