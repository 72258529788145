import { MDBBtn, MDBDatepicker, MDBInput } from "mdb-react-ui-kit";
import styles from "../../../container/Account/styles.module.css";
import UploadImage from "./../../../assets/uploadImageIcon.png";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { accountResolver } from "../../../validators/accountResolver";
import FormValidationError from "../../../shared/UI/FormValidationError";
import {
  useLazyGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../redux/features/account/profileApiSlice";
import PhoneInput from "react-phone-input-2";
import { format } from "date-fns";
import Loader from "../../../shared/UI/Loader/Loader";
import ButtonLoader from "../../../shared/UI/Loader/ButtonLoader";
import { useAppSelector } from "../../../redux/hooks";
import { businessId } from "../../../config";

export default function ProfileSettings({ account, setOpen }) {
  const defaultValues = {
    firstname: "",
    lastname: "",
    emailAddress: "",
    mobile: "",
    dateOfBirth: "",
  };
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues, resolver: accountResolver });

  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const [getProfile, result] = useLazyGetProfileQuery();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };
  const onSubmit = async (values) => {
    let formData = new FormData();
    formData.append("image", selectedFile);
    const res = await updateProfile({
      firstName: values?.firstname,
      lastName: values?.lastname,
      userId: user?.userId ?? 0,
      mobileNumber: values?.mobile,
      email: values?.emailAddress,
      dob: values?.dateOfBirth,
      image: selectedFile !== undefined ? formData : image,
      businessId: businessId,
    });
    if ("data" in res && res.data.status === "SUCCESS") {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (user)
      getProfile({
        userId: user?.userId ?? 0,
        businessId: businessId,
      });
    // eslint-disable-next-line
  }, [user]);
  useEffect(() => {
    if (result?.data) {
      setValue("firstname", result?.data?.firstName);
      setValue("lastname", result?.data?.lastName);
      setValue("emailAddress", result?.data?.email);
      setValue("mobile", result?.data?.mobileNumber);
      setValue(
        "dateOfBirth",
        result?.data?.dateOfBirth
          ? format(result?.data?.dateOfBirth, "yyyy-MM-dd")
          : ""
      );
      setImage(result?.data?.imageURL);
    }
    // eslint-disable-next-line
  }, [result?.data]);
  return (
    <>
      <div>
        {result?.isLoading ? (
          <div style={{ margin: "7rem 0rem" }}>
            <Loader />
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center col-10  ">
              <div style={{minWidth:'80px'}}>
                {selectedFile === undefined ? (
                  <img
                    src={image ? image : UploadImage}
                    alt="nothing"
                    className={styles["imgg"] + " me-3"}
                  />
                ) : (
                  <img
                    src={preview}
                    alt=""
                    className={styles["imgg"] + " me-3"}
                  />
                )}
              </div>
              <div className="d-flex flex-column col-10 mx-2">
                <p className={styles["imagelabel"] + "  mb-0 ms-1 "}>
                  Image dimensions should be 90x90 or more.
                </p>
                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="files"
                    style={{
                      backgroundColor: "white",
                      color: "#003ECB",
                      fontSize: "1rem",
                      boxShadow: "none",
                      padding: "0.3rem",
                      textTransform: "capitalize",
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  >
                    <u
                      className={`mb-md-0 mb-2 py-md-1 py-2 px-4 ${styles.btnUpload}`}
                    >
                      Upload&nbsp;Image
                    </u>
                  </label>
                  <br />
                  <input
                    type="file"
                    className="col-10 d-none"
                    id="files"
                    accept="image/*"
                    onChange={onSelectFile}
                    style={{ color: "white" }}
                  />
                </div>
              </div>
            </div>

            <div className=" mt-md-3">
              <div className={` py-2     `}>
                <Controller
                  control={control}
                  name="firstname"
                  render={({ field: { onChange, value } }) => (
                    <MDBInput
                      className={`${errors.firstname && "is-invalid"}`}
                      label="First Name"
                      size="lg"
                      type="text"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <FormValidationError errorMessage={errors.firstname?.message} />{" "}
              </div>
              <div className={` py-2    `}>
                <Controller
                  control={control}
                  name="lastname"
                  render={({ field: { onChange, value } }) => (
                    <MDBInput
                      className={`${errors.lastname && "is-invalid"}`}
                      label="Last Name"
                      size="lg"
                      type="text"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <FormValidationError errorMessage={errors.lastname?.message} />{" "}
              </div>
              <div className={` py-2   `}>
                <Controller
                  control={control}
                  name="emailAddress"
                  render={({ field: { onChange, value } }) => (
                    <MDBInput
                      className={`${errors.emailAddress && "is-invalid"}`}
                      label="Email Address"
                      size="lg"
                      disabled={result?.data?.email}
                      type="email"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <FormValidationError
                  errorMessage={errors.emailAddress?.message}
                />{" "}
              </div>
              <div className={`py-2     `}>
                <Controller
                  control={control}
                  name="mobile"
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      label="Mobile Number"
                      country={"us"}
                      inputStyle={{ width: "100%", height: "48px" }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <FormValidationError errorMessage={errors.mobile?.message} />{" "}
              </div>
              <div className={`py-2   `}>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ field: { onChange, value } }) => (
                    <MDBDatepicker
                      className={`${errors.dateOfBirth && "is-invalid"}`}
                      label="Date of Birth"
                      format="yyyy-mm-dd"
                      disableFuture
                      size="lg"
                      inline
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <FormValidationError
                  errorMessage={errors.dateOfBirth?.message}
                />{" "}
              </div>
            </div>
            <div>
              {account ? (
                <MDBBtn
                  className={`py-md-2 col-4 col-md-3 my-3 bgRed shadow-none`}
                  size="lg"
                  onClick={handleSubmit(onSubmit)}
                >
                  {isLoading ? <ButtonLoader /> : "Save"}
                </MDBBtn>
              ) : (
                <>
                  <MDBBtn
                    className={`my-3 col-12 bgRed shadow-none`}
                    onClick={handleSubmit(onSubmit)}
                    size="lg"
                  >
                    {isLoading ? <ButtonLoader /> : "Save & Continue"}
                  </MDBBtn>
                  <h6
                    className="mb-3 cursor text-center"
                    onClick={() => setOpen(false)}
                  >
                    Skip
                  </h6>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
