import React from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { ReactComponent as Cross } from "../../../assets/authcross.svg";
import ProfileSettings from "../../../components/Account/Profile/ProfileSettings";

export default function ProfileModal({ open, setOpen }) {
  const toggleShow = () => setOpen(!open);
  return (
    <>
      <MDBModal show={open} setShow={setOpen} tabIndex="-1" staticBackdrop>
        <MDBModalDialog centered>
          <MDBModalContent>
            <div className="p-3">
              <Cross className="cursor float-end" onClick={toggleShow} />
            </div>
            <div className="text-center">
              <h5 className="text-center fw-bold black">Setup Your Profile</h5>
              <h6 className="text-center fw600">
                Please complete the necessary details for the better experience!
              </h6>
            </div>
            <MDBModalBody className="col-11 mx-auto">
              <ProfileSettings setOpen={setOpen} />
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
