import { MDBSpinner } from "mdb-react-ui-kit";
import { ReactComponent as Google } from "../../../assets/google.svg";
import styles from "../styles.module.css";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../../firebase";
import { useGoogleLoginMutation } from "../../../redux/features/auth/authApiSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { RecaptchaVerifier, signInWithPhoneNumber } from "@firebase/auth";
import {
  setAddressType,
  setCredentials,
  setGreetingModal,
  setLoginState,
} from "../../../redux/features/auth/authSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { store } from "../../../redux/store";
import { setErrorMessage } from "../../../redux/features/common/commonSlice";
import { businessId } from "../../../config";
let counter = 0;
const renderCounter = (base = 10) => {
  const value = ++counter;
  return value.toString(base);
};
const renderTime = (base = 10) => {
  const value = Date.now();
  return value.toString(base);
};
const renderRandom = (base = 10) => {
  const value = Math.floor(1e16 + 9e16 * Math.random());
  return value.toString(base);
};
const generateId = (base = 10, separator = "-") => {
  return (
    renderCounter(base) +
    separator +
    renderTime(base) +
    separator +
    renderRandom(base)
  );
};
export default function Login({ setOpen }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLogin, { data: loginData, isLoading }] =
    useGoogleLoginMutation();
  useEffect(() => {}, [loginData]);
  const onSuccessLogin = async (result) => {
    const newdata = await googleLogin({
      name: result?.user?.displayName,
      email: result?.user?.email,
      imageURL: result?.user?.photoURL,
      token: result?.user?.accessToken,
      // mobileNumber: "0308",
      instanceId: generateId(10),
      businessId: businessId,
      dateTime: result?.user?.metadata?.creationTime,
    });
    if ("data" in newdata && newdata.data.status === "SUCCESS") {
      localStorage.setItem("user", newdata?.data?.jwt);
      dispatch(setCredentials(newdata?.data?.jwt));
      // setOpenProfileModal(true);
      // setOpen(false);
      dispatch(setLoginState(false));
      dispatch(setGreetingModal({ type: "enable" }));
      //dispatch(setAddressType({type:"pick"}))
    }
  };
  async function googlelogin(e) {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(auth, provider)
      .then((result) => {
        onSuccessLogin(result);
      })
      .catch((error) => {});
  }
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };
  const onSignInSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    generateRecaptcha();
    if (phoneNumber) {
      const phone = "+" + phoneNumber;
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          setLoading(false);
          window.confirmationResult = confirmationResult;
          // toggleModals();
          navigate("/otp", { state: { phoneNumber } });
          // navigate("/otp");
        })
        .catch((error) => {
          setLoading(false);
          store.dispatch(setErrorMessage("Error Sending OTP"));
        });
    } else {
      setLoading(false);
    }
    return;
  };
  return (
    <>
      <div className="mt-5 col-10 mx-auto">
        <div className="pb-4">
          <h5 className="text-center black fw600">Sign In / Sign Up</h5>
          {/* <small className="d-block mx-auto text-center fw-bold">
            Welcome Back!
          </small> */}
        </div>
        {isLoading ? (
          <MDBSpinner className="d-block mx-auto text-center" />
        ) : (
          <>
            <div className="col-12 mx-auto">
              <PhoneInput
                country={"us"}
                inputStyle={{ width: "100%", height: "2.75rem" }}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
              />
            </div>
            <div id="sign-in-button" />
            <div className="my-3">
              <button
                className={
                  styles["gradient"] +
                  " col-12 rounded border-0 white py-2 mt-3"
                }
                type="button"
                onClick={onSignInSubmit}
              >
                {loading || isLoading ? <MDBSpinner size="sm" /> : " Login"}
              </button>
            </div>
            <div className={styles["separator"]}>Or Login with</div>
            <div
              className="my-3 p-2 d-flex justify-content-center "
              style={{ border: "1px solid #BEBEBE", borderRadius: "8px" }}
              onClick={googlelogin}
              role="button"
            >
              <div className="mx-2">
                <Google className="cursor" width={20} />
              </div>
              <div className="mx-2 fw-bold" style={{ color: "#212121" }}>
                Continue with Google
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
