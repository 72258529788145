import React from "react";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import styles from "./styles.module.css";
import { ReactComponent as Info } from "../../assets/info.svg";
import { useSelector } from "react-redux";
export default function TabCardContent({ item }) {
  const cartData = useSelector((state) => state?.cart?.cartData);
  return (
    <>
      <MDBCard className={styles[`menu-item-card`]}>
        <MDBCardBody className="p-0">
          <div className="d-flex justify-content-between">
            <div className="w-75 pe-md-3">
              <div className="mt-1">
                <h6 className="elipses-1 fw500 black">{item?.name ?? "-"}</h6>
              </div>
              <div style={{ minHeight: "3rem" }} className="mb-1">
                <MDBCardText
                  className={styles["paragraph"] + " lightBlack fw400"}
                >
                  {item?.description}
                </MDBCardText>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-1">
              
                <h6 className="fw400 black mb-0">${item.itemSize[0].price}</h6>
                {cartData?.find(
                  (itemm) => itemm?.itemId === item?.shopItemId
                ) && (
                  <small className="fw400 d-flex align-items-center me-1" style={{ color: "#DB7712" }}>
                    <Info className="fw-bold me-1" />
                    Already in Cart
                  </small>
                )}{" "}
              </div>
            </div>

            <div style={{ alignSelf: "center" }} className="ms-lg-3 ms-md-3 ms-3">
              <img
                alt=""
                src={item?.imageURL}
                width={100}
                height={100}
                style={{ objectFit: "cover", borderRadius: "8px" }}
              />
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </>
  );
}
