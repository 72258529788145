import styles from "./styles.module.css";
export default function ItemCard({
  imageURL,
  title,
  description,
  quantity,
  size,
  price,
}) {
  return (
    <div className="col-12 col-md-12 col-lg-6 col-xl-6 px-0 my-2">
      <div
        className={
          styles["card"] + " d-flex p-2 overflow-auto align-items-center"
        }
      >
        <div className="me-2">
          <img
            src={imageURL}
            alt=""
            style={{
              backgroundSize: "cover",
              height: "96px",
              width: "96px",
              backgroundPosition: "center",
              borderRadius: "12px",
            }}
          />
        </div>
        <div className="d-flex justify-content-between flex-column w-100">
          <div className="">
            <div className="d-flex justify-content-between flex-nowrap">
              <h5 className="text-nowrap me-2 mb-0 fs-6 fw-bold">{title}</h5>
              <div className="d-md-flex d-none">
                <div className="mx-1 fw-bold text-nowrap fs-small">
                  Size: {size}
                </div>
                <div className="mx-1 fw-bold text-nowrap fs-small">
                  Quantity: {quantity}
                </div>
              </div> <div className="d-flex d-md-none">
              <div className="mx-1 fw-bold text-nowrap fs-small">
                Size: {size}
              </div>
              <div className="mx-1 fw-bold text-nowrap fs-small">
                Quantity: {quantity}
              </div>
            </div>
            </div>
           
            <p className="text-muted elipses-2 mb-0">{description}</p>
          </div>
          <div className="fw-bold">${price}</div>
        </div>
      </div>
    </div>
  );
}
