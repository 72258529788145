import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
const initialState = {
  token: localStorage.getItem("user"),
  user: localStorage.getItem("user")
    ? jwt_decode(localStorage.getItem("user"))
    : "",
  ordertype: 0,
  emptyCart: true,
  loginState: false,
  greetingModal: false,
  addressType: "pickUp",
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const token = action.payload;
      const user = jwt_decode(action.payload);
      state.token = { token, user };
    },
    setOrderType: (state, action) => {
      const ordertype = action.payload;
      state.ordertype = ordertype;
    },
    setEmpCart: (state, action) => {
      const emptycart = action.payload;
      state.emptyCart = emptycart;
    },
    setLoginState: (state, action) => {
      const loginState = action.payload;
      state.loginState = loginState;
    },
    setGreetingModal: (state, action) => {
      if (action.payload?.type === "enable") {
        state.greetingModal = true;
      } else {
        state.greetingModal = false;
      }
    },
    setAddressType: (state, action) => {
      if (action.payload?.type === "pickUp") {
        state.addressType = "pickUp";
      } else {
        state.addressType = "delivery";
      }
    },

    logOut: (state) => {
      state.token = "";
      state.user = "";
      state.ordertype = 0;
      localStorage.clear();
      state.greetingModal = false;
    },
  },
});

export const {
  setCredentials,
  setOrderType,
  logOut,
  setEmpCart,
  setLoginState,
  setGreetingModal,
  setAddressType,
} = authSlice.actions;

export default authSlice.reducer;
