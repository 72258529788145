import React from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalBody,
  MDBBtn,
  MDBInput,
  MDBTextArea,
  MDBModalContent,
} from "mdb-react-ui-kit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Controller, useForm } from "react-hook-form";
import { giftCardResolver } from "../../validators/giftResolver";
import FormValidationError from "../../shared/UI/FormValidationError";
import { ReactComponent as Cross } from "../../assets/Cancel.svg";

const BuySendModal = ({
  showModal,
  handleCloseModal,
  selectedCard,
  setShowModal,
}) => {
  const defaultValues = {
    emailAddress: "",
    mobile: "",
    message: "",
  };

  const {
    control,
    formState: { errors },
  } = useForm({ defaultValues, resolver: giftCardResolver });
  return (
    <>
      <MDBModal show={showModal} setShow={setShowModal}>
        <MDBModalDialog className="rounded" centered>
          <MDBModalContent>
            <>
              <div style={{ position: "relative" }}>
                <Cross
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
                <img
                  style={{
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                  height={250}
                  src={selectedCard && selectedCard.src}
                  alt={selectedCard && selectedCard.title}
                />{" "}
              </div>
            </>
            <MDBModalBody className="px-md-5 px-3 p-5">
              <>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0 fw600 lightBlack">
                    {selectedCard && selectedCard.title}
                  </h6>
                  <h6 className="ms-3 mb-0 lightBlack fw600">
                    {selectedCard && selectedCard.price}
                  </h6>
                </div>
                <div className=" mt-3">
                  <div className={` py-2  mb-2   `}>
                    <Controller
                      control={control}
                      name="emailAddress"
                      render={({ field: { onChange, value } }) => (
                        <MDBInput
                          className={`${errors.emailAddress && "is-invalid"}`}
                          label="Email Address"
                          size="lg"
                          type="email"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <FormValidationError
                      errorMessage={errors.emailAddress?.message}
                    />{" "}
                  </div>
                  <div className={`py-2  mb-2   `}>
                    <Controller
                      control={control}
                      name="mobile"
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          country={"us"}
                          inputStyle={{ width: "100%", height: "2.75rem" }}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <FormValidationError
                      errorMessage={errors.mobile?.message}
                    />{" "}
                  </div>
                  <div className={` py-2  mb-2   `}>
                    <Controller
                      control={control}
                      name="message"
                      render={({ field: { onChange, value } }) => (
                        <MDBTextArea
                          className={`${errors.message && "is-invalid"}`}
                          label="Message"
                          style={{ resize: "none" }}
                          size="lg"
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <FormValidationError
                      errorMessage={errors.message?.message}
                    />{" "}
                  </div>
                </div>
                <MDBBtn
                  className="btn-block my-4 shadow-none bgRed"
                  onClick={() => {
                    handleCloseModal();
                  }}
                  size="lg"
                >
                  Buy & Send
                </MDBBtn>
              </>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default BuySendModal;
