import { MDBSpinner } from "mdb-react-ui-kit";
import styles from "../styles.module.css";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { usePhoneLoginMutation } from "../../../redux/features/auth/authApiSlice";
import { useAppDispatch } from "../../../redux/hooks";
import {
  setCredentials,
  setGreetingModal,
  setLoginState,
} from "../../../redux/features/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "../../../redux/store";
import { setErrorMessage } from "../../../redux/features/common/commonSlice";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase";

let counter = 0;

const renderCounter = (base = 10) => {
  const value = ++counter;
  return value.toString(base);
};

const renderTime = (base = 10) => {
  const value = Date.now();
  return value.toString(base);
};

const renderRandom = (base = 10) => {
  const value = Math.floor(1e16 + 9e16 * Math.random());
  return value.toString(base);
};

const generateId = (base = 10, separator = "-") => {
  return (
    renderCounter(base) +
    separator +
    renderTime(base) +
    separator +
    renderRandom(base)
  );
};
export default function Otp({ setOpen }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [phoneLogin, { isLoading }] = usePhoneLoginMutation();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const onSuccessLogin = async (result) => {
    const newdata = await phoneLogin({
      key: result?.user?.accessToken,
      mobileNumber: result?.user?.phoneNumber,
      instanceId: generateId(10),
      businessId: 8983,
      dateTime: result?.user?.metadata?.creationTime,
    });
    if ("data" in newdata && newdata.data.status === "Success") {
      setLoading(false);
      localStorage.setItem("user", newdata?.data?.jwt);
      dispatch(setCredentials(newdata?.data?.jwt));
      // setOpen(false);
      dispatch(setLoginState(false));
      dispatch(setGreetingModal({ type: "enable" }));
      //navigate("/home");
    }
  };
  const verifyOtp = () => {
    if (otp.length === 6) {
      setLoading(true);

      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          onSuccessLogin(result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          store.dispatch(setErrorMessage("Invalid Code"));
          // ...
        });
    } else {
      setLoading(false);
    }
  };
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };
  const onSignInSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    generateRecaptcha();
    if (state?.phoneNumber) {
      const phone = "+" + state?.phoneNumber;
      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          setLoading(false);
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    return;
  };

  return (
    <>
      <div className="mt-5 col-10 mx-auto">
        <div className="pb-4">
          <h5 className="text-center black fw600">Enter OTP</h5>
          <small className="d-block mx-auto text-center fw-bold">
            We have sent you an OTP Code to <br />{" "}
            {state?.phoneNumber ? "+" + state?.phoneNumber : ""}
          </small>
        </div>

        <div className="my-3 px-0 col-12 mx-auto">
          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            numInputs={6}
            inputType="number"
            inputStyle={styles["otp"]}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <small className="gray cursor" onClick={() => navigate("/login")}>
            Change Phone Number
          </small>
          <small>
            {!loading && (
              <span className={"red cursor"} dis onClick={onSignInSubmit}>
                {"Resend Code"}
              </span>
            )}
            {/* <span className="ms-2 gray">29s</span>{" "} */}
          </small>
        </div>
        <div id="sign-in-button" />
        <div className="my-3">
          <button
            className={
              styles["gradient"] + " col-12 rounded border-0 white py-2 mt-3"
            }
            type="button"
            onClick={verifyOtp}
          >
            {loading || isLoading ? <MDBSpinner size="sm" /> : " Submit"}
          </button>
        </div>
        <div className="text-center mb-4">
          <small className="gray cursor" onClick={() => navigate("/login")}>
            &larr; <span className="ms-1">Back to login</span>{" "}
          </small>
        </div>
      </div>
    </>
  );
}
