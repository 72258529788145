import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBProgress,
  MDBProgressBar,
} from "mdb-react-ui-kit";
import { ReactComponent as Cross } from "../../assets/Cancel.svg";
import { format, formatDistanceToNow } from "date-fns";
import {
  useAddPromotionMutation,
  useLazyGetUserPromotionsListQuery,
} from "../../redux/features/loyaltypoints/loyaltyPointsApiSlice";
import { useAppSelector } from "../../redux/hooks";
import { businessId } from "../../config";
import ButtonLoader from "../../shared/UI/Loader/ButtonLoader";
import { useEffect, useState } from "react";
import Loader from "../../shared/UI/Loader/Loader";

export default function PromotionModal({
  showModal,
  setShowModal,
  data,
  setData,
  id,
}) {
  const date = new Date();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [addPromotion, { isLoading }] = useAddPromotionMutation();
  const [getUserPromotions, userPromotionData] =
    useLazyGetUserPromotionsListQuery();
  const [promList, setPromList] = useState([]);
  const [userProms, setUserProms] = useState([]);
  const onSubmit = (promotionId) => {
    const res = addPromotion({
      dateTime: format(date, "yyyy-MM-dd H:mm:ss"),
      userId: user?.userId ?? 0,
      businessId: businessId,
      promotionId: promotionId,
    });
    if ("data" in res && res.data.status === "SUCCESS") {
      setShowModal(false);
      setData();
    }
  };
  useEffect(() => {
    if (userPromotionData?.data) {
      setPromList(
        userPromotionData?.data?.map((item) => item?.promotionDetail)
      );
      setUserProms(userPromotionData?.data);
    }
    // eslint-disable-next-line
  }, [userPromotionData?.data]);
  useEffect(() => {
    if (showModal === true && user) {
      getUserPromotions({
        businessId: businessId,
        userId: user?.userId ?? 0,
      });
    }
    // eslint-disable-next-line
  }, [showModal]);
  const CurrentPromotion = userProms?.find(
    (itemm) =>
      itemm?.promotionId ===
      promList?.flat()?.find((item) => item?.promotion?.promotionId === id)
        ?.promotion?.promotionId
  );
  return (
    <>
      <MDBModal show={showModal} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="md" centered>
          <MDBModalContent>
            <MDBModalBody className="ps-0 pt-0 pe-0 pb-4">
              <div style={{ position: "relative" }}>
                <Cross
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowModal(false);
                    setData();
                  }}
                />
                <img
                  src={data?.promotion?.imageURL}
                  alt=""
                  height={300}
                  style={{
                    borderRadius: " 8px 8px 0px 0px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="mt-5 col-10 mx-auto"
                key={data?.promotion?.promotionId}
              >
                <div className="d-flex justify-content-between mb-2">
                  <h5 className="fw-bold lightBlack">
                    {data?.promotion?.name}
                  </h5>
                  <h6>{data?.promotion?.extraPoint ?? 0} Extra Points</h6>
                </div>
                <h6 className="fw-bold lightBlack">How it works?</h6>
                <div>{data?.promotion?.description ?? "-"}</div>
                {data && (
                  <p>
                    {"Will expire on " +
                      format(data?.promotion?.endDate, "MMM dd, yyyy hh:mm a")}
                  </p>
                )}
                <div>
                  <h6 className="fw-bold lightBlack">Items</h6>

                  {data?.promotionItems?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          key={index}
                        >
                          <li className="lightBlack">
                            {item?.quantity}&nbsp;x&nbsp;
                            {item?.item?.name ?? "-"}
                          </li>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="my-3">
                  {userPromotionData?.isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {/* {promList?.length > 0 ? ( */}
                      {!promList
                        ?.flat()
                        ?.find(
                          (item) => item?.promotion?.promotionId === id
                        ) ? (
                        <button
                          className="bgRed col-12 border-0 rounded p-2 white mt-2"
                          type="button"
                          key={data?.promotion?.promotionId}
                          onClick={() => {
                            onSubmit(data?.promotion?.promotionId);
                          }}
                        >
                          {isLoading ? <ButtonLoader /> : "Start"}
                        </button>
                      ) : (
                        promList
                          ?.flat()
                          ?.find(
                            (item) => item?.promotion?.promotionId === id
                          ) && (
                          <>
                            <div className="d-flex justify-content-between align-items-center">
                              <h6>
                                {CurrentPromotion?.bought} down{" "}
                                {(CurrentPromotion?.total ?? 0) -
                                  (CurrentPromotion?.bought ?? 0)}{" "}
                                to go
                              </h6>
                              <h6>
                                {new Date(
                                  userProms?.find(
                                    (itemm) =>
                                      itemm?.promotionId ===
                                      promList
                                        ?.flat()
                                        ?.find(
                                          (item) =>
                                            item?.promotion?.promotionId === id
                                        )?.promotion?.promotionId
                                  )?.promotionDetail[0]?.promotion?.endDate
                                ) < Date?.now() ? (
                                  "Expired"
                                ) : (
                                  <>
                                    {" "}
                                    Expire in{" "}
                                    {formatDistanceToNow(
                                      new Date(
                                        userProms?.find(
                                          (itemm) =>
                                            itemm?.promotionId ===
                                            promList
                                              ?.flat()
                                              ?.find(
                                                (item) =>
                                                  item?.promotion
                                                    ?.promotionId === id
                                              )?.promotion?.promotionId
                                        )?.promotionDetail[0]?.promotion?.endDate
                                      ),
                                      {
                                        addSuffix: false,
                                      }
                                    )}
                                  </>
                                )}
                              </h6>
                            </div>
                            <MDBProgress height="5">
                              <MDBProgressBar
                                width={
                                  ((CurrentPromotion.bought ?? 0) /
                                    (CurrentPromotion.total ?? 0)) *
                                  100
                                }
                                valuemin={0}
                                valuemax={100}
                              />
                            </MDBProgress>
                          </>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
