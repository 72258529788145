import cardbg from "../../assets/cardbg.png";
import { ReactComponent as BankIcon } from "../../assets/bank.svg";
import { ReactComponent as DefaultIcon } from "../../assets/defaultIcon.svg";
import { ReactComponent as DropDownIcon } from "../../assets/dropdownIcon.svg";
import { ReactComponent as Star } from "../../assets/star.svg";
import { ReactComponent as Visa } from "../../assets/visa.svg";
import { ReactComponent as Master } from "../../assets/master.svg";
import { ReactComponent as Amex } from "../../assets/amex.svg";
import styles from "./styles.module.css";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import EditPaymentModal from "../../container/Account/Payments/EditPaymentModal";
import { useState } from "react";
export default function PaymentCard({ item, setStripeId, setOpenDelete }) {
  const [openEditModal, setOpenEditModal] = useState(false);
  return (
    <>
      <div className={styles["container"]}>
        <img src={cardbg} alt="" style={{ width: "100%" }} />
        <div className={styles["bottom-left"]}>
          <div className="d-flex justify-content-between align-items-center mt-3 mx-3">
            <div>{item?.bank === "hbl" ? <BankIcon /> : <BankIcon />}</div>
            <div className="d-flex align-items-center">
              {item?.isDefault && <DefaultIcon className="me-2" width={80} />}
              <MDBDropdown className="shadow-none p-0">
                <MDBDropdownToggle
                  className="shadow-none p-0"
                  style={{ background: "none" }}
                >
                  <DropDownIcon width={30} />
                </MDBDropdownToggle>
                <MDBDropdownMenu
                  style={{ marginLeft: "-7rem", marginTop: "1rem" }}
                >
                  {/* <MDBDropdownItem link onClick={() => setOpenEditModal(true)}>
                    Edit
                  </MDBDropdownItem> */}
                  <MDBDropdownItem
                    link
                    onClick={() => {
                      setOpenDelete(true);
                      setStripeId(item?.userStripeId);
                    }}
                  >
                    Delete
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </div>
          <div className={styles["star"]}>
            <div className="d-flex align-items-center mx-3">
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
              <span className="fw-bolder black ms-1">{item?.cardNo}</span>
            </div>
          </div>
          <div className={styles["userdetail"]}>
            <div className="d-flex justify-content-between align-items-center mx-3">
              <div className="d-flex flex-column ">
                <span className="black fw-bold">{item?.holderName ?? " "}</span>
                <span className="black fw-bold">{item?.expiry ?? "-"}</span>
              </div>
              <div>
                {item?.cardType === "Visa" ? (
                  <Visa />
                ) : item?.cardType === "MasterCard" ? (
                  <Master />
                ) : (
                  item?.cardType === "American Express" && <Amex />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!!openEditModal && (
        <EditPaymentModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          data={item}
        />
      )}
    </>
  );
}
