import styles from "../styles.module.css";
import React, { useEffect } from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import ProfileSettings from "../../../components/Account/Profile/ProfileSettings";

export default function Profile() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="py-4 mx-3 mx-md-4 mx-lg-5 my-1  ">
      <div
        className={styles["changepasswordbox"]}
        style={{
          minHeight: "75vh",
        }}
      >
        <>
          <div className="col-md-11 col-11 mx-auto ">
            <MDBBreadcrumb className="mx-md-5 mx-4 ps-md-2 ps-lg-0 mt-md-5 mt-4">
              <MDBBreadcrumbItem onClick={() => navigate("/account")}>
                Account Settings
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>Profile Settings</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <h5 className="fw-bold black mx-md-5 mx-4 ps-md-2 ps-lg-0 mt-3 py-2">
              Profile Settings
            </h5>

            <div className="col-lg-5 col-11 col-md-8 col-sm-11 ms-md-5 ms-3 ">
              <ProfileSettings account={true} />
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
