import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export const passwordResolver = yupResolver(
  Yup.object().shape({
    curpassword: Yup.string().required("Password is Required"),
    newpassword: Yup.string().required("Password Must be atleast 8 characters"),
    confirmpassword: Yup.string().required("Password is Required"),
  })
);
