import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import Greetings from "../../assets/wave.svg";
import { useEffect, useState } from "react";
import { setGreetingModal } from "../../redux/features/auth/authSlice";
import { useAppDispatch } from "../../redux/hooks";
import styles from "./styles.module.css";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import Cross from "../../assets/Cancel2.png";
import groupOrderingImage from "../../assets/groupOrdering.png";
import trashIcon from "../../assets/trash.png";
import userIcon from "../../assets/userIcon.png";
import InputField from "../../components/GroupOrder/InputField";
import { useAppSelector } from "../../redux/hooks";

import clockIcon from "../../assets/clockIcon.png";
import GroupOrderButton from "../../components/GroupOrder/GroupOrderButton";
import GroupMembersCard from "../../components/GroupOrder/GroupMembersCard";
import { useCreateGroupMutation } from "../../redux/features/groups/groupsApiSlice";
import Loader from "../../shared/UI/Loader/Loader";

export default function CreateNewGroup(props) {
  const visibleItems = props.selectedGroup?.groupMembers.slice(0, 6);
  const [selectedGroup, setSelectedGroup] = useState(null); // State to store the selected group
  const [viewDetailsModal, setCreateNewGroupModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [memberName, setMemberName] = useState("");
  const [membersArray, setMembersArray] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("Owner");
  const [newMemberName, setNewMemberName] = useState("");
  const [
    createGroup,
    {
      data: createGroupData,
      isLoading: createGroupLoading,
      isError: createGroupisError,
      error: createGrouperror,
    },
  ] = useCreateGroupMutation();

  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  // empty all states after adding the froup
  useEffect(() => {
    console.log("createGroupData", createGroupData);
    createGroupData && setGroupName("");
    setMembersArray([]);
    setMemberName("");
    setNewMemberName("");
  }, [createGroupData]);

  //comma separated
  useEffect(() => {
    setNewMemberName(
      membersArray.length > 0 ? `${membersArray},${memberName}` : memberName
    );
  }, [membersArray]);

  useEffect(() => {
    console.log("check newMemberName", newMemberName);
  }, [newMemberName]);

  useEffect(() => {
    console.log("check membersArray", membersArray);
  }, [membersArray]);

  const createGroupHandler = () => {
    // Call the createGroup mutation with the required parameters
    createGroup({
      dateTime: "2023-05-30 06:23:01",
      locationId: 11072,
      members: newMemberName, //memberName, //"summar.raja@globewyze.com,,",
      businessId: 8983,
      name: groupName,
      paymentMethod: paymentMethod,
      userId: user?.userId ?? 0, //12387,
    });
  };

  const handleAddMember = () => {
    if (memberName.trim() !== "") {
      setMembersArray([...membersArray, memberName]);
      console.log("check memberName", memberName);
      setMemberName(memberName);
      // Clear the input field (optional)
      setMemberName("");
    }
  };

  const handleRemoveMember = (index) => {
    const updatedMembers = [...membersArray];
    updatedMembers.splice(index, 1);
    setMembersArray(updatedMembers);
  };

  const disableModal = () => {
    props.setCreateNewGroupModal(false);
  };

  return (
    <>
      <MDBModal
        show={props.createNewGroupModal}
        tabIndex="-1"
        staticBackdrop
        appendToBody
      >
        <MDBModalDialog size="fullscreen-sm-down" centered>
          <MDBModalContent style={{ overflowY: "auto" }}>
            <MDBModalBody className="ps-0 pt-0 pe-0 pb-4">
              <div style={{ position: "relative" }}>
                <img
                  src={Cross}
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    marginTop: "6px",
                  }}
                  className={styles[`crossIcon`]}
                  onClick={() => {
                    disableModal();
                  }}
                />
              </div>

              <div className="col-lg-12 col-md-12 col-12 mx-lg-auto  mt-4 ">
                <div
                  className="justify-content-between"
                  style={{ paddingRight: "2rem", paddingLeft: "2rem" }}
                >
                  <div style={{ marginTop: "2.5rem" }}>
                    <div
                      style={{
                        color: "#606060",
                        fontWeight: "600",
                        fontSize: "17px",
                      }}
                    >
                      Create New Group
                    </div>
                    <div
                      className="mt-2 mb-4"
                      style={{ color: "#606060", fontSize: "13px" }}
                    >
                      This group will be automatically added to your group
                      ordering list!
                    </div>

                    {/* //input field/ */}
                    <div
                      className="row"
                      style={{
                        marginTop: "2rem",
                        marginBottom: "2rem",
                        marginLeft: "0px",
                        marginRight: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <div
                        className="col-12"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <InputField
                          value={groupName}
                          label={"Group Name"}
                          onChange={(e) => setGroupName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div
                      className="row mt-4 mb-4"
                      style={{
                        marginLeft: "0px",
                        marginRight: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <div className="col-10" style={{ paddingLeft: "0px" }}>
                        <InputField
                          value={memberName}
                          label={"Add Member via Email"}
                          onChange={(e) => setMemberName(e.target.value)} //
                        />
                      </div>
                      <div
                        className="col-2"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <GroupOrderButton
                          backgroundColor={"#F54222"}
                          color={"#fff"}
                          OnClickHandler={() => handleAddMember()}
                          text={"Add"}
                        />
                      </div>
                    </div>
                    {/* Display selected members as tags with a remove button */}
                    {membersArray.map((member, index) => (
                      <div
                        key={index}
                        className="badge me-2"
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "2px",
                          color: "#000",
                          border: "1px solid #CECECE",
                          width: "fit-content",
                          height: "fit-content",
                          padding: "5px",
                          textAlign: "center",
                        }}
                      >
                        {member}{" "}
                        <img
                          src={Cross}
                          style={{
                            height: "12px",
                            width: "12px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveMember(index)}
                        />
                      </div>
                    ))}

                    {/* // button */}
                    <div className="mb-3" style={{ marginTop: "2.5rem" }}>
                      <GroupOrderButton
                        backgroundColor={"#F54222"}
                        color={"#fff"}
                        OnClickHandler={() => createGroupHandler()}
                        text={createGroupLoading ? <Loader /> : "Create Group"}
                      />
                    </div>
                    <div className=" mb-4">
                      <GroupOrderButton
                        backgroundColor={"#FCC6BD"}
                        color={"#F54222"}
                        OnClickHandler={() => {
                          props.setCreateNewGroupModal(false);
                          props.setSelectGroupModal(true);
                        }}
                        text={"Go Back"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
