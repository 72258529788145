import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../container/Home/Home";
import AccountRouting from "../container/Account";
import Checkout from "../container/Checkout/Checkout";
import MyOrders from "../container/MyOrders/MyOrders";
import MyRewards from "../container/MyRewards/MyRewards";
import OrderTracking from "../container/OrderTracking/OrderTracking";
import DeliveryDetail from "../container/DeliveryDetails/DeliveryDetails";
import PickupDetails from "../container/PickupDetail/PickupDetails";
import OrderHistory from "../container/Account/OrderHistory/OrderHistory";
import LoyaltyPoints from "../container/LoyaltyPoints/LoyaltyPoints";
import GiftCards from "../container/GiftCards/GiftCards";
import DownloadReceipt from "../container/Checkout/DownloadReceipt";
import Promotions from "../container/Promotions/Promotions";
export default function ProtectedRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Promotions />} />
      <Route path="/menu" element={<Home />} />
      <Route path="/account/*" element={<AccountRouting />} />
      <Route path="/checkout/*" element={<Checkout />} />
      <Route path="/testroute/*" element={<DownloadReceipt />} />
      <Route path="/deliverydetail/*" element={<DeliveryDetail />} />
      <Route path="/pickdetail/*" element={<PickupDetails />} />
      <Route path="/myorders/*" element={<MyOrders />} />
      <Route path="/giftcards/*" element={<GiftCards />} />
      <Route path="/myrewards/*" element={<MyRewards />} />
      <Route path="/loyaltypoints/*" element={<LoyaltyPoints />} />
      <Route path="/orderhistory/*" element={<OrderHistory />} />
      <Route path="/trackorder/:cartId" element={<OrderTracking />} />
      <Route path="/" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
