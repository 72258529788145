import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBRow,
  MDBIcon,
} from "mdb-react-ui-kit";
import { MDBBtn } from "mdb-react-ui-kit";
import styles from "./styles.module.css";
import ButtonLoader from "../Loader/ButtonLoader";
export default function DeleteAlert({
  isOpen,
  onClose,
  onOk,
  message,
  heading,
  cancelText,
  isLoading,
}) {
  return (
    <MDBModal
      tabIndex="-1"
      show={isOpen}
      setShow={(show) => {
        if (!show && isOpen) onClose();
      }}
      appendToBody
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalBody style={{ padding: "0rem" }}>
            <MDBRow className="mb-2">
              <div className=" col-11 mx-auto mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className=" fw-bold mb-0">
                    {heading ? heading : "Delete Content?"}
                  </h5>{" "}
                  <div role="button" onClick={onClose}>
                    <MDBIcon fas icon="times" />
                  </div>
                </div>
              </div>
              <div className=" ms-4 mt-3">
                <div className="fs-6 ">{message}</div>
              </div>
            </MDBRow>
          </MDBModalBody>
          <div className="d-flex align-items-center justify-content-around mb-3  mt-2 text-center">
            <div className="col-5">
              <MDBBtn
                className={styles["btn_undo"] + " shadow-none"}
                size={"md"}
                onClick={onClose}
              >
                Cancel
              </MDBBtn>
            </div>

            <div className="col-5">
              <MDBBtn
                className={styles["btn_okay"] + " shadow-none"}
                size={"md"}
                onClick={onOk}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ButtonLoader />
                ) : (
                  <>{cancelText ? cancelText : "Delete"}</>
                )}
              </MDBBtn>
            </div>
          </div>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
