import { businessId } from "../../config";
import { useAddLoyaltyPointsMutation } from "../../redux/features/loyaltypoints/loyaltyPointsApiSlice";
import { useAppSelector } from "../../redux/hooks";
import { ReactComponent as EligibleIcon } from "../../assets/elgible.svg";
import { ReactComponent as NotEligibleIcon } from "../../assets/notEligble.svg";
import { ReactComponent as NotRedeemPointsIcon } from "../../assets/redemOff.svg";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

export default function RedeemCard({ item, userRewardPoints, index }) {
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const navigate = useNavigate();
  const location = useAppSelector((state) => state?.common?.location);
  const [addLoyaltyPoints, { isLoading }] = useAddLoyaltyPointsMutation();
  const onSubmit = async (values) => {
    const res = await addLoyaltyPoints({
      itemId: values?.shopItemId,
      userId: user?.userId ?? 0,
      sizeId: values.itemsizes[0]?.size?.sizeId,
      businessId: businessId,
      locationId: user?.pickupAddress ?? location.locationId,
    });
    if ("data" in res && res.data.status === "SUCCESS") {
      navigate("/orderhistory");
    }
  };
  return (
    <>
      <div
        className="col-lg-5 col-md-7 col-sm-8 me-3 col-11 col-lg-5 my-2 p-2"
        style={{
          minHeight: "8rem",
          background: "#FAFAFA",
          border: "1px solid #EAEAEA",
          borderRadius: "12px",
        }}
      >
        <div className="row g-0 align-items-center">
          <div className="col-md-4">
            <img
              src={item.imageURL}
              style={{ objectFit: "cover" }}
              alt=""
              className={styles["card-image"] + " p-md-1 pb-1"}
            />
          </div>
          <div className="col-md-8 px-2">
            <div className="d-flex justify-content-between align-items-center mt-md-0 mt-2">
              <h6 className="mb-0 fw600 black">{item?.name ?? "-"}</h6>
              <div>
                {userRewardPoints?.rewardPoint > item?.points ? (
                  <EligibleIcon />
                ) : (
                  <NotEligibleIcon />
                )}
              </div>
            </div>
            <p className="elipses-2 my-2">{item?.description ?? "-"}</p>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <h6 className="mb-0 black fw600">{item.points ?? 0} Points</h6>
              <div className="mb-md-0 mb-2">
                {userRewardPoints?.rewardPoint > 0 ? (
                  <button
                    onClick={() => onSubmit(item)}
                    type="button"
                    className="bgRed border-0 rounded shadow-none white px-2 py-1"
                  >
                    {isLoading ? "Processing..." : "Redeem Points"}
                  </button>
                ) : (
                  <NotRedeemPointsIcon />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
