import { apiSlice } from "../../api/apiSlice";

export const groupsApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Groups"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      //getAllgroups
      getGroupsByLocation: builder.query({
        // Define the query function to specify the URL and parameters
        query: (params) =>
          `/group/getByLocation?businessId=${params.businessId}&userId=${params.userId}&locationId=${params.locationId}&dateTime=${params.dateTime}`,
        // Provide a unique tag for this endpoint
        method: "GET",
        providesTags: ["Groups"],
        // Transform the response if needed
        transformResponse: (result) => {
          // You can process the result here if needed
          return result.payLoad ?? [];
        },
      }),

      //deleteone group
      deleteGroup: builder.mutation({
        query: (
          params // query: (body) => ({
        ) => ({
          url: `/group/deleteGroup?groupId=${params.groupId}&businessId=${params.businessId}&userId=${params.userId}`,
          method: "POST",
        }),
        // body,         }),
        invalidatesTags: ["Groups"],
        transformResponse: (result) => {
          return result.payLoad ?? null;
        },
      }),
      //add one group
      createGroup: builder.mutation({
        query: (params) => ({
          url: `/groupAdd?dateTime=${params.dateTime}&locationId=${params.locationId}&members=${params.members}&businessId=${params.businessId}&name=${params.name}&paymentMethod=${params.paymentMethod}&userId=${params.userId}`,
          method: "POST",
        }),
        invalidatesTags: ["Groups"],
        transformResponse: (result) => {
          return result.payLoad ?? null;
        },
      }),

      //view one goup
      viewGroupById: builder.query({
        query: (params) =>
          `/group/getByGroup?userId=${params.userId}&businessId=${params.businessId}&dateTime=${params.dateTime}&groupId=${params.groupId}`,
        method: "GET",
        providesTags: ["Groups"],
        transformResponse: (result) => {
          return result ?? [];
        },
      }),

      //remove one member from group
      removeMember: builder.mutation({
        query: (params) => ({
          url: `/group/leaveGroup?businessId=${params.businessId}&userId=${params.memberUserId}&groupId=${params.groupId}`,
          method: "POST",
        }),
        invalidatesTags: ["Groups"],
        transformResponse: (result) => {
          return result.payLoad ?? null;
        },
      }),

      //add new member update group
      addNewMember: builder.mutation({
        query: (params) => ({
          url: `/group/Update?name=${params.name}&userId=${params.userId}&businessId=${params.businessId}&locationId=${params.locationId}&paymentMethod=${params.paymentMethod}&members=${params.members}&groupId=${params.groupId}&dateTime=${params.dateTime}`,
          method: "POST",
        }),
        invalidatesTags: ["Groups"],
        transformResponse: (result) => {
          return result.payLoad ?? null;
        },
      }),

      //change member role
      changeMemberRole: builder.mutation({
        query: (params) => ({
          url: `/group/groupMemberChangeRole?businessId=${params.businessId}&userId=${params.userId}&memberUserId=${params.memberUserId}&locationId=${params.locationId}&dateTime=${params.dateTime}&groupId=${params.groupId}&roleId=${params.roleId}`,
          method: "POST",
        }),
        invalidatesTags: ["Groups"],
        transformResponse: (result) => {
          return result.payLoad ?? null;
        },
      }),
    }),
  });

export const {
  useGetGroupsByLocationQuery,
  useDeleteGroupMutation,
  useCreateGroupMutation,
  useViewGroupByIdQuery,
  useRemoveMemberMutation,
  useAddNewMemberMutation,
  useChangeMemberRoleMutation,
} = groupsApiSlice;
