import { useEffect, useState } from "react";
import { useAppSelector } from "./redux/hooks";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import MessageDialog from "./shared/UI/MessageDialog/MessageDialog";
import { useDispatch } from "react-redux";
import {
  setCategories,
  setCurrentLocation,
} from "./redux/features/common/commonSlice";
import { getCurrentPosition, toggleBodyScroll } from "./helper/utilis";
import Navbar from "./shared/UI/Navbar/Navbar";
import { BrowserRouter } from "react-router-dom";
import Drawer from "./shared/UI/Navbar/Drawer";
import { useLazyGetCategoriesQuery } from "./redux/features/categories/categoryApiSlice";

import { businessId } from "./config";
import TimingModal from "./components/Home/TimingModal";
import { useGetLocationsQuery } from "./redux/features/common/commonApiSlice";
import { useLazyGetFavListQuery } from "./redux/features/cart/cartApiSlice";
// import MenuFloater from "./shared/UI/MenuFloater/MenuFloater";
function App() {
  const [getCategories, categoryData] = useLazyGetCategoriesQuery();
  const [getFavList] = useLazyGetFavListQuery();
  useGetLocationsQuery({ businessId });
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const location = useAppSelector((state) => state?.common?.location);

  const dispatch = useDispatch();
  const isRestaurantOpen = useAppSelector(
    (state) => state?.common?.isRestaurantOpen
  );
  const token = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.token
  );
  const { successMessage, errorMessage } = useAppSelector(
    (state) => state.common
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const [showTimingModal, setShowTimingModal] = useState(false);

  useEffect(() => {
    if (user)
      getFavList({
        businessId: businessId,
        userId: user?.userId ?? 0,
      });
    if (location) {
      getCategories({
        userId: user?.userId ?? 0,
        businessId: businessId,
        locationId: user?.pickupAddress ?? location?.locationId,
      });
    }
    // eslint-disable-next-line
  }, [location, user]);

  useEffect(() => {
    if (categoryData.data) {
      dispatch(setCategories(categoryData.data));
    }
  }, [categoryData.data]);

  useEffect(() => {
    const getLocation = async () => {
      getCurrentPosition()
        .then((position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setCurrentLocation({ lat: latitude, lon: longitude }));
        })
        .catch((error) => {
          dispatch(setCurrentLocation(null));
        });
    };
    getLocation();
  }, []);

  useEffect(() => {
    toggleBodyScroll(!showDrawer);
  }, [showDrawer]);

  return (
    <>
      <div style={{ marginTop: isRestaurantOpen ? "0" : "0" }}>
        <BrowserRouter>
          <Navbar
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            setShowTimingModal={setShowTimingModal}
          />
          <Drawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
          {token ? <ProtectedRoutes /> : <PublicRoutes />}
        </BrowserRouter>
      </div>
      {/* <div className="d-md-none d-lg-none d-xl-none">
        <MenuFloater />
      </div> */}
      <TimingModal
        showTimingModal={showTimingModal}
        setShowTimingModal={setShowTimingModal}
      />
      <MessageDialog
        message={successMessage}
        open={!!successMessage}
        type="success"
        setOpen={() => {}}
      />
      <MessageDialog
        message={errorMessage}
        open={errorMessage == "No record available" || errorMessage == "Something went wrong" ? false : !!errorMessage}
        type="failure"
        setOpen={() => {}}
      />
    </>
  );
}

export default App;
