import { MDBInput } from "mdb-react-ui-kit";
import { Controller, useForm } from "react-hook-form";
import FormValidationError from "../../../shared/UI/FormValidationError";
import styles from "../styles.module.css";
import { forgetPasswordResolver } from "../../../validators/authenticationResolver";
const defaultValues = {
  email: "",
};
export default function ForgetPassword() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues, resolver: forgetPasswordResolver });
  const onSubmit = (values) => {
  
  };
  return (
    <>
      <div className="mt-5 col-10 mx-auto">
        <div className="pb-4">
          <h5 className="text-center black fw600">Forgot Password?</h5>
          <small className="d-block mx-auto text-center fw-bold">
            No worries, we’ll send you reset instructions.
          </small>
        </div>

        <div className="my-3">
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <MDBInput
                className={`${errors.email && "is-invalid"}`}
                label="Email or Mobile Number"
                size="lg"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <FormValidationError errorMessage={errors.email?.message} />
        </div>
        <div className="my-3">
          <button
            className={
              styles["gradient"] + " col-12 rounded border-0 white py-2 mt-3"
            }
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Reset Password
          </button>
        </div>
        <div className="text-center mb-4">
          <small className="gray cursor">
            &larr; <span className="ms-1">Back to login</span>{" "}
          </small>
        </div>
      </div>
    </>
  );
}
