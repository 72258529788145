import { apiSlice } from "../../api/apiSlice";

export const OrderHistoryApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["OrderHistory"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOrderHistory: builder.query({
        query: (data) => ({
          url: "User/getHistory?" + new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["OrderHistory"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),

      getOrderHistorybyDateAndTime: builder.query({
        query: (data) => ({
          url:
            "/Order/customer/orderByDateTime?" +
            new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["ActiveOrderHistory"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
    }),
  });
export const {
  useLazyGetOrderHistoryQuery,
  useLazyGetOrderHistorybyDateAndTimeQuery,
} = OrderHistoryApiSlice;
