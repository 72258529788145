import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export const loginResolver = yupResolver(
  Yup.object().shape({
    email: Yup.string().required("Email is Required"),
    password: Yup.string().required("Password is Required"),
  })
);
export const registerResolver = yupResolver(
  Yup.object().shape({
    fullname: Yup.string().required("Full Name is Required"),
    email: Yup.string().required("Email is Required"),
    mobile: Yup.string().required("Mobile Number is Required"),
    password: Yup.string().required("Password is Required"),
  })
);
export const forgetPasswordResolver = yupResolver(
  Yup.object().shape({
    email: Yup.string().required("Email or Phone Number is Required"),
  })
);
export const otpResolver = yupResolver(
  Yup.object().shape({
    otp: Yup.number().required("OTP is Required"),
  })
);
export const newPasswordResolver = yupResolver(
  Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters. ")
      .required("Password is Required"),
  })
);
