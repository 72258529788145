import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as Diamond } from "../../assets/diamondIcon.svg";
import { ReactComponent as DiamondGold } from "../../assets/diamondIcon.svg";
import { ReactComponent as Forward } from "../../assets/forwardIcon.svg";
import { useDispatch } from "react-redux";
import { setLoginState } from "../../redux/features/auth/authSlice";

export default function LoyaltyPointsBadge({
  user,
  userRewardPoints,
  isRounded,
}) {
  const dispatch = useDispatch();
  return (
    <div
      className={styles[`loyaltyPointDiv${isRounded ? "-rounded" : ""}`]}
      style={{ background: !!user ? "#DB7712" : "white" }}
      onClick={() => {
        if (!user) {
          dispatch(setLoginState(true));
        }
      }}
    >
      {!!user ? (
        <>
          <div className="d-flex align-items-center">
            <div>
              <DiamondGold />
            </div>

            <span
              style={{
                color: "#FFF8EB",
                fontWeight: "bold",
                fontSize: "18px",
              }}
              className="mx-2"
            >
              {userRewardPoints?.rewardPoint ?? 0}&nbsp;Points
            </span>
          </div>
          {userRewardPoints?.rewardPoint >= 5000 ? (
            "Gold Status"
          ) : (
            <small style={{ color: "#FFF8EB" }} className="mx-2">
              Earn {5000 - (userRewardPoints?.rewardPoint ?? 0)} more points to
              achieve Gold Status
            </small>
          )}
        </>
      ) : (
        <div className="d-flex align-items-center">
          <div>
            <Diamond />
          </div>
          <div className="ms-3" style={{ alignSelf: "center" }}>
            <>
              <span className="fw-bold">Sign in to earn Loyalty points</span>
              <br />
              <small>Collect 50 points and get a free meal</small>
            </>
          </div>
        </div>
      )}
      {!user && isRounded && (
        <div className="me-2">
          <Forward width={8} />
        </div>
      )}
    </div>
  );
}
