import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as Location } from "../../assets/locationIcon.svg";
import { ReactComponent as SmallClock } from "../../assets/smallClock.svg";
import { MDBBtn } from "mdb-react-ui-kit";
import HomeCart from "./HomeCart";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router";
import { setAddressType } from "../../redux/features/auth/authSlice";
export default function Cart({ address }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isRestaurantOpen = useAppSelector(
    (state) => state?.common?.isRestaurantOpen
  );
  const guestCartData = useAppSelector((state) => state?.cart?.cartData);
  const addressType = useAppSelector((state) => state?.auth?.addressType);
  const cartNavigate = () => {
    // if (addressType === "pickUp") {
    // dispatch(setAddressType({ type: "pickUp" }));
    navigate("/pickdetail");
    //} else {
    //dispatch(setAddressType({ type: "delivery" }));
    //navigate("/deliverydetail");
    //}
  };
  return (
    <>
      <div
        id="sticky-cart"
        className={styles["cartDiv"] + " p-3"}
        style={{ top: isRestaurantOpen ? "5rem" : "10rem" }}
      >
        <div
          style={{
            overflow: "auto",
            height: "20rem",
          }}
        >
          <div style={{ border: "2px red" }}>
            <p className="fw-bold">Your Cart</p>
            <div className="d-flex">
              <div>
                <Location />
              </div>
              <div className="ms-2">
                Pickup at <span style={{ color: "#F54222" }}>{address}</span>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ alignSelf: "center" }}>
                <SmallClock />
              </div>
              <div className="ms-2">Order Now</div>
            </div>
            <div className="mt-4 pe-2">
              <hr className="horiRow" />
            </div>
          </div>
          <div className={"mt-4"}>
            <HomeCart />
          </div>
        </div>
        {guestCartData?.length > 0 && (
          <MDBBtn
            className="bgRed shadow-none col-12 mt-3"
            onClick={() => {
              cartNavigate();
            }}
            size="lg"
          >
            Proceed to Checkout
          </MDBBtn>
        )}
      </div>
    </>
  );
}
