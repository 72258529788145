import AddressCard from "../../../components/Account/Address/AddressCard";
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn } from "mdb-react-ui-kit";
import styles from "../../../container/Account/styles.module.css";
import { ReactComponent as ButtonAddIcon } from "../../../assets/btnAdd.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddAddress from "./AddAddress";
import {
  useDeleteAddressMutation,
  useLazyGetAddressesQuery,
} from "../../../redux/features/account/addressesApiSlice";
import Loader from "../../../shared/UI/Loader/Loader";
import DeleteAlert from "../../../shared/UI/MessageDialog/DeleteAlert";
import { useAppSelector } from "../../../redux/hooks";

export default function Addresses() {
  const navigate = useNavigate();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [getAddresses, result] = useLazyGetAddressesQuery();
  const [deleteAddress, { isLoading }] = useDeleteAddressMutation();
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [addressId, setAddressId] = useState();
  const [addressList, setAddressList] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getAddresses({
      userId: user?.userId ?? 0,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (result?.data) {
      setAddressList(result?.data);
    }
  }, [result?.data]);
  const onDeletSubmit = async () => {
    const result = await deleteAddress({
      addressesId: addressId,
    });
    if ("data" in result && result.data.status === "SUCCESS") {
      setOpenDelete(false);
    }
  };
  return (
    <div className="py-4 mx-3 mx-md-4 mx-lg-5 my-1  ">
      <div
        className={styles["changepasswordbox"]}
        style={{
          minHeight: "75vh",
        }}
      >
        <div className="col-md-11 col-12 mx-auto">
          <MDBBreadcrumb className="mx-md-5 mx-4 ps-md-2 ps-lg-0 mt-md-5 mt-4">
            <MDBBreadcrumbItem onClick={() => navigate("/account")}>
              Account Settings
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Addresses</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <h5 className="fw-bold black mx-md-5 mx-4 ps-md-2 ps-lg-0 mt-3 py-2">
            Addresses
          </h5>

          <div className=" mx-sm-5 mx-3 my-3">
            {result?.isLoading ? (
              <div style={{ margin: "5rem 0rem" }}>
                <Loader />
              </div>
            ) : (
              <div className="row">
                {addressList?.length > 0 ? (
                  addressList?.map((item, index) => (
                    <div
                      className={`col-lg-6 col-md-6  col-12   py-2   `}
                      key={index}
                    >
                      <AddressCard
                        item={item}
                        setId={setAddressId}
                        addressId={addressId}
                        setOpenDelete={setOpenDelete}
                      />
                    </div>
                  ))
                ) : (
                  <h6 className="text-center black fw-bold my-5">
                    No Address Found
                  </h6>
                )}
              </div>
            )}
            <div>
              <MDBBtn
                className={`px-3 mt-3 mb-md-5 mb-3 shadow-none fw-bold ${styles.btnUpload}`}
                onClick={() => setOpenModal(true)}
              >
                <ButtonAddIcon className="me-2 mb-1" />
                Add New Address
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      {!!openModal && <AddAddress open={openModal} setOpen={setOpenModal} />}

      {!!openDelete && (
        <DeleteAlert
          isOpen={openDelete}
          isLoading={isLoading}
          onClose={() => setOpenDelete(false)}
          heading={"Delete Address?"}
          cancelText={"Proceed"}
          message={"Are you sure want to Delete this Address?"}
          onOk={onDeletSubmit}
        />
      )}
    </div>
  );
}
