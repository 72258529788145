import React, { useState } from "react";
import styles from "./styles.module.css";
import dotsVertical from "../../assets/dotsVertical.png";

export default function GroupMembersCard(props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); //
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  return (
    <>
      <div className="mt-2 row align-items-center mb-3">
        <div
          className="col-2"
          //   style={{
          //     display: "flex",
          //     justifyContent: "flex-end",
          //     marginLeft: "-4px",
          //   }}
        >
          <div className={styles[`defaultImageIcon`]}> </div>
        </div>

        <div className="col-7" style={{ paddingLeft: "0px" }}>
          <div className={styles[`orderHeading`]}>{props.name}</div>
          {props.email && (
            <div className={styles[`orderText`]}>{props.email}</div>
          )}
        </div>

        {props.groupowner ? (
          <div className="col-3" style={{ textAlign: "end" }}>
            <div
              className={styles[`orderHeading`]}
              style={{ fontSize: "12px" }}
            >
              Group owner
            </div>
          </div>
        ) : (
          <div className="col-3" style={{ textAlign: "end" }}>
            {/* Three dots button/div */}
            <div
              className={styles[`dotsButton`]}
              onClick={toggleDropdown}
              // onBlur={closeDropdown}
              tabIndex={0}
            >
              <img
                src={dotsVertical}
                style={{ height: "1.2rem", width: "1.2rem", cursor: "pointer" }}
              />
            </div>
            {isDropdownOpen && (
              <div
                style={{
                  backgroundColor: "#e6e5e5",
                  borderRadius: " 3px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  textAlign: "center",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}
              >
                <div
                  style={{
                    fontSize: "13px",
                    marginBottom: "3px",
                    cursor: "pointer",
                  }}
                  onClick={props.removeMemberHandler}
                >
                  Remove
                </div>
                <div
                  style={{ fontSize: "13px", cursor: "pointer" }}
                  onClick={props.changeRoleHandler}
                >
                  Make Owner
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

//////////////////////////////////////
// import styles from "./styles.module.css";
// import dotsVertical from "../../assets/dotsVertical.png";

// import { Menu, Dropdown, message } from "antd";
// export default function GroupMembersCard(props) {
//   const menu = (
//     <Menu
//     // className="joblisting-dropdown"
//     // onClick={onClick}
//     >
//       <Menu.Item
//         key="Edit"
//         // onClick={() => EditJobHandler()}
//       >
//         Edit
//       </Menu.Item>

//       <Menu.Item
//         key="Delete"
//         // onClick={() => setshowmodal(true)}
//       >
//         Make Admin
//       </Menu.Item>
//     </Menu>
//   );
//   return (
//     <>
//       <div className="mt-2 row align-items-center mb-3">
//         <div
//           className="col-2"
//           //   style={{
//           //     display: "flex",
//           //     justifyContent: "flex-end",
//           //     marginLeft: "-4px",
//           //   }}
//         >
//           <div className={styles[`defaultImageIcon`]}> </div>
//         </div>

//         <div className="col-7" style={{ paddingLeft: "0px" }}>
//           <div className={styles[`orderHeading`]}>{props.name}</div>
//           {props.email && (
//             <div className={styles[`orderText`]}>{props.email}</div>
//           )}
//         </div>

//         {props.groupowner ? (
//           <div className="col-3">
//             <div
//               className={styles[`orderHeading`]}
//               style={{ fontSize: "12px" }}
//             >
//               Group owner
//             </div>
//           </div>
//         ) : (
//           //////////////
//           <div
//             className="col-3"
//             style={{ display: "flex", justifyContent: "end" }}
//           >
//             <Dropdown overlay={menu} trigger={["click"]}>
//               <img
//                 src={dotsVertical}
//                 style={{
//                   height: "1.2rem",
//                   width: "1.2rem",
//                 }}
//               />
//             </Dropdown>
//           </div>
//         )}
//       </div>
//     </>
//   );
// }
