import { ReactComponent as Fb } from "../../../assets/fb.svg";
import { ReactComponent as Insta } from "../../../assets/insta.svg";
export default function Footer() {
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <small className="fw600 lightBlack text-center">
          13608 Midway Rd, Farmers Branch, TX 75244,{" "}
          <span className="text-nowrap">United States</span>
        </small>
        <div className="d-flex">
          <small className="fw600 lightBlack text-center mx-1">
            inquiries@bkkhanbbq.com,{" "}<span className="text-nowrap">+1 214-441-9055</span>
          </small>
        </div>
        <div>
          <div className="d-flex justify-content-between align-items-center my-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/BkkhanBBQ/"
            >
              {" "}
              <Fb className="mx-3 cursor" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/bkkhanbbq/"
            >
              <Insta className="mx-3 cursor" />{" "}
            </a>
          </div>
        </div>
        <small className="text-center mt-2 mb-4 fw600 lightBlack text-center">
          {" "}
          Copyright © {new Date()?.getFullYear()}, Powered by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mywyzer.com/"
            className="cursor lightBlack"
          >
            Wyzer
          </a>
        </small>
      </div>
    </>
  );
}
