export default function CheckEmail() {
  return (
    <>
      <div className="mt-5 col-10 mx-auto">
        <div>
          <h5 className="text-center black fw600">Check Your Email</h5>
          <small className="d-block mx-auto text-center fw-bold">
            We have sent you a password reset link to <br />
            Khizer@wyzer.com
          </small>
        </div>
        <small className="d-block mx-auto text-center fw-bold mt-3">
          <span>
            Didn’t receive the email?
            <span className="ms-1 cursor red">Click to Resend</span>{" "}
          </span>
        </small>
        <div className="text-center my-4">
          <small className="gray cursor">
            &larr; <span className="ms-1">Back to login</span>{" "}
          </small>
        </div>
      </div>
    </>
  );
}
