import React from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { ReactComponent as Cross } from "../../../assets/authcross.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InjectedPaymentForm from "../../Checkout/PaymentForm";
const stripePromise = loadStripe("pk_test_zSvQL59v8Ry8S0guMJL8czGC");
export default function AddPaymentModal({ open, setOpen }) {
  const toggleShow = () => setOpen(!open);

  return (
    <>
      <MDBModal show={open} setShow={setOpen} tabIndex="-1" staticBackdrop>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody>
              <div className="pb-3">
                <Cross className="cursor float-end" onClick={toggleShow} />
              </div>
              <div className="mt-3">
                <h5 className="black text-center fw-bold">
                  Add New Payment Method
                </h5>
                <p className="text-center">
                  This payment method will be added to your methods list
                </p>
                <div className="mx-md-3  mt-5">
                  <Elements stripe={stripePromise}>
                    <InjectedPaymentForm setOpen={setOpen} />
                  </Elements>
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
