import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

import { ReactComponent as DefaultIcon } from "../../../assets/tag.svg";
import { ReactComponent as DropDownIcon } from "../../../assets/dropdownIcon.svg";
import { useEffect, useState } from "react";
import EditAddress from "../../../container/Account/Addresses/EditAddress";
import { useLazyGetProfileQuery } from "../../../redux/features/account/profileApiSlice";
import { businessId } from "../../../config";
import { useAppSelector } from "../../../redux/hooks";

export default function AddressCard({ item, setId, addressId, setOpenDelete }) {
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [openEditModal, setOpenEditModal] = useState(false);
  const [getProfile, result] = useLazyGetProfileQuery();
  useEffect(() => {
    if (user)
      getProfile({
        userId: user?.userId ?? 0,
        businessId: businessId,
      });
    // eslint-disable-next-line
  }, [user, result?.data]);
  return (
    <>
      <MDBCard
        style={{
          background: "#F5F5F5",
          height: "100%",
        }}
        className="shadow-none "
      >
        <MDBCardBody>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <MDBCardTitle>{result?.data ? result?.data?.firstName+ " "+ result?.data?.lastName : "-"}</MDBCardTitle>
            </div>

            <div className=" d-flex align-items-center">
              {item?.setDefault && <DefaultIcon className="me-3" style={{transform: "scale(1.2)"}} />}
              <MDBDropdown className="shadow-none p-0">
                <MDBDropdownToggle
                  className="shadow-none p-0"
                  style={{ background: "none" }}
                >
                  <DropDownIcon width={30} />
                </MDBDropdownToggle>
                <MDBDropdownMenu
                        style={{ marginLeft: "-7rem", marginTop: "1rem" }}>
                  <MDBDropdownItem
                    className=" cursor"
                    link
                    onClick={() => {
                      setOpenEditModal(true);
                      setId(item?.addressId);
                    }}
                  >
                    Edit
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    className="cursor"
                    onClick={() => {
                      setOpenDelete(true);
                      setId(item?.addressId);
                    }}
                  >
                    Delete
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>{" "}
            </div>
          </div>
          <MDBCardText className="mt-3 black">
            <p className="mb-0 black">{item.address ?? "-"}</p>
            <p className="mb-0 black">{item?.city?.name ?? ""} {item?.province?.name ?? ""}{" "}
            {item?.country?.name ?? ""}</p>
            
          </MDBCardText>
          <MDBCardText className="black">{item.phoneNo ?? ""}</MDBCardText>
        </MDBCardBody>
      </MDBCard>

      {!!openEditModal && (
        <EditAddress
          addressId={addressId}
          open={openEditModal}
          setOpen={setOpenEditModal}
        />
      )}
    </>
  );
}
