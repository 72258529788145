import { apiSlice } from "../../api/apiSlice";
import { setSuccessMessage } from "../common/commonSlice";
import { store } from "../../store";
export const LoyaltyPointsApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["LoyaltyPoints"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLoyaltyPoints: builder.query({
        query: (data) => ({
          url:
            "Redeem/redeemItemList?" +
            new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["LoyaltyPoints"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
      getPromotions: builder.query({
        query: (data) => ({
          url:
            "Promotion/getList?" + new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["LoyaltyPoints"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
        getRedeemItemsList: builder.query({
          query: (data) => ({
            url:
              "Redeem/redeemItemList?" +
              new URLSearchParams({ ...data }).toString(),
            method: "GET",
          }),
          providesTags: ["LoyaltyPoints"],
          transformResponse: (result) => {
            return result.payLoad;
          },
        }),
      getUserRewardPoint: builder.query({
        query: (data) => ({
          url:
            "User/rewardPoint?" + new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["LoyaltyPoints"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
      addLoyaltyPoints: builder.mutation({
        query: (credentials) => ({
          url:
            "/Redeem/redeemPlaceOrder?" +
            new URLSearchParams({ ...credentials }).toString(),
          method: "POST",
        }),
        invalidatesTags: ["LoyaltyPoints"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result?.message));
          return result;
        },
      }),
      addPromotion: builder.mutation({
        query: (credentials) => ({
          url:
            "/Promotion/user/addPromotion?" +
            new URLSearchParams({ ...credentials }).toString(),
          method: "POST",
        }),
        invalidatesTags: ["LoyaltyPoints"],
        transformResponse: (result) => {
          store.dispatch(setSuccessMessage(result?.message));
          return result;
        },
      }),
      getMenuItemsList: builder.query({
        query: (data) => ({
          url:
            "Redeem/menu/getRedeem?" +
            new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["LoyaltyPoints"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),
      getUserPromotionsList: builder.query({
        query: (data) => ({
          url:
            "Promotion/user/getList?" +
            new URLSearchParams({ ...data }).toString(),
          method: "GET",
        }),
        providesTags: ["LoyaltyPoints"],
        transformResponse: (result) => {
          return result.payLoad;
        },
      }),

    }),
  });
export const {
  useLazyGetLoyaltyPointsQuery,
  useAddLoyaltyPointsMutation,
  useAddPromotionMutation,
  useLazyGetPromotionsQuery,
  useLazyGetRedeemItemsListQuery,
  useLazyGetUserRewardPointQuery,
  useLazyGetMenuItemsListQuery,
  useLazyGetUserPromotionsListQuery
} = LoyaltyPointsApiSlice;
