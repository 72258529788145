import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { ReactComponent as CancelIcon } from "../../assets/CancelIcon.svg";
import { useAppSelector } from "../../redux/hooks";
import { useLazyGetTimingsQuery } from "../../redux/features/common/commonApiSlice";
import { useEffect, useState } from "react";
import { convertTime } from "../../helper/utilis";

export default function TimingModal({ showTimingModal, setShowTimingModal }) {
  //const [greetingMessage, setGreetingMessage] = useState("");
  const [triggerTimeInformation, getTimeInformation] = useLazyGetTimingsQuery();
  const [timeInformation, setTimeInformation] = useState();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );

  useEffect(() => {
    if (showTimingModal)
      triggerTimeInformation({
        userId: 12231,
        businessId: 8983,
        locationId: 11077,
      });
  }, [showTimingModal]);

  useEffect(() => {
    if (getTimeInformation.data) {
      setTimeInformation(getTimeInformation?.data?.flexTime);
    }
  }, [getTimeInformation.data]);

  return (
    <>
      <div className="col-11 mx-auto">
        <MDBModal show={showTimingModal} tabIndex="-1" staticBackdrop>
          <MDBModalDialog size="md" centered>
            <MDBModalContent>
              <MDBModalBody>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {" "}
                  <CancelIcon
                    className="cursor"
                    onClick={() => {
                      setShowTimingModal(false);
                    }}
                  />
                </div>
                <div className="mx-lg-5 mx-md-4 mx-sm-4 mb-5">
                  <h5 className="fw600 lightBlack mb-3">Opening Hours</h5>

                  {timeInformation?.map((item, index) => (
                    <div className="d-flex justify-content-between black my-2 fw400">
                      <div style={{ fontSize: "18px" }}>{item?.weekDay}</div>

                      <div style={{ fontSize: "18px" }}>
                        {convertTime(item?.openingTime) +
                          " - " +
                          convertTime(item?.closingTime)}
                      </div>
                    </div>
                  ))}
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
}
