import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import Greetings from "../../assets/wave.svg";
import { useEffect, useState } from "react";
import { setGreetingModal } from "../../redux/features/auth/authSlice";
import { useAppDispatch } from "../../redux/hooks";
import styles from "./styles.module.css";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";

import SelectGroupModal from "./SelectGroupModal";
import { ReactComponent as Cross } from "../../assets/Cancel.svg";
import groupOrderingImage from "../../assets/groupOrdering.png";
import clockIcon from "../../assets/clockIcon.png";
import userIcon from "../../assets/userIcon.png";
import GroupOrderButton from "../../components/GroupOrder/GroupOrderButton";

export default function GroupOrderModal({
  showModal,
  setShowModal,
  setGroupName,
}) {
  const [selectGroupModal, setSelectGroupModal] = useState(false);
  const disableModal = () => {
    setShowModal(false);
  };

  console.log("showModal", showModal);
  return (
    <>
      <MDBModal show={showModal} tabIndex="-1" staticBackdrop appendToBody>
        <MDBModalDialog size="fullscreen-sm-down" centered>
          <MDBModalContent style={{ overflowY: "auto" }}>
            <MDBModalBody className="ps-0 pt-0 pe-0 pb-4">
              <div style={{ position: "relative" }}>
                <Cross
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    disableModal();
                  }}
                />
                <img
                  src={groupOrderingImage}
                  alt=""
                  style={{ borderRadius: " 8px 8px 0px 0px", width: "100%" }}
                  height={190}
                />
              </div>

              <div className="col-lg-12 col-md-12 col-12 mx-lg-auto  mt-4 ">
                <div
                  className="d-flex justify-content-between"
                  style={{ paddingRight: "2rem", paddingLeft: "2rem" }}
                >
                  <div>
                    <div
                      style={{
                        color: "#606060",
                        fontWeight: "600",
                        fontSize: "17px",
                      }}
                    >
                      Introducing Group Ordering
                    </div>
                    <div
                      className="mt-2 mb-4"
                      style={{ color: "#606060", fontSize: "13px" }}
                    >
                      Invite friends and guests to join and add their favorite
                      items to a shared cart.
                    </div>

                    {/* <MDBCard style={{width:'29rem'}}     className={styles[`menu-item-card`]}>
        <MDBCardBody className="p-0">
          <div className="d-flex justify-content-between">
            <div className=" w-75 pe-md-3">
            <div className=" row align-items-center">
             <div className="col-auto pe-0 mb-1"><img src={clockIcon} style={{ width: '1.2rem', height: '1.2rem' }} /> </div>
             <div className="col-auto pl-0 mb-0">
                <div style={{ color: '#606060', fontWeight: '600', fontSize: '13px' }}>Schedule ahead</div>
             </div>
         </div>
         <div className="col-auto mt-0" style={{ whiteSpace: 'nowrap', marginLeft:'2rem', color: "#606060", fontSize: '12px' }}>Invite friends and guests to join and add their favorite items to a shared cart.</div>
                   

              
            </div>
          </div>
        </MDBCardBody>
      </MDBCard> */}

                    <div className="mt-4 row align-items-center">
                      <div className="col-auto pe-0 mb-1">
                        <img
                          src={clockIcon}
                          style={{ width: "1.2rem", height: "1.2rem" }}
                        />{" "}
                      </div>
                      <div className="col-auto pl-0 mb-0">
                        <div className={styles[`orderHeading`]}>
                          Schedule ahead
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles[`orderText`]}
                      style={{ marginLeft: "1.9rem" }}
                    >
                      Invite friends and guests to join and add their favorite
                      items to a shared cart.
                    </div>

                    {/* // div2 */}
                    <div className="mt-3 row align-items-center">
                      <div className="col-auto pe-0 mb-1">
                        <img
                          src={userIcon}
                          style={{ width: "1.2rem", height: "1.2rem" }}
                        />{" "}
                      </div>
                      <div className="col-auto pl-0">
                        <div className={styles[`orderHeading`]}>
                          Selection Freedom
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles[`orderText`]}
                      style={{ marginLeft: "1.9rem" }}
                    >
                      Have friends and guests select separately and pay for then
                      everyone collectively.
                    </div>

                    <div className="mt-4 mb-4">
                      <GroupOrderButton
                        backgroundColor={"#F54222"}
                        color={"#fff"}
                        OnClickHandler={() => {
                          setShowModal(false);
                          setSelectGroupModal(!selectGroupModal);
                        }}
                        text={"Try Group Ordering"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <SelectGroupModal
        selectGroupModal={selectGroupModal}
        setSelectGroupModal={setSelectGroupModal}
        setGroupName={setGroupName}
      />
    </>
  );
}
