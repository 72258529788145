import { Route, Routes } from "react-router-dom";
import Account from "./Account";
import Profile from "./Profile/Profile";
import ChangePassword from "./ChangePassword/ChangePassword";
import Addresses from "./Addresses/Addresses";
import OrderHistory from "./OrderHistory/OrderHistory";
import Payments from "./Payments/Payments";

export default function AccountRouting(params) {
  return (
    <>
      <Routes>
        <Route path="*" element={<Account />} />
        <Route path="profile/*" element={<Profile />} />
        <Route path="changepassword/*" element={<ChangePassword />} />
        <Route path="addresses/*" element={<Addresses />} />
        <Route path="orderhistory/*" element={<OrderHistory />} />
        <Route path="payments/*" element={<Payments />} />
      </Routes>
    </>
  );
}
