import styles from "../styles.module.css";
export default function PasswordChanged() {
  const onSubmit = (values) => {
   
  };
  return (
    <>
      <div className="mt-5 col-10 mx-auto">
        <div>
          <h5 className="text-center black fw600">
            Password Changed Successfully
          </h5>
          <small className="d-block mx-auto text-center fw-bold">
            Your password has been successfully reset, <br />
            Click continue to login.
          </small>
        </div>

        <div className="my-3">
          <button
            className={
              styles["gradient"] + " col-12 rounded border-0 white py-2 mt-3"
            }
            type="button"
            onClick={onSubmit}
          >
            Continue
          </button>
        </div>
        <div className="text-center mb-4"></div>
      </div>
    </>
  );
}
