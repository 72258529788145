import { MDBCheckbox, MDBInput } from "mdb-react-ui-kit";
import { ReactComponent as Google } from "../../../assets/google.svg";
import { ReactComponent as Facebook } from "../../../assets/facebook.svg";
import { ReactComponent as Apple } from "../../../assets/apple.svg";
import { Controller, useForm } from "react-hook-form";
import { registerResolver } from "../../../validators/authenticationResolver";
import FormValidationError from "../../../shared/UI/FormValidationError";
import styles from "../styles.module.css";
import MDBPassword from "../../../shared/UI/MDBPassword";
const defaultValues = {
  email: "",
  password: "",
  fullname: "",
  mobile: "",
  terms: false,
};
export default function Register() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues, resolver: registerResolver });
  const onSubmit = (values) => {
  
  };
  return (
    <div className="mt-5 col-10 mx-auto">
      <div className="pb-4">
        <h5 className="text-center black fw600">Account Registration</h5>
        <small className="d-block mx-auto text-center fw-bold">
          Welcome to WAIM
        </small>
      </div>
      <div className="my-4">
        <Controller
          control={control}
          name="fullname"
          render={({ field: { onChange, value } }) => (
            <MDBInput
              className={`${errors.fullname && "is-invalid"}`}
              label="Full Name"
              size="lg"
              type="text"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <FormValidationError errorMessage={errors.fullname?.message} />
      </div>
      <div className="my-4">
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <MDBInput
              className={`${errors.email && "is-invalid"}`}
              label="Email"
              size="lg"
              type="email"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <FormValidationError errorMessage={errors.email?.message} />
      </div>
      <div className="my-4">
        <Controller
          control={control}
          name="mobile"
          render={({ field: { onChange, value } }) => (
            <MDBInput
              className={`${errors.mobile && "is-invalid"}`}
              label="Mobile Number"
              size="lg"
              type="text"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <FormValidationError errorMessage={errors.mobile?.message} />
      </div>
      <div className="mt-4 mb-2">
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value } }) => (
            <MDBPassword
              className={`${errors.password && "is-invalid"}`}
              label="Password"
              size="lg"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <FormValidationError errorMessage={errors.password?.message} />
      </div>
      <div>
        <Controller
          control={control}
          name="terms"
          render={({ field: { onChange, value } }) => (
            <MDBCheckbox
              onChange={onChange}
              value={value}
              labelStyle={{ color: "#A3A3A3" }}
              label={
                <>
                  I agree to the <u className="cursor">Terms & Services</u> of
                  WAIM
                </>
              }
              inline
            />
          )}
        />
      </div>
      <div className="my-4">
        <button
          className={styles["gradient"] + " col-12 rounded border-0 white py-2"}
          type="button"
          onClick={handleSubmit(onSubmit)}
        >
          Register
        </button>
      </div>
      <div className={styles["separator"]}>Or Login with</div>
      <div className="my-4 d-flex justify-content-center align-items-center">
        <Google className="cursor mx-3" width={20} />
        <Facebook className="cursor mx-3" width={20} />
        <Apple className="cursor mx-3" width={20} />
      </div>
      <div className="text-center mb-2">
        <small className="gray">Already Have an Account?</small>
        <small className="red uppercase ms-2 cursor">Login</small>
      </div>
    </div>
  );
}
