import React from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalBody,
  MDBModalContent,
} from "mdb-react-ui-kit";
import "react-phone-input-2/lib/style.css";
import { ReactComponent as Cross } from "../../assets/paymentcross.svg";
import { Elements } from "@stripe/react-stripe-js";
import InjectedPaymentForm from "../../container/Checkout/PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe("pk_test_zSvQL59v8Ry8S0guMJL8czGC");
const PaymentModal = ({ open, setOpen,openSuccessModal, setOpenSuccessModal}) => {
  return (
    <>
      <MDBModal show={open} setShow={setOpen}>
        <MDBModalDialog className="rounded" centered>
          <MDBModalContent>
            <MDBModalBody>
              <>
                <Cross
                  className="cursor float-end"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
                <div className="d-block mx-auto py-5 px-5">
                  <h4 className="text-center fw600 black">Pay Now</h4>
                  <p className="text-center fw600 mb-5">
                    You are paying for the gift card you purchased
                  </p>
                  <Elements stripe={stripePromise}>
                    <InjectedPaymentForm setOpen={setOpen} giftcard={true} setOpenSuccessModal={setOpenSuccessModal} />
                  </Elements>
                </div>
              </>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default PaymentModal;
