import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBRadio,
  MDBCheckbox,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Minus } from "../../assets/minus.svg";
import { ReactComponent as PlusThin } from "../../assets/plus-icon-thin.svg";
import { ReactComponent as MinusThin } from "../../assets/minus-icon-thin.svg";
import { ReactComponent as Cross } from "../../assets/Cancel.svg";
import { ReactComponent as Fav } from "../../assets/Fav.svg";
import { ReactComponent as Filled } from "../../assets/filled.svg";
import styles from "./styles.module.css";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Loader from "../../shared/UI/Loader/Loader";
import {
  useAddToFavouriteMutation,
  useDeleteFromFavouriteMutation,
} from "../../redux/features/cart/cartApiSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { businessId } from "../../config";
import {
  setAddToCart,
  setUpdateCart,
} from "../../redux/features/cart/cartSlice";
import { useAddItemByGroupMutation } from "../../redux/features/cart/cartApiSlice";

import { subTotal } from "../../helper/utilis";
import { useSelector } from "react-redux";
const defaultValues = {
  comment: "",
};

export default function AddToCartModal({
  showModal,
  setShowModal,
  menuItem,
  groupName,
}) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const favList = useSelector((state) => state.common.favouriteItems);
  const [isFavorite, setIsFavorite] = useState();
  const [favoriteId, setFavoriteId] = useState(null);
  const [id, setId] = useState();
  const [fav, setFav] = useState(false);
  const [deleteFromFavourite, { isLoading: deleteLoading }] =
    useDeleteFromFavouriteMutation();
  const [addToFavorite, { isLoading }] = useAddToFavouriteMutation();
  const menuItemId = menuItem?.shopItemId;

  const { handleSubmit } = useForm({
    defaultValues,
  });
  const [updatedMenuItem, setUpdatedMenuItem] = useState();

  const [
    addItemByGroup,
    {
      data: addItemByGroupData,
      isLoading: addItemByGroupLoading,
      isError: addItemByGroupIsError,
      error: addItemByGroupError,
    },
  ] = useAddItemByGroupMutation();

  useEffect(() => {
    if (menuItem) {
      setUpdatedMenuItem(menuItem);
    }
    // eslint-disable-next-line
  }, [menuItem, showModal]);
  const disableModal = () => {
    setShowModal(false);
  };
  const incrementQuantity = () => {
    const upadtedQuantityData = {
      ...updatedMenuItem,
      quantity: updatedMenuItem.quantity + 1,
    };
    setUpdatedMenuItem(upadtedQuantityData);
  };
  const decrementQuantity = () => {
    if (updatedMenuItem?.quantity > 1) {
      const upadtedQuantityData = {
        ...updatedMenuItem,
        quantity: updatedMenuItem.quantity - 1,
      };
      setUpdatedMenuItem(upadtedQuantityData);
    }
  };
  const incrementExtra = (variationIndex, subVariationIndex) => {
    const updatedMenu = { ...updatedMenuItem };
    const subVariation =
      updatedMenu?.listofVariations[variationIndex]?.variation
        ?.listofSubVariations[subVariationIndex];
    subVariation.quantity = subVariation?.quantity + 1;

    setUpdatedMenuItem(updatedMenu);
  };

  const decrementExtra = (variationIndex, subVariationIndex) => {
    const updatedMenu = { ...updatedMenuItem };
    const subVariation =
      updatedMenu?.listofVariations[variationIndex]?.variation
        ?.listofSubVariations[subVariationIndex];
    if (subVariation?.quantity > 0) {
      subVariation.quantity = subVariation.quantity - 1;
      setUpdatedMenuItem(updatedMenu);
    }
  };
  const handleFavoriteClick = async () => {
    setFav(true);
    if (isFavorite) {
      // Remove from Favorites logic
      const deleteFromFavoritesData = {
        userId: user?.userId ?? 0,
        favoriteId: favoriteId, // Use the stored favoriteId
        businessId: businessId,
      };

      try {
        await deleteFromFavourite(deleteFromFavoritesData);
        setFavoriteId(null); // Reset the favoriteId
        setFav(false);
        localStorage.setItem(`favorite_${menuItemId}`, "false"); // Update local storage
      } catch (error) {
        // Handle the error if needed
      }
    } else {
      // Add to Favorites logic
      const addToFavoritesData = {
        userId: user?.userId ?? 0,
        itemId: menuItemId,
        businessId: businessId,
      };

      try {
        const result = await addToFavorite(addToFavoritesData);
        if ("data" in result && result.data.status === "SUCCESS") {
          setFav(false);
          setFavoriteId(result.data.payLoad.favoriteId);
          localStorage.setItem(`favorite_${menuItemId}`, "true"); // Update local storage
        }
      } catch (error) {
        setFav(false);
      }
    }
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);
  };

  console.log(" localStorage.setItem", localStorage.getItem("selectedGroupId"));

  const onSubmit = async (values) => {
    if (updatedMenuItem?.cartItemId === -1) {
      const updatedMenuItemWithId = {
        ...updatedMenuItem,
        cartItemId: Math.floor(Math.random() * 100000000),
      };
      console.log("updatedMenuItem", updatedMenuItem);
      dispatch(setAddToCart(updatedMenuItemWithId));
    } else {
      dispatch(
        setUpdateCart({ type: "replace", updatedMenuItem: updatedMenuItem })
      );
    }

    //call add item to cart by ggroup if group is selected
    groupName &&
      addItemByGroup({
        userId: user?.userId ?? 0, //12387, //user?.userId ?? 0,, 13000
        businessId: 8983, //businessId,
        locationId: 11072,
        itemId: updatedMenuItem?.shopItemId,
        sizeId: updatedMenuItem?.sizeId,
        quantity: updatedMenuItem?.quantity,
        groupId: localStorage.getItem("selectedGroupId"),
      });

    setShowModal(false);
  };

  const uncheckExtra = (vindex, sindex) => {
    const updatedMenu = { ...updatedMenuItem };
    const subVariation =
      updatedMenu?.listofVariations[vindex]?.variation?.listofSubVariations[
        sindex
      ];
    subVariation.quantity = 0;
    subVariation.checked = false;

    setUpdatedMenuItem(updatedMenu);
  };
  const checkedExtra = (vindex, sindex) => {
    const updatedMenu = { ...updatedMenuItem };
    const subVariation =
      updatedMenu?.listofVariations[vindex]?.variation?.listofSubVariations[
        sindex
      ];
    subVariation.quantity = subVariation.quantity + 1;
    subVariation.checked = true;
    setUpdatedMenuItem(updatedMenu);
  };
  const changeComment = (e) => {
    const upadtedCommentData = {
      ...updatedMenuItem,
      comment: e.target.value,
    };
    setUpdatedMenuItem(upadtedCommentData);
  };
  const changeSize = (id) => {
    const upadtedSizeData = {
      ...updatedMenuItem,
      sizeId: id,
    };
    setUpdatedMenuItem(upadtedSizeData);
  };
  const favr = favList?.find(
    (item) => item?.itemDetail?.shopItemId === menuItem?.shopItemId
  );
  return (
    <>
      <MDBModal show={showModal} tabIndex="-1" staticBackdrop appendToBody>
        <MDBModalDialog size="fullscreen-sm-down" centered>
          <MDBModalContent>
            <MDBModalBody className="ps-0 pt-0 pe-0 pb-4">
              <div style={{ position: "relative" }}>
                <Cross
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    disableModal();
                  }}
                />
                <img
                  src={menuItem?.imageURL}
                  alt=""
                  style={{ borderRadius: " 8px 8px 0px 0px", width: "100%" }}
                  height={300}
                />
              </div>
              <div className="col-lg-11 col-md-10 col-11 mx-lg-auto mx-md-auto mx-auto mt-4">
                <div className="d-flex justify-content-between fw-bold">
                  <div className="col-9 d-flex align-items-center">
                    <h5 className="fw600 lightBlack">{menuItem?.name}</h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex">
                      <h5 className="fw600 lightBlack">
                        ${menuItem?.itemSize[0]?.price ?? 0}
                      </h5>
                      {user && (
                        <button
                          className="bg-white border-0 rounded-0"
                          onClick={() => {
                            setId(menuItem?.shopItemId);
                            handleFavoriteClick();
                          }}
                          disabled={isLoading || deleteLoading || fav}
                        >
                          {/* {favr ? (
                            <Filled className="ms-3 mb-2" width={22} height={21} />
                          ) : (
                            <Fav className={"ms-3 mb-2"} />
                          )} */}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <p>{menuItem?.description}</p>
                </div>
                <form>
                  <div className="d-flex">
                    {updatedMenuItem?.itemSize?.map((item, index) => (
                      <div className="mt-3 me-4" key={index}>
                        <MDBRadio
                          labelClass={styles.radioButtons}
                          value={updatedMenuItem?.sizeId}
                          label={item?.size?.size}
                          inline
                          checked={
                            updatedMenuItem?.sizeId === item?.size?.sizeId
                          }
                          onChange={() => changeSize(item?.size?.sizeId)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="mt-4">
                    {updatedMenuItem?.listofVariations?.map(
                      (variation, vindex) => (
                        <div>
                          <p
                            className="fw-bold mt-4"
                            key={variation.variation.variationId}
                          >
                            {variation?.variation?.variationName}
                          </p>
                          {variation?.variation?.listofSubVariations?.map(
                            (subvariation, sindex) => (
                              <div
                                className="d-flex justify-content-between mt-3"
                                key={subvariation.subvariationId}
                              >
                                <div style={{ width: "45%" }}>
                                  <div>
                                    <MDBCheckbox
                                      label={
                                        subvariation?.item
                                          ? subvariation?.item?.name
                                          : subvariation?.name
                                      }
                                      id={subvariation?.suvariationId}
                                      value={subvariation.subvariationId}
                                      checked={subvariation.checked === true}
                                      onChange={(e) => {
                                        if (!e.target.checked) {
                                          uncheckExtra(vindex, sindex);
                                        } else {
                                          checkedExtra(vindex, sindex);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div style={{ width: "100%" }}>
                                    <MinusThin
                                      className={styles["icons"] + " mx-2"}
                                      onClick={() => {
                                        decrementExtra(vindex, sindex);
                                      }}
                                    />
                                    <span className=" mx-1">
                                      {subvariation?.quantity}
                                    </span>
                                    <PlusThin
                                      className={styles["icons"] + " mx-2"}
                                      onClick={() => {
                                        incrementExtra(
                                          vindex,
                                          sindex,
                                          subvariation
                                        );
                                      }}
                                    />
                                  </div>
                                  <span className=" ms-2">
                                    ${subvariation?.price}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div className="mt-4 col-lg-12 col-12">
                    <MDBTextArea
                      label="Special Instructions"
                      rows={3}
                      value={updatedMenuItem?.comment}
                      onChange={(e) => {
                        changeComment(e);
                      }}
                    />
                  </div>
                  <div className="mt-4">
                    <div className="d-flex justify-content-between mb-3">
                      <span className="fw600 lightBlack">Sub-Total</span>
                      <span className="fw600 lightBlack">
                        ${subTotal(updatedMenuItem)}
                      </span>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-3 col-4"
                        style={{ alignSelf: "center" }}
                      >
                        <Minus
                          className={styles["icons"] + " me-3"}
                          onClick={() => {
                            decrementQuantity();
                          }}
                        />
                        <span className="fw-bold">
                          {updatedMenuItem?.quantity}
                        </span>
                        <Plus
                          className={styles["icons"] + " ms-3"}
                          onClick={() => {
                            incrementQuantity();
                          }}
                        />
                      </div>
                      <div
                        onClick={handleSubmit(onSubmit)}
                        className="col-lg-9 col-md-8 col-8 text-center p-2"
                        style={{
                          background: "#F54222",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {addItemByGroupLoading ? (
                            <Loader />
                          ) : updatedMenuItem?.cartItemId === -1 ? (
                            "Add To Cart"
                          ) : (
                            "Update Cart"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
