import { apiSlice } from "../../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    googleLogin: builder.mutation({
      query: (credentials) => ({
        url:
          "/Login/socialMedia?" +
          new URLSearchParams({ ...credentials }).toString(),
        method: "POST",
      }),
      transformResponse: (result) => {
        return result;
      },
    }),
    phoneLogin: builder.mutation({
      query: (credentials) => ({
        url:
          "/login/mobileNumber?" +
          new URLSearchParams({ ...credentials }).toString(),
        method: "POST",
      }),
      transformResponse: (result) => {
        return result;
      },
    }),
  }),
});

export const { useGoogleLoginMutation, usePhoneLoginMutation } =
  authApiSlice;
