import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import Greetings from "../../assets/wave.svg";
import { setGreetingModal } from "../../redux/features/auth/authSlice";
import { useAppDispatch } from "../../redux/hooks";
import styles from "./styles.module.css";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import Cross from "../../assets/Cancel2.png";
import groupOrderingImage from "../../assets/groupOrdering.png";
import trashIcon from "../../assets/trash.png";
import userIcon from "../../assets/userIcon.png";
import React, { useEffect, useState } from "react";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBInputGroup,
  MDBBtn,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import clockIcon from "../../assets/clockIcon.png";
import GroupOrderButton from "../../components/GroupOrder/GroupOrderButton";
import GroupMembersCard from "../../components/GroupOrder/GroupMembersCard";
import InputField from "../../components/GroupOrder/InputField";
import {
  useViewGroupByIdQuery,
  useRemoveMemberMutation,
  useAddNewMemberMutation,
  useChangeMemberRoleMutation,
} from "../../redux/features/groups/groupsApiSlice";
import { useAppSelector } from "../../redux/hooks";
import Loader from "../../shared/UI/Loader/Loader";

export default function ViewDetailsModal(props) {
  const [selectedGroup, setSelectedGroup] = useState(null); // State to store the selected group
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [newMember, setNewMember] = useState("");
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );

  console.log("props?.selectedGroup", props?.selectedGroup);
  //add new member
  const [
    addMember,
    {
      data: addMemberData,
      isLoading: addMemberisLoading,
      isError: addMemberisError,
      error: addMembererror,
    },
  ] = useAddNewMemberMutation();

  const addNewMemberHandler = () => {
    addMember({
      name: props?.selectedGroup?.name,
      userId: user?.userId ?? 0, //12387, //user?.userId ?? 0,, 13000
      businessId: 8983, //businessId,
      locationId: 11072,
      paymentMethod: "Owner",
      members: newMember,
      groupId: props?.selectedGroup?.groupId,
      dateTime: "2023-05-30 06:20:17",
    });

    setNewMember("");
  };
  //for group details
  const { data, isLoading, isError, groupDetailserror } = useViewGroupByIdQuery(
    {
      userId: user?.userId ?? 0, //12387, //user?.userId ?? 0,, 13000
      businessId: 8983, //businessId,
      dateTime: "2023-05-30 06:20:17",
      groupId: props?.selectedGroup?.groupId,
    }
  );

  //for removing member
  const [
    removeMember,
    {
      data: removeMemberData,
      isLoading: removeMemberisLoading,
      isError: removeMemberisError,
      error: removeMembererror,
    },
  ] = useRemoveMemberMutation();

  const removeMemberHandler = (id) => {
    console.log("member id is::", id);
    removeMember({
      businessId: 8983, //businessId,
      groupId: props?.selectedGroup?.groupId,
      memberUserId: id,
    });
  };

  //for changing member role
  const [
    changeMemberRole,
    {
      data: changeMemberData,
      isLoading: changeMemberisLoading,
      isError: changeMemberisError,
      error: changeMembererror,
    },
  ] = useChangeMemberRoleMutation();

  const changeRoleHandler = (id) => {
    console.log("member id is::", id);
    changeMemberRole({
      businessId: 8983, //businessId,
      userId: user?.userId ?? 0, //12387, //user?.userId ?? 0,, 13000
      memberUserId: id,
      locationId: 11072,
      dateTime: "2023-05-30 06:20:17",
      groupId: props?.selectedGroup?.groupId,
      roleId: 1,
    });
  };

  // useEffect(() => {
  //   console.log("data--", data?.payLoad);
  // }, [data]);

  // props.selectedGroup?.groupMembers?.map((item) => {
  //   console.log("items", item?.role?.name);
  // });
  const disableModal = () => {
    props.setViewDetailsModal(false);
  };

  const date = new Date(data?.payLoad?.creationDate);
  const options = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return (
    <>
      <MDBModal
        show={props.viewDetailsModal}
        tabIndex="-1"
        staticBackdrop
        appendToBody
      >
        <MDBModalDialog size="fullscreen-sm-down" centered>
          <MDBModalContent style={{ overflowY: "auto" }}>
            <MDBModalBody className="ps-0 pt-0 pe-0 pb-4">
              <div style={{ position: "relative" }}>
                <img
                  src={Cross}
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    marginTop: "6px",
                  }}
                  className={styles[`crossIcon`]}
                  onClick={() => {
                    disableModal();
                  }}
                />
              </div>

              {/* //////////////////////////////// */}
              {isLoading ? (
                <Loader />
              ) : (
                <div className="col-lg-12 col-md-12 col-12 mx-lg-auto  mt-4 ">
                  <div
                    className="justify-content-between"
                    style={{ paddingRight: "2rem", paddingLeft: "2rem" }}
                  >
                    <div style={{ marginTop: "2.5rem" }}>
                      <div
                        style={{
                          color: "#606060",
                          fontWeight: "600",
                          fontSize: "17px",
                        }}
                      >
                        {isLoading ? (
                          <div>loadingggg....</div>
                        ) : (
                          data?.payLoad?.name
                        )}
                      </div>
                      <div></div>
                      <div
                        className="mt-2 mb-4"
                        style={{ color: "#606060", fontSize: "13px" }}
                      >
                        {`Created at ${formattedDate}`}
                        {/* Created at {props?.selectedGroup?.createdDate} */}
                      </div>
                      {/* //input field/ */}

                      <div
                        className="row mt-4 mb-3"
                        style={{
                          marginLeft: "0px",
                          marginRight: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                      >
                        <div className="col-10" style={{ paddingLeft: "0px" }}>
                          <InputField
                            value={newMember}
                            label={"Add New Member"}
                            onChange={(e) => setNewMember(e.target.value)}
                          />
                        </div>
                        <div
                          className="col-2"
                          style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        >
                          <GroupOrderButton
                            backgroundColor={"#F54222"}
                            color={"#fff"}
                            OnClickHandler={addNewMemberHandler}
                            text={addMemberisLoading ? <Loader /> : "Add"}
                          />
                        </div>
                      </div>

                      {/* list of users */}
                      <div
                        style={{
                          maxHeight: "289px",
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        {/* <GroupMembersCard
                        name="Aizaz Rafi"
                        email="aizazrafi@gmail.com"
                        groupowner={true}
                      /> */}
                        {/* props.selectedGroup?.groupMembers */}
                        {data?.payLoad?.groupMembers.map((member, index) => (
                          <GroupMembersCard
                            key={index}
                            name={
                              member.userBusiness?.user?.firstName +
                              " " +
                              member.userBusiness?.user?.lastName
                            }
                            groupowner={member?.role?.name && true}
                            removeMemberHandler={() =>
                              removeMemberHandler(
                                member.userBusiness?.user?.userId
                              )
                            }
                            changeRoleHandler={() =>
                              changeRoleHandler(
                                member.userBusiness?.user?.userId
                              )
                            }
                          />
                        ))}
                      </div>

                      {/* // button */}
                      <div className="mt-3 mb-3">
                        <GroupOrderButton
                          backgroundColor={"#F54222"}
                          color={"#fff"}
                          OnClickHandler={() =>
                            props.setViewDetailsModal(false)
                          }
                          text={"Save"}
                        />
                      </div>
                      <div className=" mb-4">
                        <GroupOrderButton
                          backgroundColor={"#FCC6BD"}
                          color={"#F54222"}
                          OnClickHandler={() => {
                            props.setViewDetailsModal(false);
                            props.setSelectGroupModal(true);
                          }}
                          text={"Go Back"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
