import { MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn } from "mdb-react-ui-kit";
import PaymentCard from "../../../components/Payment/PaymentCard";
import styles from "../styles.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../../assets/btnAdd.svg";
import AddPaymentModal from "./AddPaymentModal";
import {
  useDeletePaymentMethodMutation,
  useLazyGetPaymentsQuery,
} from "../../../redux/features/account/paymentApiSlice";
import DeleteAlert from "../../../shared/UI/MessageDialog/DeleteAlert";
import Loader from "../../../shared/UI/Loader/Loader";
import { useAppSelector } from "../../../redux/hooks";
import { businessId } from "../../../config";
export default function Payments(params) {
  const navigate = useNavigate();
  const user = useAppSelector((state) =>
    state?.auth?.token?.user ? state?.auth?.token?.user : state?.auth?.user
  );
  const [getPayments, result] = useLazyGetPaymentsQuery();
  const [deletePaymentMethod, { isLoading }] = useDeletePaymentMethodMutation();
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [stripeId, setStripeId] = useState();
  const [paymentsList, setPaymentsList] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getPayments({
      // userId: user?.userId??0,
      userId: user?.userId ?? 0,
      businessId: businessId,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (result?.data) {
      setPaymentsList(result?.data);
    }
  }, [result?.data]);
  const onDeletSubmit = async () => {
    const result = await deletePaymentMethod({
      userStripeId: stripeId,
      userId: user?.userId ?? 0,
      businessId: businessId,
    });
    if ("data" in result && result.data.status === "SUCCESS") {
      setOpenDelete(false);
    }
  };
  return (
    <div className="py-4 mx-3 mx-md-4 mx-lg-5 my-1  ">
      <div
        className={styles["changepasswordbox"]}
        style={{
          minHeight: "75vh",
        }}
      >
        <div className="col-md-11 col-12 mx-auto">
          <MDBBreadcrumb className="mx-md-5 mx-4 ps-md-2 ps-lg-2 mt-md-5 mt-4">
            <MDBBreadcrumbItem onClick={() => navigate("/account")}>
              Account Settings
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Payments</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <h5 className="fw-bold black mx-md-5 mx-4 ps-md-2 ps-lg-2 mt-3 py-2">
            Payments
          </h5>
          <div className=" mx-sm-5 mx-3 my-3">
            {result?.isLoading ? (
              <div style={{ margin: "5rem 0rem" }}>
                <Loader />
              </div>
            ) : (
              <div className="row mx-md-0 mx-1">
                {paymentsList?.length > 0 ? (
                  paymentsList?.map((item, index) => {
                    return (
                      <div className={styles["paymentCard"]} key={index}>
                        <PaymentCard
                          item={item}
                          setStripeId={setStripeId}
                          setOpenDelete={setOpenDelete}
                        />
                      </div>
                    );
                  })
                ) : (
                  <h6 className="text-center black fw-bold my-5 pb-4">
                    No Payment Method Found
                  </h6>
                )}
              </div>
            )}

            <div>
              <MDBBtn
                onClick={() => setOpenModal(true)}
                className={`px-3 mt-3 mb-md-5 ms-md-2 ms-1 mb-3 shadow-none fw-bold ${styles.btnUpload}`}
              >
                <AddIcon className="me-2 mb-1" /> Add New Payment Method
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      {!!openModal && (
        <AddPaymentModal open={openModal} setOpen={setOpenModal} />
      )}
      {!!openDelete && (
        <DeleteAlert
          isOpen={openDelete}
          isLoading={isLoading}
          onClose={() => setOpenDelete(false)}
          heading={"Delete Payment Method?"}
          cancelText={"Proceed"}
          message={"Are you sure want to Delete this Payment Method?"}
          onOk={onDeletSubmit}
        />
      )}
      <div className="pt-3" />
    </div>
  );
}
