import { compareAsc, format, parse } from "date-fns";
import { store } from "../redux/store";
import { setAddToCart } from "../redux/features/cart/cartSlice";
export function AddToCart(categoryId, itemId, quantity) {
  const categoriesData = store.getState()?.common?.categories;

  const item = categoriesData
    ?.find((category) => category?.categoryId === categoryId)
    ?.item?.find((item) => item?.shopItemId === itemId);

  const meaningfulData = {
    description: item?.description,
    imageURL: item?.imageURL,
    itemSize: item?.itemSize,
    listofVariations: item?.listofVariations?.map((variation) => ({
      variation: {
        variationName: variation?.variation?.variationName,
        listofSubVariations: variation?.variation?.listofSubVariations?.map(
          (subVariation) => ({
            price: subVariation?.price,
            name: subVariation?.name
              ? subVariation?.name
              : subVariation?.item?.name,
            quantity: subVariation?.quantity,
            checked: false,
            subVariationId: subVariation?.subVariationId,
          })
        ),
      },
    })),
    name: item?.name,
    comment: "",
    quantity: quantity,
    cartItemId: Math.floor(Math.random() * 100000000),
    shopItemId: item?.shopItemId,
    price: item?.itemSize[0]?.price,
    sizeId: item?.itemSize[0]?.size?.sizeId,
  };
  store.dispatch(setAddToCart(meaningfulData));
}

export function getDateFromMillis(millis) {
  if (!millis || typeof millis != "number") return null;
  return format(parse(millis, "T", new Date()), "MMM dd, YYY");
}
export function getTimeFromMillis(millis) {
  if (!millis || typeof millis != "number") return null;
  return format(parse(millis, "T", new Date()), "H:mm");
}
export function compareTime(time1, time2) {
  try {
    const firstDate = parse(time1, "HH:mm:ss", Date.now());
    const secondDate = parse(time2, "HH:mm:ss", Date.now());
    return compareAsc(firstDate, secondDate);
  } catch (error) {
    return null;
  }
}
export function subTotal(cartItem) {
  let totalPrice = cartItem?.price;
  cartItem?.listofVariations?.forEach((variation) => {
    variation?.variation?.listofSubVariations?.forEach((subVariation) => {
      if (subVariation.checked) {
        totalPrice += subVariation?.price * subVariation?.quantity;
      }
    });
  });

  return (totalPrice * cartItem?.quantity)?.toFixed(2);
}
export function getTotal(cartItem) {
  let totalPrice = 0;

  cartItem?.forEach((data) => {
    totalPrice += parseFloat(subTotal(data));
  });

  return totalPrice.toFixed(2);
}

export function redirectToGoogleMaps(address) {
  window?.open(`https://maps.google.com/maps?q=${address}`, "_blank");
}
export const getDistance = (lat1, lon1, lat2, lon2) => {
  const earthRadius = 6371; // in kilometers

  const toRadians = (degree) => (degree * Math.PI) / 180;
  const deltaLat = toRadians(lat2 - lat1);
  const deltaLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;

  return distance;
};
export const getRoadDistance = async (apiKey, currentLocation, location) => {
  const origin = `${currentLocation.latitude},${currentLocation.longitude}`;
  const destination = `${location.latitude},${location.longitude}`;
  const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&key=${apiKey}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === "OK") {
      const distance = data.routes[0].legs[0].distance.value;
      return distance;
    }
    return null;
  } catch (error) {
    console.error("Error fetching road distance:", error);
    return null;
  }
};
export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => resolve(null)
    );
  });
};
export const isRestaurantOpen = (
  openingTime = "04:00:00",
  closingTime = "13:00:00"
) => {
  console.log(openingTime);
  console.log(closingTime);
  return (
    Date.parse("01/01/2011 " + format(new Date(), "HH:mm:ss")) >
      Date.parse("01/01/2011 " + openingTime) &&
    Date.parse("01/01/2011 " + closingTime) >
      Date.parse("01/01/2011 " + format(new Date(), "HH:mm:ss"))
  );
};

export const getCurrentDayName = () => {
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let d = new Date();
  return days[d.getDay()];
};
export function convertTime(time) {
  // from 13:00:00 to 01:00 PM
  try {
    return format(parse(time, "HH:mm:ss", new Date()), "hh:mm a");
  } catch (error) {
    return "";
  }
}
export const toggleBodyScroll = (shouldScroll) => {
  if (!document?.body) return;
  if (shouldScroll) {
    document.body.className = "";
  } else {
    document.body.className = "overflow-hidden";
  }
};
