import React from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalBody,
  MDBModalContent,
  MDBBtn,
} from "mdb-react-ui-kit";
import "react-phone-input-2/lib/style.css";
import { ReactComponent as Cross } from "../../assets/paymentcross.svg";
import { ReactComponent as Send } from "../../assets/send.svg";

const PaymentSuccessModal = ({ open, setOpen }) => {
  return (
    <>
      <MDBModal show={open} setShow={setOpen}>
        <MDBModalDialog className="rounded" centered>
          <MDBModalContent>
            <MDBModalBody>
              <>
                <Cross
                  className="cursor float-end mt-2"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
                <div className="d-block mx-auto py-5 px-5">
                  <Send width={54} className="d-block mx-auto mb-5" />
                  <h4 className="text-center fw600 black">
                    Gift Card Sent Successfully!
                  </h4>
                  <p className="text-center mb-5" style={{ color: "#868686" }}>
                    Gift Card has been purchased and sent to <br />
                    <span className="fw600">Ameer, Aizaz, & Junaid</span>
                  </p>
                  <MDBBtn
                    className={"bgRed col-12 shadow-none"}
                    onClick={() => {
                      setOpen(false);
                    }}
                    size="lg"
                  >
                    Continue Ordering
                  </MDBBtn>
                </div>
              </>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default PaymentSuccessModal;
